import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useIntl } from 'react-intl';
import { Button, Drawer, FlexboxGrid } from 'rsuite';

import Counter from '../../../handlers/Counter/Counter';
import { DeleteCounterModal } from '../Modal/DeleteCounterModal';
import { ExternalCounterInformationCard } from './ExternalCounterInformation';
import { ExternalApi } from '../../../handlers/Counter/ExternalApi';

type Props = {
    isOpen: boolean;
    onHide: () => void;
    reloadCounters: () => void;
    counter?: Counter;
    externalApis: ExternalApi[];
};

export const ExternalCounterDrawer = (props: Props) => {
    const intl = useIntl();

    if (!props.counter) {
        return null;
    }

    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);

    const handleCloseDeleteModal = (deleted: boolean) => {
        if (deleted) {
            props.onHide();
            props.reloadCounters();
        }

        setIsDeleteModalOpen(false);
    };

    return (
        <>
            <DeleteCounterModal counters={[props.counter]} show={isDeleteModalOpen} onHide={handleCloseDeleteModal} />

            <Drawer backdrop show={props.isOpen} onHide={props.onHide}>
                <Drawer.Header>
                    <Drawer.Title>{props.counter.label}</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body className="drawer-body-without-avatar">
                    <ExternalCounterInformationCard counter={props.counter} externalApis={props.externalApis} />
                </Drawer.Body>
                <Drawer.Footer>
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={8}>
                            <Button color="red" onClick={() => setIsDeleteModalOpen(true)} block>
                                <FontAwesomeIcon icon={faTrash} className="margin-right-5" />{' '}
                                {intl.formatMessage({ id: 'counter.external.delet' })}
                            </Button>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Drawer.Footer>
            </Drawer>
        </>
    );
};

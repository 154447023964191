import { faFileExport, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Alert, Button, Col, DatePicker, FlexboxGrid, Form, FormControl, Modal } from 'rsuite';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';
import { StatInterface } from '../OccupationGraph';
import { saveAs } from 'file-saver';

type Props = {
    counterId: number;
    show: boolean;
    onHide: Function;
    level: Record<string, any>;
    parking?: Record<string, any>;
} & WrappedComponentProps;

type State = {
    exportAvailable: boolean;
    options: Record<string, any>;
    loading: boolean;
    data: {
        labels: Array<Date>;
        datasets: Array<any>;
    };
    formValue: FormValue;
    rawData: Array<Record<string, any>>;
    redraw: boolean;
    placeTypes: Record<string, string>;
};

type FormValue = {
    startDate: Date;
    endDate: Date;
};

class OccupationGraphModal extends React.Component<Props, State> {
    reference: Line | null = null;
    minMaxValues: [number | null, number | null][];
    dateMaxValue: null | Date;

    constructor(props) {
        super(props);

        this.minMaxValues = [];
        this.dateMaxValue = null;

        const options = {
            maintainAspectRatio: false,
            responsive: true,
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                            max: 100,
                            callback: value => `${value}%`,
                        },
                    },
                ],
                xAxes: [
                    {
                        type: 'time',
                        distribution: 'series',
                        major: {
                            enabled: true,
                        },
                        time: {
                            unit: 'hour',
                            displayFormats: {
                                millisecond: 'HH[h]mm',
                                second: 'HH[h]mm',
                                minute: 'HH[h]mm',
                                hour: 'HH[h]mm',
                                day: 'DD/MM/YY HH[h]mm',
                                week: 'DD/MM/YYYY HH[h]mm',
                                month: 'DD/MM/YYYY HH[h]mm',
                                quarter: 'DD/MM/YYYY HH[h]mm',
                                year: 'DD/MM/YYYY HH[h]mm',
                            },
                        },
                        gridLines: {
                            offset: false,
                            gridLines: {
                                offsetGridLines: false,
                            },
                        },
                    },
                ],
            },
            legend: {
                display: true,
            },
            tooltips: {
                callbacks: {
                    title: (tooltipItem, data) => {
                        return moment(data.labels[tooltipItem[0].index]).format('DD/MM/YYYY HH:mm');
                    },
                    label: tooltipItem => {
                        // REMINDER !!
                        // datasetIndex 0 => Occupation values (number)
                        // datasetIndex 1 => MaxValue of the serie (number)
                        // datasetIndex 2 => MinMaxValues of the point ([number, number])

                        if (tooltipItem.datasetIndex === 0) {
                            return `${this.props.intl.formatMessage({
                                id: 'occupationGraph.occupation.maxValue',
                            })} ${tooltipItem.value}% (${moment(this.dateMaxValue).format('DD/MM/YYYY HH:mm')})`;
                        }

                        if (tooltipItem.datasetIndex === 1) {
                            return `${this.props.intl.formatMessage({
                                id: 'occupationGraph.occupation.value',
                            })} ${tooltipItem.value}% (${this.state.data.datasets[1].values[tooltipItem.index]})`;
                        }

                        if (tooltipItem.datasetIndex === 2) {
                            let percentValues = JSON.parse(tooltipItem.value);
                            return `${this.props.intl.formatMessage({
                                id: 'occupationGraph.occupation.min',
                            })} ${percentValues[0]}% (${
                                this.state.data.datasets[2].values[tooltipItem.index][0]
                            }) / ${this.props.intl.formatMessage({ id: 'occupationGraph.occupation.max' })} ${
                                percentValues[1]
                            } (${this.state.data.datasets[2].values[tooltipItem.index][1]})`;
                        }
                    },
                },
                mode: 'index',
            },
        };

        const labels: Array<Date> = [];
        const datasets = [
            {
                label: this.props.intl.formatMessage({
                    id: 'occupationGraph.maxOccupation',
                }),
                type: 'line',
                show: false,
                fill: false,
                borderColor: 'rgba(255, 19, 0, 1)',
                backgroundColor: 'rgba(255, 19, 0, 1)',
                pointBorderColor: 'transparent',
                pointRadius: 0,
                borderWidth: 5,
                data: [],
                values: [],
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'occupationGraph.occupation',
                }),
                show: false,
                fill: true,
                lineTension: 0.1,
                backgroundColor: 'rgba(54, 162, 235, 1)',
                // borderColor: 'rgba(119, 221, 119,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(54, 162, 235, 1)',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(54, 162, 235, 1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: [],
                values: [],
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'occupationGraph.minAndMaxOccupation',
                }),
                type: 'bar',
                show: false,
                backgroundColor: 'rgba(255, 159, 64, 0.7)',
                data: [],
                barThickness: 'flex',
                categoryPercentage: 1,
                barPercentage: 1,
                minBarLength: 20,
                values: [],
            },
        ];

        this.state = {
            ...this.state,
            exportAvailable: true,
            options,
            loading: true,
            data: {
                labels,
                datasets,
            },
            formValue: {
                startDate: moment().subtract(1, 'day').toDate(),
                endDate: moment().toDate(),
            },
            rawData: [],
            redraw: false,
        };

        this.loadCounter = this.loadCounter.bind(this);
        this.exportCounter = this.exportCounter.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.loadCounter();
    }

    componentDidUpdate() {
        this.state.redraw &&
            this.setState({
                redraw: false,
            });
    }

    loadCounter() {
        const dateBefore = this.state.formValue.endDate.toISOString();
        const dateAfter = this.state.formValue.startDate.toISOString();

        this.setState({
            redraw: true,
            exportAvailable: false,
        });
        axiosService
            .getAxios()
            .get('/sensor-place-type/', {
                headers: authHeader(),
            })
            .then(response => {
                const placeTypes = {};
                for (let i = 0; i < response.data.length; i++) {
                    placeTypes[response.data[i].id] = response.data[i].label;
                }

                axiosService
                    .getAxios()
                    .get(`/statistics/counter/${this.props.counterId}/Date/${dateAfter};${dateBefore}`, {
                        headers: authHeader(),
                    })
                    .then(response => {
                        let labels: Array<Date> = [];
                        let occupationPercent: Array<number> = [];
                        let occupationValues: Array<number> = [];
                        let maxPercentValue: Array<number | undefined> = [];
                        let maxValue: Array<number | undefined> = [];
                        let minMaxPercent: [number | null, number | null][] = [];
                        let minMaxValues: [number | null, number | null][] = [];

                        const stats = response.data;

                        // Init the max value of the serie
                        let maxSeriePercent = 0;
                        let maxSerieValue = 0;
                        let maxSerieValueIndex = 0;

                        for (let i = 0; i < stats.length; i++) {
                            const stat: StatInterface = stats[i].result.counterValue.all;

                            let counterMaxPercent: number = stats[i].result.counterMax?.maxOccRate
                                ? parseFloat(stats[i].result.counterMax.maxOccRate.toFixed(2))
                                : 0;
                            let counterMinPercent: number = stats[i].result.counterMin?.minOccRate
                                ? parseFloat(stats[i].result.counterMin.minOccRate.toFixed(2))
                                : 0;

                            let counterMaxValue: number = stats[i].result.counterMax?.maxOccRate
                                ? stats[i].result.counterMax.maxOcc
                                : 0;
                            let counterMinValue: number = stats[i].result.counterMin?.minOccRate
                                ? stats[i].result.counterMin.minOcc
                                : 0;

                            //--- check counterMaxMin for update value
                            if (stats[i].result.counterMax?.all?.maxOccRate)
                                counterMaxPercent = parseFloat(
                                    Number(stats[i].result.counterMax.all.maxOccRate).toFixed(2)
                                );
                            if (stats[i].result.counterMin?.all?.minOccRate)
                                counterMinPercent = parseFloat(
                                    Number(stats[i].result.counterMin.all.minOccRate).toFixed(2)
                                );
                            if (stats[i].result.counterMax?.all?.maxOcc)
                                counterMaxValue = parseFloat(Number(stats[i].result.counterMax.all.maxOcc).toFixed(2));
                            if (stats[i].result.counterMin?.all?.minOcc)
                                counterMinValue = parseFloat(Number(stats[i].result.counterMin.all.minOcc).toFixed(2));

                            const date = moment(stats[i].createdAt).toDate();

                            // Add date on X axis
                            labels.push(date);

                            // Determine the value of the stat;
                            let statValue = 0;

                            if (stat.total > 0) {
                                statValue = parseFloat(((stat.occupied / stat.total) * 100).toFixed(2));
                            }

                            occupationPercent.push(statValue);
                            occupationValues.push(stat.occupied);

                            // Add min & max value in array
                            // TODO: Change with real values from API
                            minMaxPercent.push([counterMinPercent, counterMaxPercent]);
                            minMaxValues.push([counterMinValue, counterMaxValue]);

                            if (maxSeriePercent < counterMaxPercent) {
                                maxSeriePercent = counterMaxPercent;
                                maxSerieValue = counterMaxValue;
                                maxSerieValueIndex = i;
                                this.dateMaxValue = labels[i];
                            }

                            // Fill maxPercentValue array with undefined to not render points
                            maxPercentValue.push(undefined);
                            maxValue.push(undefined);
                        }
                        // Replace the maxPercentValue max value index with occupation max value

                        maxPercentValue.fill(maxSeriePercent);
                        maxValue.fill(maxSerieValue);

                        const datasets = [...this.state.data.datasets];
                        datasets[0].data = maxPercentValue;
                        datasets[0].values = maxValue;
                        datasets[1].data = occupationPercent;
                        datasets[1].values = occupationValues;
                        datasets[2].data = minMaxPercent;
                        datasets[2].values = minMaxValues;

                        let unit = 'hour';

                        if (stats.length > 24 * 4) {
                            unit = 'day';
                        }

                        this.setState(
                            {
                                rawData: stats,
                                data: {
                                    ...this.state.data,
                                    labels,
                                    datasets,
                                },
                                options: {
                                    ...this.state.options,
                                    scales: {
                                        ...this.state.options.scales,
                                        xAxes: [
                                            {
                                                type: 'time',
                                                distribution: 'series',
                                                major: {
                                                    enabled: true,
                                                },
                                                time: {
                                                    unit,
                                                    displayFormats: {
                                                        millisecond: 'HH[h]mm',
                                                        second: 'HH[h]mm',
                                                        minute: 'HH[h]mm',
                                                        hour: 'HH[h]mm',
                                                        day: 'DD/MM/YY HH[h]mm',
                                                        week: 'DD/MM/YYYY HH[h]mm',
                                                        month: 'DD/MM/YYYY HH[h]mm',
                                                        quarter: 'DD/MM/YYYY HH[h]mm',
                                                        year: 'DD/MM/YYYY HH[h]mm',
                                                    },
                                                },
                                                gridLines: {
                                                    offset: false,
                                                    gridLines: {
                                                        offsetGridLines: false,
                                                    },
                                                },
                                            },
                                        ],
                                    },
                                },
                            },
                            () => {
                                this.reference?.chartInstance.update();
                            }
                        );
                        this.setState({ exportAvailable: true, placeTypes });
                    })
                    .catch(err => console.error(err));
            });
    }

    exportPartialPlaceType(placeType: string, data: Record<string, any>): string {
        let counterValue = {
            free: 0,
            freeRate : 0,
            occupied: 0,
            occupiedRate: 0,
            total: 0,
        };
        let counterMin = {
            time: null,
            minOcc: 0,
            minOccRate: 0,
            place: 0,
        };
        let counterMax = {
            time: null,
            maxOcc: 0,
            maxOccRate: 0,
            place: 0,
        };
        //---
        if (data.counterValue[placeType]) {
            counterValue = data.counterValue[placeType];
            counterValue.freeRate = Number(((counterValue.free / counterValue.total) * 100 ).toFixed(2));
            counterValue.occupiedRate = Number(((counterValue.occupied / counterValue.total) * 100 ).toFixed(2));
        }
        //--- check counterValue for update value
        if (data.counterMin[placeType]) {
            counterMin = data.counterMin[placeType];
        } else if (data.counterMin.time) {
            counterMin = data.counterMin;
        }
        //--- check counterValue for update value
        if (data.counterMax[placeType]) {
            counterMax = data.counterMax[placeType];

        } else if (data.counterMax.time) {
            counterMax = data.counterMax;
        }

        let text =
            counterValue.free +
            ';' +
            counterValue.freeRate+
            '%;' +
            counterValue.occupied +
            ';' +
            counterValue.occupiedRate+
            '%;' +
            counterValue.total +
            ';|||;' +
            moment(counterMin.time).format('YYYY-MM-DD_HH:mm:ss') +
            ';' +
            counterMin.minOcc +
            ';' +
            Number(counterMin.minOccRate).toFixed(2) +
            '%;' +
            counterMin.place +
            ';|||;' +
            moment(counterMax.time).format('YYYY-MM-DD_HH:mm:ss') +
            ';' +
            counterMax.maxOcc +
            ';' +
            Number(counterMax.maxOccRate).toFixed(2) +
            '%;' +
            counterMax.place +
            ';***;';
        //---
        return text;
    }

    exportCounter() {
        let allText = '';

        if (this.state.rawData.length > 0) {
            let graphLength = this.state.rawData.length;
            let counterName = this.state.rawData[0].label;
            allText =
                allText +
                moment(this.state.rawData[0].createdAt).format('YYYY-MM-DD_HH:mm:ss') +
                ';' +
                moment(this.state.rawData[graphLength - 1].createdAt).format('YYYY-MM-DD_HH:mm:ss') +
                '\n\r' +
                counterName
                + ';Total' +';;;;;;;;;;;;;;;;';
            //---
            for(let i in this.state.placeTypes){
                allText +=   this.state.placeTypes[i] + ';;;;;;;;;;;;;;;;';
            //---
            }

            allText +=   '\n\r';

            allText =
                allText +
                'Date;Free;Free percentage;Occupied;Occupied percentage;Total' +
                ';|||;Date min;Occurency min;Occurency min percentage;Total' +
                ';|||;Date max;Occurency max;Occurency max percentage;Total';

            for (let i in this.state.placeTypes) {
                allText =
                    allText +
                    ';***;Free;Free percentage;Occupied;Occupied percentage;Total' +
                    ';|||;Date min;Occurency min;Occurency min percentage;Total' +
                    ';|||;Date max;Occurency max;Occurency max percentage;Total';
            }

            allText +=   '\n\r';

            for (let i = 0; i < graphLength; i++) {
                let total = this.state.rawData[i].result.counterValue.all.total;
                allText += moment(this.state.rawData[i].createdAt).format('YYYY-MM-DD_HH:mm:ss') + ';';
                //--- Add all type
                allText += this.exportPartialPlaceType('all', this.state.rawData[i].result);
                //--- Add all type
                for (let j in this.state.placeTypes) {
                    allText += this.exportPartialPlaceType(j, this.state.rawData[i].result);
                }

                allText += '\n\r';
            }

            const csvData = new Blob([allText], { type: 'text/csv;charset=utf-8;' });
            saveAs(
                csvData,
                'export_' + counterName + '-OccupationGraph_' + moment().format('YYYY-MM-DD_HH:mm:ss') + '.csv'
            );

            Alert.success(this.props.intl.formatMessage({ id: 'occupationGraph.exportCSV.success' }));
        } else {
            Alert.error(this.props.intl.formatMessage({ id: 'occupationGraph.exportCSV.error' }));
        }
    }

    onChange(formValue) {
        this.setState({
            formValue: {
                startDate: formValue.startDate ? moment(formValue.startDate).toDate() : this.state.formValue.startDate,
                endDate: formValue.endDate ? moment(formValue.endDate).toDate() : this.state.formValue.endDate,
            },
        });
    }

    render() {
        return (
            <Modal backdrop="static" show={this.props.show} onHide={() => this.props.onHide()} full>
                <Modal.Header>
                    <Modal.Title>
                        {this.props.level.name ? this.props.level.name : this.props.parking?.name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ height: '80vh', overflow: 'none' }}>
                    <Form value={this.state.formValue} onChange={this.onChange}>
                        <FlexboxGrid className="margin-bottom-10">
                            <FlexboxGrid.Item componentClass={Col} xs={6}>
                                <FormControl
                                    accepter={DatePicker}
                                    name="startDate"
                                    format="DD/MM/YYYY HH:mm:ss"
                                    cleanable={false}
                                    ranges={[
                                        {
                                            label: this.props.intl.formatMessage({ id: 'calendar.yesterday' }),
                                            value: moment().subtract(1, 'day').toDate(),
                                        },
                                    ]}
                                    value={new Date(this.state.formValue.startDate)}
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={6}>
                                <FormControl
                                    accepter={DatePicker}
                                    name="endDate"
                                    ranges={[
                                        {
                                            label: this.props.intl.formatMessage({ id: 'calendar.now' }),
                                            value: new Date(),
                                        },
                                    ]}
                                    format="DD/MM/YYYY HH:mm:ss"
                                    cleanable={false}
                                    value={new Date(this.state.formValue.endDate)}
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={6}>
                                <Button color="green" block onClick={this.loadCounter} data-cy="graph-reload">
                                    <FontAwesomeIcon className="margin-right-10" icon={faSearch} />
                                    Recharger
                                </Button>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={6}>
                                <Button
                                    color="orange"
                                    block
                                    onClick={this.exportCounter}
                                    data-cy="graph-export"
                                    loading={!this.state.exportAvailable}>
                                    <FontAwesomeIcon className="margin-right-10" icon={faFileExport} />
                                    Exporter
                                </Button>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Form>
                    <Line
                        data={this.state.data}
                        options={this.state.options}
                        ref={reference => (this.reference = reference)}
                        redraw={this.state.redraw}
                    />
                </Modal.Body>
            </Modal>
        );
    }
}

export default injectIntl(OccupationGraphModal);

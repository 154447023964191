import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Col, FlexboxGrid, Modal } from 'rsuite';
import { LPSensor } from '../../../../handlers/ipCanDevices/LPSensor';
import { authHeader } from '../../../../redux/helpers';
import { axiosService, webSocketService } from '../../../../redux/services';
import { DebugValue, GraphDebugValue } from '../../../IpCanElementsPage/Drawer/LPSensor';
import LPSensorInformationsCard from '../../../IpCanElementsPage/Drawer/LPSensor/InformationsCard';
import LPFilter from '../../../IpCanElementsPage/Drawer/LPSensor/LPFilter';
import realTimeGraph from '../../../IpCanElementsPage/Drawer/LPSensor/RealTimeGraph';
import RealTimeTable from '../../../IpCanElementsPage/Drawer/LPSensor/RealTimeTable';
import { CalibrationCard } from '../../../IpCanElementsPage/Drawer/LPSensor/CalibrationCard';

type Props = {
    show: boolean;
    onHide: Function;
    lpSensor: LPSensor;
} & WrappedComponentProps;

type State = {
    isRealTime: boolean;
    realTimeChange: boolean;
    graphDebugArray: GraphDebugValue[];
    debugArray: DebugValue[];
    showRealTimeGraph: boolean;
    maxPoints: number;
};

class PassCountEditFilterModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isRealTime: false,
            realTimeChange: false,
            graphDebugArray: [],
            showRealTimeGraph: false,
            debugArray: [],
            maxPoints: 30,
        };
    }

    setRealTimeChange = (toggled: boolean) => {
        this.setState({
            showRealTimeGraph: toggled,
        });

        if (this.state.realTimeChange) {
            this.toggleWebsocket();
        }
    };

    toggleWebsocket = () => {
        const websocketActivated = !this.state.realTimeChange;

        this.setState({
            realTimeChange: !this.state.realTimeChange,
        });

        axiosService.getAxios().put(
            '/devices/lp-sensor/debugMode',
            {
                id: this.props.lpSensor.id,
                activDebug: websocketActivated,
            },
            {
                headers: authHeader(),
            }
        );

        if (websocketActivated) {
            webSocketService.onEvent('lp-sensor:debugValues', this.addDebugValue);
        } else {
            webSocketService.offEvent('lp-sensor:debugValues', this.addDebugValue);
        }
    };

    addDebugValue = data => {
        if (data.bus === this.props.lpSensor.bus && data.deviceId === this.props.lpSensor.deviceId) {
            const debugArray = [...this.state.debugArray].slice(0, this.state.maxPoints - 1);
            let graphDebugArray = [...this.state.graphDebugArray];

            if (this.state.graphDebugArray.length >= this.state.maxPoints) {
                graphDebugArray = [...this.state.graphDebugArray].slice(1, this.state.maxPoints);
            }

            let sDetection = 512;

            if (this.props.lpSensor.us1Config.sens < 10) {
                sDetection = 128;
            } else if (this.props.lpSensor.us1Config.sens < 80) {
                sDetection = 192;
            } else if (this.props.lpSensor.us1Config.sens < 160) {
                sDetection = 256;
            } else if (this.props.lpSensor.us1Config.sens < 250) {
                sDetection = 384;
            }

            let sRelease = 358;

            if (this.props.lpSensor.us2Config.sens < 10) {
                sRelease = 89;
            } else if (this.props.lpSensor.us2Config.sens < 80) {
                sRelease = 134;
            } else if (this.props.lpSensor.us2Config.sens < 160) {
                sRelease = 179;
            } else if (this.props.lpSensor.us2Config.sens < 250) {
                sRelease = 269;
            }

            debugArray.unshift({
                c1_distance1: data.debugValues.us1.distance1,
                c1_power1: data.debugValues.us1.power1,
                c1_distance2: data.debugValues.us1.distance2,
                c1_power2: data.debugValues.us1.power2,
                c2_distance1: data.debugValues.us2.distance1,
                c2_power1: data.debugValues.us2.power1,
                c2_distance2: data.debugValues.us2.distance2,
                c2_power2: data.debugValues.us2.power2,
            });

            graphDebugArray.push({
                c1_distance1: data.debugValues.us1.distance1,
                c1_power1: data.debugValues.us1.power1,
                c1_distance2: data.debugValues.us1.distance2,
                c1_power2: data.debugValues.us1.power2,
                c2_distance1: data.debugValues.us2.distance1,
                c2_power1: data.debugValues.us2.power1,
                c2_distance2: data.debugValues.us2.distance2,
                c2_power2: data.debugValues.us2.power2,
                value: data.debugValues.valueFiltered,
                date: new Date(),
                sDetection: sDetection,
                sRelease: sRelease,
            });

            this.setState({
                debugArray,
                graphDebugArray,
            });
        }
    };

    hideModal = () => {
        this.setRealTimeChange(false);

        this.setState({
            realTimeChange: false,
            debugArray: [],
            maxPoints: 30,
            graphDebugArray: [],
            showRealTimeGraph: false,
            isRealTime: false,
        });

        this.props.onHide();
    };

    resetArray = () => {
        this.setState({
            debugArray: [],
            graphDebugArray: [],
        });
    };

    setMaxPoints = points => {
        this.setState({
            maxPoints: parseInt(points),
        });
    };

    render = () => (
        <Modal show={this.props.show} onHide={() => this.hideModal()} full={this.state.showRealTimeGraph}>
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="map.passCount.lpSensor.editFilter.title" />
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <FlexboxGrid>
                    {this.state.showRealTimeGraph && (
                        <FlexboxGrid.Item componentClass={Col} xs={7}>
                            <LPSensorInformationsCard
                                sensor={this.props.lpSensor}
                                showRealTimeGraph={this.state.showRealTimeGraph}
                            />

                            {RealTimeTable(this.state.debugArray)}
                        </FlexboxGrid.Item>
                    )}
                    <FlexboxGrid.Item componentClass={Col} xs={this.state.showRealTimeGraph ? 17 : 24}>
                        <FlexboxGrid>
                            <FlexboxGrid.Item componentClass={Col} xs={this.state.showRealTimeGraph ? 7 : 24}>
                                <CalibrationCard
                                    sensor={this.props.lpSensor}
                                    realTimeChange={this.setRealTimeChange}
                                    isRealTime={this.state.showRealTimeGraph}
                                    comparedData={null}
                                    compareMode={false}
                                />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={this.state.showRealTimeGraph ? 17 : 24}>
                                <div data-cy="ipCanDevices-lpSensor-card-filter">
                                    <LPFilter
                                        sensor={this.props.lpSensor}
                                        realTimeChange={this.setRealTimeChange}
                                        isRealTime={this.state.showRealTimeGraph}
                                        comparedData={null}
                                        compareMode={false}
                                    />
                                </div>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>

                        {this.state.showRealTimeGraph &&
                            realTimeGraph(
                                this.state.realTimeChange,
                                this.state.graphDebugArray,
                                this.toggleWebsocket,
                                this.resetArray,
                                this.setMaxPoints
                            )}
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Modal.Body>
        </Modal>
    );
}

export default injectIntl(PassCountEditFilterModal);

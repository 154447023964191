import { faCheck, faPlug, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { Fragment } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { Popup } from 'react-leaflet';
import { Button, Tag } from 'rsuite';
import { DrawerInformationInterface } from '../..';
import { IpCan } from '../../../../handlers/ipcan/IpCan';
import { TCMSensorMapInformation_I } from '../../../../handlers/map/MapTCMSensor';
import { rolesConstants } from '../../../../static/roles';
import SecuredFragment from '../../../Auth/SecuredFragment';
import { DeviceStateInformationLogo } from '../../../IpCanElementsPage/Cards/DeviceStateInformation/DeviceStateInformationLogo';
import ForceSensorModal from '../../../IpCanElementsPage/Drawer/Sensor/Modal/ForceSensorModal';
import TCMSensorHistoryModal from '../../../IpCanElementsPage/Drawer/Sensor/Modal/TCMSensorHistoryModal';
import TCMSensorPlaceDrawer from './Drawer/TCMSensorPlaceDrawer';

type Props = {
    type?: Record<string, any>;
    sensor: TCMSensorMapInformation_I;
    editMode: boolean;
    controller?: IpCan;
    controllers: IpCan[];
    placeTypes: any[];
    reloadSensors: Function;
    hoverPopup: boolean;
    name?: string;
    service: any;
    drawerInformation: DrawerInformationInterface;
    openDrawer: Function;
    closeDrawer: Function;
} & WrappedComponentProps;

type State = {
    editModalOpen: boolean;
    forceSensorModalOpen: boolean;
    time: string;
    editCalibrationModalOpen: boolean;
    historyModalOpen: boolean;
    isDrawerOpen: boolean;
};

class TCMSensorPlacePopup extends React.PureComponent<Props, State> {
    timer: ReturnType<typeof setTimeout> | undefined;
    constructor(props) {
        super(props);

        this.state = {
            editModalOpen: false,
            forceSensorModalOpen: false,
            time: '0 secondes',
            editCalibrationModalOpen: false,
            historyModalOpen: false,
            isDrawerOpen: false,
        };

        this.openForceModal = this.openForceModal.bind(this);
        this.closeForceModal = this.closeForceModal.bind(this);
        this.startTimer = this.startTimer.bind(this);
        this.stopTimer = this.stopTimer.bind(this);
        this.openEditCalibrationModal = this.openEditCalibrationModal.bind(this);
        this.closeEditCalibrationModal = this.closeEditCalibrationModal.bind(this);
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.sensor.detectionStateTime !== this.props.sensor.detectionStateTime) {
            this.generateTime();
        }
    }

    openForceModal() {
        this.setState({
            forceSensorModalOpen: true,
        });
    }

    closeForceModal() {
        this.setState({
            forceSensorModalOpen: false,
        });

        this.props.reloadSensors('sensors');
    }

    openEditCalibrationModal() {
        this.setState({
            editCalibrationModalOpen: true,
        });
    }

    closeEditCalibrationModal() {
        this.setState({
            editCalibrationModalOpen: false,
        });

        this.props.reloadSensors('sensors');
    }

    openDrawer = () => {
        this.setState({
            isDrawerOpen: true,
        });
    };

    closeDrawer = () => {
        this.setState({
            isDrawerOpen: false,
        });
    };

    generateTime = () => {
        let currentTime = moment(new Date());
        let timeToCompare = moment(this.props.sensor.detectionStateTime, 'DD-MM-YYYY HH:mm:ss');
        //---
        const calculated = (currentTime.valueOf() - timeToCompare.valueOf()) / 1000;
        const duration = moment.duration(calculated, 'seconds');

        let finalString = '';
        let days = duration.days();
        let hours = duration.hours();
        let minutes = duration.minutes();
        let seconds = duration.seconds();

        if (days > 0) {
            finalString += days < 10 ? '0' + days : days;
            finalString += ' jour(s), ';
        }

        if (hours > 0) {
            finalString += hours < 10 ? '0' + hours : hours;
            finalString += ' heure(s), ';
        }

        if (minutes > 0) {
            finalString += minutes < 10 ? '0' + minutes : minutes;
            finalString += ' minute(s) et ';
        }

        finalString += seconds < 10 ? '0' + seconds : seconds;
        finalString += ' seconde(s)';

        return finalString;
    };

    startTimer() {
        this.setState({
            time: this.generateTime(),
        });
        this.timer = setInterval(() => {
            this.setState({
                time: this.generateTime(),
            });
        }, 1000);
    }

    stopTimer() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    openHistoryModal = () => {
        this.setState({
            historyModalOpen: true,
        });
    };

    closeHistoryModal = () => {
        this.setState({
            historyModalOpen: false,
        });
    };

    generateForceType(forceType) {
        switch (forceType) {
            case 1:
                return (
                    <Tag color="green">
                        <FormattedMessage id="sensor.force.free" />
                    </Tag>
                );
            case 2:
                return (
                    <Tag color="red">
                        <FormattedMessage id="sensor.force.occupied" />
                    </Tag>
                );
            case 3:
                return (
                    <Tag color="green">
                        <FormattedMessage id="sensor.force.overstayFree" />
                    </Tag>
                );
            case 4:
                return (
                    <Tag color="red">
                        <FormattedMessage id="sensor.force.overstayOccupied" />
                    </Tag>
                );
            case 11:
                return (
                    <Tag color="green">
                        <FormattedMessage id="sensor.force.green" />
                    </Tag>
                );
            case 12:
                return (
                    <Tag color="red">
                        <FormattedMessage id="sensor.force.red" />
                    </Tag>
                );
            case 13:
                return (
                    <Tag style={{ backgroundColor: '#212121', color: 'white' }}>
                        <FormattedMessage id="sensor.force.black" />
                    </Tag>
                );
            case 14:
                return (
                    <Tag color="green">
                        <FormattedMessage id="sensor.force.greenBlink" />
                    </Tag>
                );
            case 15:
                return (
                    <Tag color="red">
                        <FormattedMessage id="sensor.force.redBlink" />
                    </Tag>
                );
            case 17:
                return (
                    <Tag color="red">
                        <FormattedMessage id="sensor.force.redGreenBlink" />
                    </Tag>
                );
            default:
                return null;
        }
    }

    generateEventForceColor(forceColor_) {
        const colors = {
            green: {
                className: 'task-color-green',
                label: 'sensor.color.green',
            },
            red: {
                className: 'task-color-red',
                label: 'sensor.color.red',
            },
            black: {
                className: 'task-color-black',
                label: 'sensor.color.black',
            },
            green_blink: {
                className: 'task-color-green-blink',
                label: 'sensor.color.greenBlink',
            },
            red_blink: {
                className: 'task-color-red-blink',
                label: 'sensor.color.redBlink',
            },
            red_green_blink: {
                className: 'task-color-red-green-blink',
                label: 'sensor.color.redGreenBlink',
            },
        };
        return colors[forceColor_];
    }

    generateDetectionStateStatus = () => {
        switch (this.props.sensor.detectionState - 1) {
            case 0:
                return (
                    <Tag color="green">
                        <FormattedMessage id="ipCanDevices.LPSensors.sensorDetectionState.free" />
                    </Tag>
                );
            case 1:
                return (
                    <Tag color="red">
                        <FormattedMessage id="ipCanDevices.LPSensors.sensorDetectionState.occupied" />
                    </Tag>
                );
            case 3:
                return (
                    <Tag color="orange">
                        <FormattedMessage id="ipCanDevices.LPSensors.sensorDetectionState.overstay_occupied" />
                    </Tag>
                );
            case 4:
                return (
                    <Tag color="violet">
                        <FormattedMessage id="ipCanDevices.LPSensors.sensorDetectionState.reserved" />
                    </Tag>
                );
            default:
                return null;
        }
    };

    render = () => {
        const shouldShowDrawer =
            this.props.editMode &&
            this.props.drawerInformation.isOpen &&
            this.props.drawerInformation.type === 'tcm-sensor' &&
            this.props.drawerInformation.id === this.props.sensor.id;
        return (
            <Fragment>
                {shouldShowDrawer && (
                    <TCMSensorPlaceDrawer
                        show={this.props.drawerInformation.isOpen}
                        onHide={() => this.props.closeDrawer()}
                        sensor={this.props.sensor}
                        controllers={this.props.controllers}
                        placeTypes={this.props.placeTypes}
                        service={this.props.service}
                    />
                )}
                <Popup  key={`TCM-Sensor-${this.props.sensor.id}-${this.props.hoverPopup}`} autoPan={!this.props.hoverPopup}  onOpen={this.startTimer} onClose={this.stopTimer}>
                    <h5>
                        <SecuredFragment authorizedRoles={[]}>
                            <FontAwesomeIcon
                                icon={faPlug}
                                color={this.props.sensor.online ? 'green' : 'red'}
                                className="margin-right-5"
                            />
                        </SecuredFragment>
                        <span className="margin-right-5">{this.props.sensor.label}</span>
                        <SecuredFragment authorizedRoles={[]}>
                            <DeviceStateInformationLogo
                                maintenanceState={this.props.sensor.tcmSensor.getMaintenanceState()}
                            />
                        </SecuredFragment>
                    </h5>
                    <p>
                        {this.props.controller?.label} -- {this.props.sensor.bus + 1} -- {this.props.sensor.deviceId}
                    </p>
                    <p>
                        <FormattedMessage id="map.displays.popup.placeStatus" />
                        {this.generateDetectionStateStatus()}
                    </p>
                    <SecuredFragment authorizedRoles={[]}>
                        <p>
                            <FormattedMessage id="map.tcmSensor.lastOnlineStateDate" /> :{' '}
                            {this.props.sensor.lastOnlineStateDate}
                        </p>
                    </SecuredFragment>
                    <hr />
                    {this.props.sensor.eventForce.forceType === 'tcm-sensor_force_color' && (
                        <Fragment>
                            <p>
                                <FormattedMessage id="map.sensors.popup.eventForce" />
                                <Tag
                                    className={`margin-left-5 ${this.generateEventForceColor(this.props.sensor.eventForce.color).className}`}>
                                    <FormattedMessage
                                        id={this.generateEventForceColor(this.props.sensor.eventForce.color).label}
                                    />
                                </Tag>
                            </p>
                            <hr />
                        </Fragment>
                    )}
                    <SecuredFragment authorizedRoles={[]}>
                        <p>
                            Calibration :{' '}
                            <FontAwesomeIcon
                                icon={this.props.sensor.calibration.state ? faCheck : faTimes}
                                color={this.props.sensor.calibration.state ? 'green' : 'red'}
                            />{' '}
                            {(this.props.sensor.calibration.value * 3.264).toFixed(0)} cm
                        </p>
                    </SecuredFragment>

                    {this.props.sensor.detectionState === 2 && (
                        <Fragment>
                            <p>Date de détection : {this.props.sensor.detectionStateTime}</p>
                            <p>Occupé depuis {this.state.time}</p>
                        </Fragment>
                    )}

                    {this.props.sensor.isForce && (
                        <Fragment>
                            <p>
                                Forçage : {this.generateForceType(this.props.sensor.forceType)}
                                <br />
                                <FormattedMessage id="map.displays.popup.startForceDate" />
                                <span className="margin-left-5">
                                    {moment(this.props.sensor.startForceTime).format('DD/MM/YYYY HH:mm:ss')}
                                </span>
                                <br />
                                <FormattedMessage id="map.displays.popup.endForceDate" />
                                <span className="margin-left-5">
                                    {moment(this.props.sensor.endForceTime).format('DD/MM/YYYY HH:mm:ss')}
                                </span>
                            </p>
                        </Fragment>
                    )}

                    {!this.props.hoverPopup && (
                        <Fragment>
                            <SecuredFragment authorizedRoles={[]}>
                                <Button size="sm" block color="green" onClick={this.openHistoryModal}>
                                    <FormattedMessage id="map.tcmSensor.showHistory" />
                                </Button>

                                <TCMSensorHistoryModal
                                    show={this.state.historyModalOpen}
                                    onHide={this.closeHistoryModal}
                                    sensor={this.props.sensor}
                                    ipCanId={this.props.sensor.ipCanId}
                                />
                            </SecuredFragment>

                            <SecuredFragment authorizedRoles={[rolesConstants.TCMSensor.FORCE_SENSOR]}>
                                <Button size="sm" block color="blue" onClick={this.openForceModal}>
                                    <FormattedMessage id="map.tcmSensor.forceSensor" />
                                </Button>

                                <ForceSensorModal
                                    show={this.state.forceSensorModalOpen}
                                    onHide={this.closeForceModal}
                                    sensorsId={[this.props.sensor.id]}
                                    sensors={[this.props.sensor.tcmSensor]}
                                />
                            </SecuredFragment>
                            {this.props.editMode && (
                                <SecuredFragment authorizedRoles={[]}>
                                    <Button
                                        block
                                        size="sm"
                                        color="orange"
                                        onClick={() => this.props.openDrawer('tcm-sensor', this.props.sensor.id)}>
                                        <FormattedMessage id="map.tcmSensor.edit" />
                                    </Button>
                                </SecuredFragment>
                            )}
                        </Fragment>
                    )}
                </Popup>
            </Fragment>
        );
    };
}

export default injectIntl(TCMSensorPlacePopup);

import { assign, createMachine } from 'xstate';
import { LPDisplay } from '../../../../../handlers/lpDisplays/LPDisplay';
import { TriggerInterface } from '../../../../../handlers/lpDisplays/LPDisplayTopology';
import { counter } from '@fortawesome/fontawesome-svg-core';

type TopologyEvent = { type: 'TIMER' } | { type: 'UPDATE_DISPLAY'; value: LPDisplay };

interface TopologyMachineContext {
    timer: number;
    currentTopology: number;
    display?: LPDisplay;
}

export const topologyMachine = createMachine<TopologyMachineContext, TopologyEvent>(
    {
        id: 'topology',
        key: 'topology',
        initial: 'timer',
        context: {
            timer: 0,
            currentTopology: 0,
        },
        states: {
            timer: {
                on: {
                    TIMER: {
                        actions: ['setTimer', 'checkTrigger'],
                        target: 'timer',
                        internal: false,
                    },
                    UPDATE_DISPLAY: {
                        actions: assign({
                            display: (context, event) => event.value,
                        }),
                    },
                },
            },
        },
    },
    {
        actions: {
            checkTrigger: assign<TopologyMachineContext, TopologyEvent>({
                currentTopology: (context: TopologyMachineContext) => {
                    if (context.display) {
                        for (let t in context.display?.getTopologyConfig().getTopologys()[context.currentTopology]
                            .triggers) {
                            const currentTrigger: TriggerInterface = context.display
                                ?.getTopologyConfig()
                                .getTopologys()[context.currentTopology].triggers[t];

                            if (currentTrigger.trigger === 1 && currentTrigger.value <= context.timer) {
                                return currentTrigger.next;
                            }

                            const countersValue = context.display.getCountersValue();

                            if (countersValue != null) {
                                //---
                                let counterValue = 0;
                                if (currentTrigger.source > 0) counterValue = countersValue[currentTrigger.source - 1];
                                //---
                                if (currentTrigger.trigger === 2 && currentTrigger.value > counterValue) {
                                    return currentTrigger.next;
                                }

                                if (currentTrigger.trigger === 3 && currentTrigger.value < counterValue) {
                                    return currentTrigger.next;
                                }
                            }
                        }

                        return context.currentTopology;
                    } else {
                        return context.currentTopology;
                    }
                },
                timer: (context: TopologyMachineContext) => {
                    if (context.display) {
                        for (let t in context.display?.getTopologyConfig().getTopologys()[context.currentTopology]
                            .triggers) {
                            const currentTrigger: TriggerInterface = context.display
                                ?.getTopologyConfig()
                                .getTopologys()[context.currentTopology].triggers[t];

                            if (currentTrigger.trigger === 1 && currentTrigger.value <= context.timer) {
                                return 0;
                            }

                            const countersValue = context.display.getCountersValue();

                            if (countersValue) {
                                if (
                                    currentTrigger.trigger === 2 &&
                                    currentTrigger.value < countersValue[currentTrigger.source]
                                ) {
                                    return 0;
                                }

                                if (
                                    currentTrigger.trigger === 3 &&
                                    currentTrigger.value > countersValue[currentTrigger.source]
                                ) {
                                    return 0;
                                }
                            }
                        }

                        return context.timer;
                    } else {
                        return context.timer;
                    }
                },
            }),
            setTimer: assign<TopologyMachineContext, TopologyEvent>({
                timer: (context: TopologyMachineContext) => {
                    return context.timer + 1;
                },
            }),
        },
    }
);

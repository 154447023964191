import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Button, ButtonGroup, ControlLabel, Form, FormControl, FormGroup, Modal, SelectPicker } from 'rsuite';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';
import Counter, { counterTypeEnum } from '../../../handlers/Counter/Counter';
import { ExternalApi } from '../../../handlers/Counter/ExternalApi';

type Props = {
    isOpen: boolean;
    externalApis: ExternalApi[];
    onHide: () => void;
    reloadCounter: () => void;
};

type FormValue = {
    label: string;
    externalApi: number;
};

export const CreateCounterModal = (props: Props) => {
    const intl = useIntl();

    const [isCreating, setIsCreating] = React.useState<boolean>(false);

    const [formValue, setFormValue] = React.useState<FormValue>({
        label: '',
        externalApi: 0,
    });

    const handleCancel = () => {
        setFormValue({
            label: '',
            externalApi: 0,
        });
        props.onHide();
    };

    const handleValid = () => {
        setIsCreating(true);

        const { label, externalApi } = formValue;

        axiosService
            .getAxios()
            .post<Counter>(
                '/counters',
                {
                    label,
                    isView: false,
                    type: counterTypeEnum.EXTERNAL,
                    externalApiId: externalApi,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                Alert.success(intl.formatMessage({ id: 'counter.external.create.success' }));

                props.reloadCounter();

                setIsCreating(false);

                handleCancel();
            })
            .catch(err => {
                console.error(err);

                Alert.error(intl.formatMessage({ id: 'camera.create.error' }));

                setIsCreating(false);
            });
    };

    const formIsValid = (): boolean => {
        const { label } = formValue;

        if (label) {
            return true;
        }

        return false;
    };

    const externalApiSelector = props.externalApis.map(externalApi => {
        return {
            label: `${externalApi.label}`,
            value: externalApi.id,
        };
    });

    return (
        <Modal show={props.isOpen} onHide={handleCancel} backdrop="static" size="xs">
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="counter.external.create.title" />
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form formValue={formValue} onChange={value => setFormValue(value as FormValue)} fluid>
                    {/* LABEL */}
                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="counter.external.create.form.name" />
                        </ControlLabel>
                        <FormControl name="label" />
                    </FormGroup>
                    {/* ExternalApi */}
                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="counter.external.create.form.externalApi" />
                        </ControlLabel>
                        <FormControl
                            accepter={SelectPicker}
                            cleanable={false}
                            data={externalApiSelector}
                            name="externalApi"
                            renderMenuItem={(label, item) => {
                                return <div data-cy={`createExternalCounter-${item.value}`}>{label}</div>;
                            }}
                        />
                    </FormGroup>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <ButtonGroup>
                    <Button
                        onClick={handleCancel}
                        data-cy="counter-event-deleteModal-cancel"
                        color="red"
                        disabled={isCreating}>
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                    <Button
                        onClick={handleValid}
                        data-cy="counter-event-deleteModal-valid"
                        appearance="primary"
                        loading={isCreating}
                        disabled={!formIsValid()}>
                        <FontAwesomeIcon icon={faCheck} />
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
};

import { faChartBar, faCheck, faEdit, faEyeSlash, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import {
    Alert,
    Button,
    ButtonGroup,
    Col,
    FlexboxGrid,
    Form,
    FormControl,
    InputNumber,
    List,
    Panel,
    SelectPicker,
    Toggle,
    Tooltip,
    Whisper,
} from 'rsuite';
import { secondsToHours } from '../../../../handlers/helper';
import { LPSensor } from '../../../../handlers/ipCanDevices/LPSensor';
import { LPSensorUsConfig } from '../../../../handlers/ipCanDevices/Sensor/LPSensorUsConfig';
import PanelHeader from '../../../Custom/PanelHeader';
import { FILTER_MASK_SELECTOR, FILTER_MEDIAN_VALUE } from '../../LPSensors/constants/filter';

type Props = {
    sensor: LPSensor;
    realTimeChange: Function;
    isRealTime: boolean;
    comparedData: any;
    compareMode: boolean;
} & WrappedComponentProps;

type State = {
    formValue: FormValue;
    isEditMode: boolean;
    isUpdating: boolean;
    realTimeChange: boolean;
    formHasError: boolean;
};

type FormValue = {
    nbSalveMin: number;
    nbSalveMax: number;
    nbInterSalveMin: number;
    nbInterSalveMax: number;
    maskFree: number;
    filterFree: number;
    medianFree: number;
    maskOccupied: number;
    filterOccupied: number;
    medianOccupied: number;
    c1: number;
    c2: number;
    sDetection: number;
    sRelease: number;
    overstayDurationDays: number;
    overstayDurationHours: Date;
};

class LPFilter extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const hours = secondsToHours(this.props.sensor.overstay);

        this.state = {
            formValue: {
                nbSalveMin: this.props.sensor.filterConfiguration.nbSalveMin,
                nbSalveMax: this.props.sensor.filterConfiguration.nbSalveMax,
                nbInterSalveMin: this.props.sensor.filterConfiguration.nbInterSalveMin,
                nbInterSalveMax: this.props.sensor.filterConfiguration.nbInterSalveMax,
                maskFree: this.props.sensor.filterConfiguration.maskFree,
                filterFree: this.props.sensor.filterConfiguration.filter.filter.freeToOccupied,
                medianFree: this.props.sensor.filterConfiguration.filter.median.freeToOccupied,
                maskOccupied: this.props.sensor.filterConfiguration.maskOccupied,
                filterOccupied: this.props.sensor.filterConfiguration.filter.filter.occupiedToFree,
                medianOccupied: this.props.sensor.filterConfiguration.filter.median.occupiedToFree,
                c1: this.props.sensor.us1Config.height,
                c2: this.props.sensor.us2Config.height,
                sDetection: this.props.sensor.us1Config.sens,
                sRelease: this.props.sensor.us2Config.sens,
                overstayDurationDays: hours.days,
                overstayDurationHours: new Date(new Date().setHours(hours.hours, hours.minutes, hours.seconds, 0)),
            },
            isEditMode: false,
            isUpdating: false,
            realTimeChange: false,
            formHasError: false,
        };
    }

    componentDidUpdate() {
        if (
            !this.state.isEditMode &&
            !this.props.isRealTime &&
            (this.state.formValue.nbSalveMin !== this.props.sensor.filterConfiguration.nbSalveMin ||
                this.state.formValue.nbSalveMax !== this.props.sensor.filterConfiguration.nbSalveMax ||
                this.state.formValue.nbInterSalveMin !== this.props.sensor.filterConfiguration.nbInterSalveMin ||
                this.state.formValue.nbInterSalveMax !== this.props.sensor.filterConfiguration.nbInterSalveMax ||
                this.state.formValue.maskFree !== this.props.sensor.filterConfiguration.maskFree ||
                this.state.formValue.maskOccupied !== this.props.sensor.filterConfiguration.maskOccupied ||
                this.state.formValue.sDetection !== this.props.sensor.us1Config.sens ||
                this.state.formValue.c1 !== this.props.sensor.us1Config.height ||
                this.state.formValue.sRelease !== this.props.sensor.us2Config.sens ||
                this.state.formValue.c2 !== this.props.sensor.us2Config.height)
        ) {
            const hours = secondsToHours(this.props.sensor.overstay);

            this.setState({
                formValue: {
                    nbSalveMin: this.props.sensor.filterConfiguration.nbSalveMin,
                    nbSalveMax: this.props.sensor.filterConfiguration.nbSalveMax,
                    nbInterSalveMin: this.props.sensor.filterConfiguration.nbInterSalveMin,
                    nbInterSalveMax: this.props.sensor.filterConfiguration.nbInterSalveMax,
                    maskFree: this.props.sensor.filterConfiguration.maskFree,
                    filterFree: this.props.sensor.filterConfiguration.filter.filter.freeToOccupied,
                    medianFree: this.props.sensor.filterConfiguration.filter.median.freeToOccupied,
                    maskOccupied: this.props.sensor.filterConfiguration.maskOccupied,
                    filterOccupied: this.props.sensor.filterConfiguration.filter.filter.occupiedToFree,
                    medianOccupied: this.props.sensor.filterConfiguration.filter.median.occupiedToFree,
                    c1: this.props.sensor.us1Config.height,
                    c2: this.props.sensor.us2Config.height,
                    sDetection: this.props.sensor.us1Config.sens,
                    sRelease: this.props.sensor.us2Config.sens,
                    overstayDurationDays: hours.days,
                    overstayDurationHours: new Date(new Date().setHours(hours.hours, hours.minutes, hours.seconds, 0)),
                },
            });
        }
    }

    toggleEditMode = () => {
        this.setState({
            isEditMode: !this.state.isEditMode,
        });
    };

    handleChange = formValue => {
        let formHasError = false;

        console.log(formValue);

        // Assign correct value to sDetection & sRelease
        let sDetection;
        let sRelease;

        switch (parseInt(formValue.sDetection)) {
            case 255:
                sDetection = 512;
                break;
            case 240:
                sDetection = 384;
                break;
            case 150:
                sDetection = 256;
                break;
            case 70:
                sDetection = 192;
                break;
            default:
                sDetection = 128;
                break;
        }

        switch (parseInt(formValue.sRelease)) {
            case 255:
                sRelease = 358;
                break;
            case 240:
                sRelease = 269;
                break;
            case 150:
                sRelease = 179;
                break;
            case 70:
                sRelease = 134;
                break;
            default:
                sRelease = 89;
                break;
        }

        if (sDetection < sRelease) formHasError = true;

        this.setState({
            formValue: {
                ...formValue,
                nbSalveMin: formValue.nbSalveMin,
                nbSalveMax: formValue.nbSalveMax,
                nbInterSalveMin: formValue.nbInterSalveMin,
                nbInterSalveMax: formValue.nbInterSalveMax,
                maskFree: formValue.maskFree,
                filterFree: formValue.filterFree,
                medianFree: formValue.medianFree,
                maskOccupied: formValue.maskOccupied,
                filterOccupied: formValue.filterOccupied,
                medianOccupied: formValue.medianOccupied,
                overstayDurationDays: formValue.overstayDurationDays,
            },
            formHasError,
        });
    };

    handleRealTimeChange = value => {
        this.setState({
            realTimeChange: value,
        });
    };

    validEdition = () => {
        this.setState({
            isUpdating: true,
        });

        const {
            nbSalveMin,
            nbSalveMax,
            nbInterSalveMin,
            nbInterSalveMax,
            maskFree,
            filterFree,
            medianFree,
            maskOccupied,
            filterOccupied,
            medianOccupied,
            c1,
            c2,
            sDetection,
            sRelease,
            overstayDurationDays,
            overstayDurationHours,
        } = this.state.formValue;

        let filter = 0;
        filter += filterFree & 0xff;
        filter += (medianFree << 8) & 0xff00;
        filter += (filterOccupied << 16) & 0xff0000;
        filter += (medianOccupied << 24) & 0xff000000;


        this.props.sensor
            .updateConfig(
                nbSalveMin,
                nbSalveMax,
                nbInterSalveMin,
                nbInterSalveMax,
                maskFree,
                maskOccupied,
                filter,
                this.props.sensor.us1Config.height,
                this.props.sensor.us2Config.height,
                this.props.sensor.us1Config.sens,
                this.props.sensor.us2Config.sens,
                this.props.sensor.overstay,
            )
            .then(() => {
                if (!this.props.isRealTime) {
                    this.setState({
                        isEditMode: false,
                    });
                }

                Alert.success(
                    this.props.intl.formatMessage({
                        id: 'ipCanDevices.LPSensor.updateFitler.success',
                    })
                );
            })
            .catch(err => {
                Alert.error(
                    this.props.intl.formatMessage(
                        {
                            id: 'ipCanDevices.LPSensor.updateFitler.error',
                        },
                        {
                            error: err,
                        }
                    )
                );
            })
            .finally(() => {
                this.setState({
                    isUpdating: false,
                });
            });
    };

    handleToggleChange = isToggled => {
        this.props.realTimeChange(isToggled);
        this.setState({
            isEditMode: isToggled,
        });
    };

    render() {
        const isInEditMode = this.state.isEditMode || this.props.isRealTime;

        let realHours: any;

        let c1_SDetectionClass;
        let c2_SReleaseClass;
        let overstayClass;
        let nbSalveMinClass;
        let nbSalveMaxClass;
        let nbInterSalveMinClass;
        let nbInterSalveMaxClass;
        let maskFreeClass;
        let filterFreeToOccupiedClass;
        let medianFreeToOccupiedClass;
        let maskOccupiedClass;
        let filterOccupiedToFreeClass;
        let medianOccupiedToFreeClass;

        if (this.props.compareMode) {
            realHours = secondsToHours(this.props.comparedData.overstay);
            const us1ToCompare = new LPSensorUsConfig(this.props.comparedData.us1);
            const us2ToCompare = new LPSensorUsConfig(this.props.comparedData.us2);

            c1_SDetectionClass = this.props.sensor.us1Config.compare(us1ToCompare) ? 'list-green' : 'list-red';
            c2_SReleaseClass = this.props.sensor.us2Config.compare(us2ToCompare) ? 'list-green' : 'list-red';
            overstayClass = this.props.sensor.overstay === this.props.comparedData.overstay ? 'list-green' : 'list-red';
            nbSalveMinClass =
                this.props.sensor.filterConfiguration.nbSalveMin === this.props.comparedData.filterConfig.nbSalveMin
                    ? 'list-green'
                    : 'list-red';
            nbSalveMaxClass =
                this.props.sensor.filterConfiguration.nbSalveMax === this.props.comparedData.filterConfig.nbSalveMax
                    ? 'list-green'
                    : 'list-red';
            nbInterSalveMinClass =
                this.props.sensor.filterConfiguration.nbInterSalveMin ===
                this.props.comparedData.filterConfig.nbInterSalveMin
                    ? 'list-green'
                    : 'list-red';
            nbInterSalveMaxClass =
                this.props.sensor.filterConfiguration.nbInterSalveMax ===
                this.props.comparedData.filterConfig.nbInterSalveMax
                    ? 'list-green'
                    : 'list-red';
            maskFreeClass =
                this.props.sensor.filterConfiguration.maskFree === this.props.comparedData.filterConfig.maskFree
                    ? 'list-green'
                    : 'list-red';
            filterFreeToOccupiedClass =
                this.props.sensor.filterConfiguration.filter.filter.freeToOccupied ===
                this.props.comparedData.filterConfig.filtersValue.filter.freeToOccupied
                    ? 'list-green'
                    : 'list-red';
            medianFreeToOccupiedClass =
                this.props.sensor.filterConfiguration.filter.median.freeToOccupied ===
                this.props.comparedData.filterConfig.filtersValue.median.freeToOccupied
                    ? 'list-green'
                    : 'list-red';
            maskOccupiedClass =
                this.props.sensor.filterConfiguration.maskOccupied === this.props.comparedData.filterConfig.maskOccupied
                    ? 'list-green'
                    : 'list-red';
            filterOccupiedToFreeClass =
                this.props.sensor.filterConfiguration.filter.filter.occupiedToFree ===
                this.props.comparedData.filterConfig.filtersValue.filter.occupiedToFree
                    ? 'list-green'
                    : 'list-red';
            medianOccupiedToFreeClass =
                this.props.sensor.filterConfiguration.filter.median.occupiedToFree ===
                this.props.comparedData.filterConfig.filtersValue.median.occupiedToFree
                    ? 'list-green'
                    : 'list-red';
        }

        return (
            <Panel
                className="panel-small"
                shaded
                bordered
                bodyFill
                header={
                    <PanelHeader
                        title={this.props.intl.formatMessage({
                            id: 'ipCanDevices.LPSensor.filter',
                        })}
                        buttons={[
                            <Whisper
                                placement="top"
                                trigger="hover"
                                speaker={
                                    <Tooltip>
                                        <FormattedMessage id="device.testMode" />
                                    </Tooltip>
                                }>
                                <Toggle
                                    data-cy="ipCanDevices-lpSensor-changeModeFilters"
                                    unCheckedChildren={<FontAwesomeIcon icon={faEyeSlash} />}
                                    checkedChildren={<FontAwesomeIcon icon={faChartBar} />}
                                    className="margin-right-10"
                                    onChange={this.handleToggleChange}
                                    checked={this.props.isRealTime}
                                />
                            </Whisper>,
                            <Button
                                data-cy="ipCanDevices-lpSensor-editModeFilters"
                                appearance="primary"
                                onClick={this.toggleEditMode}
                                disabled={isInEditMode}
                                size="sm">
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>,
                        ]}
                    />
                }>
                <Form formValue={this.state.formValue} onChange={this.handleChange} fluid>
                    <FlexboxGrid>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            xs={this.props.isRealTime ? 8 : 24}
                            style={{ padding: 0 }}>
                            <List
                                hover
                                size="sm"
                                style={{ borderRight: '1px solid black' }}
                                className="list-text-smaller">
                                <List.Item className="panel-list separator-filter">
                                    <FlexboxGrid justify="center" align="middle">
                                        <FlexboxGrid.Item>
                                            <FormattedMessage id="ipCanDevices.LPSensor.filter" />
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                </List.Item>
                                {/* --- NB SALVE MIN */}
                                <List.Item
                                    className={`panel-list ${
                                        this.props.compareMode ? nbSalveMinClass : 'list-colored-aero'
                                    }`}>
                                    <FlexboxGrid justify="space-between" align="middle">
                                        <FlexboxGrid.Item
                                            style={{ fontWeight: 'bold' }}
                                            componentClass={Col}
                                            xs={14}
                                            data-cy="ipCanDevices-LPSensor-filter-nbSalveMin">
                                            <FormattedMessage id="ipCanDevices.LPSensor.filter.nbSalveMin" />
                                        </FlexboxGrid.Item>
                                        {isInEditMode ? (
                                            <FlexboxGrid.Item componentClass={Col} xs={8}>
                                                <div data-cy="ipCanDevices-lpSensor-filter-nbSalveMin-edit">
                                                    <FormControl
                                                        className="form-margin"
                                                        accepter={InputNumber}
                                                        cleanable={false}
                                                        min={10}
                                                        max={150}
                                                        name="nbSalveMin"
                                                        size="sm"
                                                    />
                                                </div>
                                            </FlexboxGrid.Item>
                                        ) : (
                                            <Fragment>
                                                {/* READ CONFIG */}
                                                {this.props.compareMode && (
                                                    <FlexboxGrid.Item>
                                                        <FlexboxGrid.Item>
                                                            {this.props.comparedData.filterConfig.nbSalveMin}
                                                        </FlexboxGrid.Item>
                                                    </FlexboxGrid.Item>
                                                )}
                                                <FlexboxGrid.Item data-cy="ipCanDevices-lpSensor-filter-salveMin">
                                                    {this.props.sensor.filterConfiguration.nbSalveMin}
                                                </FlexboxGrid.Item>
                                            </Fragment>
                                        )}
                                    </FlexboxGrid>
                                </List.Item>
                                {/* --- NB SALVE MAX */}
                                <List.Item
                                    className={`panel-list ${
                                        this.props.compareMode ? nbSalveMaxClass : 'list-colored-aero'
                                    }`}>
                                    <FlexboxGrid justify="space-between" align="middle">
                                        <FlexboxGrid.Item
                                            style={{ fontWeight: 'bold' }}
                                            componentClass={Col}
                                            xs={14}
                                            data-cy="ipCanDevices-LPSensor-filter-nbSalveMax">
                                            <FormattedMessage id="ipCanDevices.LPSensor.filter.nbSalveMax" />
                                        </FlexboxGrid.Item>
                                        {isInEditMode ? (
                                            <FlexboxGrid.Item componentClass={Col} xs={8}>
                                                <div data-cy="ipCanDevices-lpSensor-filter-nbSalveMax-edit">
                                                    <FormControl
                                                        className="form-margin"
                                                        accepter={InputNumber}
                                                        cleanable={false}
                                                        min={10}
                                                        max={250}
                                                        name="nbSalveMax"
                                                        size="sm"
                                                    />
                                                </div>
                                            </FlexboxGrid.Item>
                                        ) : (
                                            <Fragment>
                                                {/* READ CONFIG */}
                                                {this.props.compareMode && (
                                                    <FlexboxGrid.Item>
                                                        <FlexboxGrid.Item>
                                                            {this.props.comparedData.filterConfig.nbSalveMax}
                                                        </FlexboxGrid.Item>
                                                    </FlexboxGrid.Item>
                                                )}
                                                <FlexboxGrid.Item data-cy="ipCanDevices-lpSensor-filter-salveMax">
                                                    {this.props.sensor.filterConfiguration.nbSalveMax}
                                                </FlexboxGrid.Item>
                                            </Fragment>
                                        )}
                                    </FlexboxGrid>
                                </List.Item>
                                {/* --- NB INTER SALVE MIN */}
                                <List.Item
                                    className={`panel-list ${
                                        this.props.compareMode ? nbInterSalveMinClass : 'list-colored-aero'
                                    }`}>
                                    <FlexboxGrid justify="space-between" align="middle">
                                        <FlexboxGrid.Item
                                            style={{ fontWeight: 'bold' }}
                                            componentClass={Col}
                                            xs={14}
                                            data-cy="ipCanDevices-LPSensor-filter-nbInterSalveMin">
                                            <FormattedMessage id="ipCanDevices.LPSensor.filter.nbInterSalveMin" />
                                        </FlexboxGrid.Item>
                                        {isInEditMode ? (
                                            <FlexboxGrid.Item componentClass={Col} xs={8}>
                                                <div data-cy="ipCanDevices-lpSensor-filter-interSalveMin-edit">
                                                    <FormControl
                                                        className="form-margin"
                                                        accepter={InputNumber}
                                                        cleanable={false}
                                                        min={20}
                                                        max={250}
                                                        name="nbInterSalveMin"
                                                        size="sm"
                                                    />
                                                </div>
                                            </FlexboxGrid.Item>
                                        ) : (
                                            <Fragment>
                                                {/* READ CONFIG */}
                                                {this.props.compareMode && (
                                                    <FlexboxGrid.Item>
                                                        <FlexboxGrid.Item>
                                                            {this.props.comparedData.filterConfig.nbInterSalveMin}
                                                        </FlexboxGrid.Item>
                                                    </FlexboxGrid.Item>
                                                )}
                                                <FlexboxGrid.Item data-cy="ipCanDevices-lpSensor-filter-interSalveMin">
                                                    {this.props.sensor.filterConfiguration.nbInterSalveMin}
                                                </FlexboxGrid.Item>
                                            </Fragment>
                                        )}
                                    </FlexboxGrid>
                                </List.Item>
                                {/* --- NB INTER SALVE MAX */}
                                <List.Item
                                    className={`panel-list ${
                                        this.props.compareMode ? nbInterSalveMaxClass : 'list-colored-aero'
                                    }`}>
                                    <FlexboxGrid justify="space-between" align="middle">
                                        <FlexboxGrid.Item
                                            style={{ fontWeight: 'bold' }}
                                            componentClass={Col}
                                            xs={14}
                                            data-cy="ipCanDevices-LPSensor-filter-nbInterSalveMax">
                                            <FormattedMessage id="ipCanDevices.LPSensor.filter.nbInterSalveMax" />
                                        </FlexboxGrid.Item>
                                        {isInEditMode ? (
                                            <FlexboxGrid.Item componentClass={Col} xs={8}>
                                                <div data-cy="ipCanDevices-lpSensor-filter-interSalveMax-edit">
                                                    <FormControl
                                                        className="form-margin"
                                                        accepter={InputNumber}
                                                        cleanable={false}
                                                        min={20}
                                                        max={500}
                                                        name="nbInterSalveMax"
                                                        size="sm"
                                                    />
                                                </div>
                                            </FlexboxGrid.Item>
                                        ) : (
                                            <Fragment>
                                                {/* READ CONFIG */}
                                                {this.props.compareMode && (
                                                    <FlexboxGrid.Item>
                                                        <FlexboxGrid.Item>
                                                            {this.props.comparedData.filterConfig.nbInterSalveMax}
                                                        </FlexboxGrid.Item>
                                                    </FlexboxGrid.Item>
                                                )}
                                                <FlexboxGrid.Item data-cy="ipCanDevices-lpSensor-filter-interSalveMax">
                                                    {this.props.sensor.filterConfiguration.nbInterSalveMax}
                                                </FlexboxGrid.Item>
                                            </Fragment>
                                        )}
                                    </FlexboxGrid>
                                </List.Item>
                            </List>
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item
                            componentClass={Col}
                            xs={this.props.isRealTime ? 8 : 24}
                            style={{ padding: 0 }}>
                            <List
                                hover
                                size="sm"
                                style={{ borderRight: '1px solid black' }}
                                className="list-text-smaller">
                                {/* --- FILTER FREE TO OCCUPIED */}
                                <List.Item className="panel-list separator-filter">
                                    <FlexboxGrid justify="center" align="middle">
                                        <FlexboxGrid.Item>
                                            <FormattedMessage id="ipCanDevices.LPSensor.filter.filterFreeToOccupied" />
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                </List.Item>

                                {/* --- MASK FREE */}
                                <List.Item
                                    className={`panel-list ${
                                        this.props.compareMode ? maskFreeClass : 'list-colored-yellow'
                                    }`}>
                                    <FlexboxGrid justify="space-between" align="middle">
                                        <FlexboxGrid.Item
                                            style={{ fontWeight: 'bold' }}
                                            componentClass={Col}
                                            xs={14}
                                            data-cy="ipCanDevices-LPSensor-filter-maskFree">
                                            <FormattedMessage id="ipCanDevices.LPSensor.filter.maskFree" />
                                        </FlexboxGrid.Item>
                                        {isInEditMode ? (
                                            <FlexboxGrid.Item componentClass={Col} xs={8}>
                                                <div data-cy="ipCanDevices-lpSensor-filter-maskFree-edit">
                                                    <FormControl
                                                        className="form-margin"
                                                        accepter={SelectPicker}
                                                        data={FILTER_MASK_SELECTOR}
                                                        searchable={false}
                                                        cleanable={false}
                                                        placement="auto"
                                                        name="maskFree"
                                                        size="sm"
                                                        renderMenuItem={(label, item) => {
                                                            return (
                                                                <div data-cy={`lpSensor-maskFree-value-${item.value}`}>
                                                                    {label}
                                                                </div>
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </FlexboxGrid.Item>
                                        ) : (
                                            <Fragment>
                                                {/* READ CONFIG */}
                                                {this.props.compareMode && (
                                                    <FlexboxGrid.Item>
                                                        <FlexboxGrid.Item>
                                                            {this.props.comparedData.filterConfig.maskFree}
                                                        </FlexboxGrid.Item>
                                                    </FlexboxGrid.Item>
                                                )}
                                                <FlexboxGrid.Item data-cy="ipCanDevices-lpSensor-filter-maskFree">
                                                    {this.props.sensor.filterConfiguration.maskFree}
                                                </FlexboxGrid.Item>
                                            </Fragment>
                                        )}
                                    </FlexboxGrid>
                                </List.Item>

                                {/* --- FILTER */}
                                <List.Item
                                    className={`panel-list ${
                                        this.props.compareMode ? filterFreeToOccupiedClass : 'list-colored-yellow'
                                    }`}>
                                    <FlexboxGrid justify="space-between" align="middle">
                                        <FlexboxGrid.Item
                                            style={{ fontWeight: 'bold' }}
                                            componentClass={Col}
                                            xs={14}
                                            data-cy="ipCanDevices-LPSensor-filter-filterFreeToOccupied">
                                            <FormattedMessage id="ipCanDevices.LPSensor.filter.filterFreeToOccupied" />
                                        </FlexboxGrid.Item>
                                        {isInEditMode ? (
                                            <FlexboxGrid.Item componentClass={Col} xs={8}>
                                                <div data-cy="ipCanDevices-lpSensor-filter-filterFreeToOccupied-edit">
                                                    <FormControl
                                                        className="form-margin"
                                                        accepter={InputNumber}
                                                        cleanable={false}
                                                        min={1}
                                                        max={240}
                                                        name="filterFree"
                                                        size="sm"
                                                        renderMenuItem={(label, item) => {
                                                            return (
                                                                <div
                                                                    data-cy={`lpSensor-filterFreeToOccupied-value-${item.value}`}>
                                                                    {label}
                                                                </div>
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </FlexboxGrid.Item>
                                        ) : (
                                            <Fragment>
                                                {/* READ CONFIG */}
                                                {this.props.compareMode && (
                                                    <FlexboxGrid.Item>
                                                        <FlexboxGrid.Item>
                                                            {
                                                                this.props.comparedData.filterConfig.filtersValue.filter
                                                                    .freeToOccupied
                                                            }
                                                        </FlexboxGrid.Item>
                                                    </FlexboxGrid.Item>
                                                )}
                                                <FlexboxGrid.Item data-cy="ipCanDevices-lpSensor-filter-filtrerFreeToOccupied">
                                                    {this.props.sensor.filterConfiguration.filter.filter.freeToOccupied}
                                                </FlexboxGrid.Item>
                                            </Fragment>
                                        )}
                                    </FlexboxGrid>
                                </List.Item>

                                {/* --- MEDIAN */}
                                <List.Item
                                    className={`panel-list ${
                                        this.props.compareMode ? medianFreeToOccupiedClass : 'list-colored-yellow'
                                    }`}>
                                    <FlexboxGrid justify="space-between" align="middle">
                                        <FlexboxGrid.Item
                                            style={{ fontWeight: 'bold' }}
                                            componentClass={Col}
                                            xs={14}
                                            data-cy="ipCanDevices-LPSensor-filter-medianFreeToOccupied">
                                            <FormattedMessage id="ipCanDevices.LPSensor.filter.medianFreeToOccupied" />
                                        </FlexboxGrid.Item>
                                        {isInEditMode ? (
                                            <FlexboxGrid.Item componentClass={Col} xs={8}>
                                                <div data-cy="ipCanDevices-lpSensor-filter-medianFreeToOccupied-edit">
                                                    <FormControl
                                                        className="form-margin"
                                                        accepter={SelectPicker}
                                                        cleanable={false}
                                                        searchable={false}
                                                        data={FILTER_MEDIAN_VALUE}
                                                        name="medianFree"
                                                        size="sm"
                                                        renderMenuItem={(label, item) => {
                                                            return (
                                                                <div
                                                                    data-cy={`lpSensor-medianFree-value-${item.value}`}>
                                                                    {label}
                                                                </div>
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </FlexboxGrid.Item>
                                        ) : (
                                            <Fragment>
                                                {/* READ CONFIG */}
                                                {this.props.compareMode && (
                                                    <FlexboxGrid.Item>
                                                        <FlexboxGrid.Item>
                                                            {
                                                                this.props.comparedData.filterConfig.filtersValue.median
                                                                    .freeToOccupied
                                                            }
                                                        </FlexboxGrid.Item>
                                                    </FlexboxGrid.Item>
                                                )}
                                                <FlexboxGrid.Item data-cy="ipCanDevices-lpSensor-filter-medianFreeToOccupied">
                                                    {this.props.sensor.filterConfiguration.filter.median.freeToOccupied}
                                                </FlexboxGrid.Item>
                                            </Fragment>
                                        )}
                                    </FlexboxGrid>
                                </List.Item>
                            </List>
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item
                            componentClass={Col}
                            xs={this.props.isRealTime ? 8 : 24}
                            style={{ padding: 0 }}>
                            <List
                                hover
                                size="sm"
                                style={{ borderRight: '1px solid black' }}
                                className="list-text-smaller">
                                {/* --- MASK OCCUPIED */}
                                <List.Item className="panel-list separator-filter">
                                    <FlexboxGrid justify="center" align="middle">
                                        <FlexboxGrid.Item>
                                            <FormattedMessage id="ipCanDevices.LPSensor.filter.filterOccupiedToFree" />
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                </List.Item>
                                <List.Item
                                    className={`panel-list ${
                                        this.props.compareMode ? maskOccupiedClass : 'list-colored-blue'
                                    }`}>
                                    <FlexboxGrid justify="space-between" align="middle">
                                        <FlexboxGrid.Item
                                            style={{ fontWeight: 'bold' }}
                                            componentClass={Col}
                                            xs={14}
                                            data-cy="ipCanDevices-LPSensor-filter-maskOccupied">
                                            <FormattedMessage id="ipCanDevices.LPSensor.filter.maskOccupied" />
                                        </FlexboxGrid.Item>
                                        {isInEditMode ? (
                                            <FlexboxGrid.Item componentClass={Col} xs={8}>
                                                <div data-cy="ipCanDevices-lpSensor-filter-maskOccupied-edit">
                                                    <FormControl
                                                        className="form-margin"
                                                        accepter={SelectPicker}
                                                        data={FILTER_MASK_SELECTOR}
                                                        searchable={false}
                                                        cleanable={false}
                                                        placement="auto"
                                                        name="maskOccupied"
                                                        size="sm"
                                                        renderMenuItem={(label, item) => {
                                                            return (
                                                                <div
                                                                    data-cy={`lpSensor-maskOccupied-value-${item.value}`}>
                                                                    {label}
                                                                </div>
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </FlexboxGrid.Item>
                                        ) : (
                                            <Fragment>
                                                {/* READ CONFIG */}
                                                {this.props.compareMode && (
                                                    <FlexboxGrid.Item>
                                                        <FlexboxGrid.Item>
                                                            {this.props.comparedData.filterConfig.maskOccupied}
                                                        </FlexboxGrid.Item>
                                                    </FlexboxGrid.Item>
                                                )}
                                                <FlexboxGrid.Item data-cy="ipCanDevices-lpSensor-filter-maskOccupied">
                                                    {this.props.sensor.filterConfiguration.maskOccupied}
                                                </FlexboxGrid.Item>
                                            </Fragment>
                                        )}
                                    </FlexboxGrid>
                                </List.Item>

                                {/* --- FILTER */}
                                <List.Item
                                    className={`panel-list ${
                                        this.props.compareMode ? filterOccupiedToFreeClass : 'list-colored-blue'
                                    }`}>
                                    <FlexboxGrid justify="space-between" align="middle">
                                        <FlexboxGrid.Item
                                            style={{ fontWeight: 'bold' }}
                                            componentClass={Col}
                                            xs={14}
                                            data-cy="ipCanDevices-LPSensor-filter-filterOccupiedToFree">
                                            <FormattedMessage id="ipCanDevices.LPSensor.filter.filterOccupiedToFree" />
                                        </FlexboxGrid.Item>
                                        {isInEditMode ? (
                                            <FlexboxGrid.Item componentClass={Col} xs={8}>
                                                <div data-cy="ipCanDevices-lpSensor-filter-occupiedToFree-edit">
                                                    <FormControl
                                                        className="form-margin"
                                                        accepter={InputNumber}
                                                        cleanable={false}
                                                        min={1}
                                                        max={240}
                                                        name="filterOccupied"
                                                        size="sm"
                                                        renderMenuItem={(label, item) => {
                                                            return (
                                                                <div
                                                                    data-cy={`lpSensor-filterOccupiedToFree-value-${item.value}`}>
                                                                    {label}
                                                                </div>
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </FlexboxGrid.Item>
                                        ) : (
                                            <Fragment>
                                                {/* READ CONFIG */}
                                                {this.props.compareMode && (
                                                    <FlexboxGrid.Item>
                                                        <FlexboxGrid.Item>
                                                            {
                                                                this.props.comparedData.filterConfig.filtersValue.filter
                                                                    .occupiedToFree
                                                            }
                                                        </FlexboxGrid.Item>
                                                    </FlexboxGrid.Item>
                                                )}
                                                <FlexboxGrid.Item data-cy="ipCanDevices-lpSensor-filter-occupiedToFree">
                                                    {this.props.sensor.filterConfiguration.filter.filter.occupiedToFree}
                                                </FlexboxGrid.Item>
                                            </Fragment>
                                        )}
                                    </FlexboxGrid>
                                </List.Item>

                                {/* --- MEDIAN */}
                                <List.Item
                                    className={`panel-list ${
                                        this.props.compareMode ? medianOccupiedToFreeClass : 'list-colored-blue'
                                    }`}>
                                    <FlexboxGrid justify="space-between" align="middle">
                                        <FlexboxGrid.Item
                                            style={{ fontWeight: 'bold' }}
                                            componentClass={Col}
                                            xs={14}
                                            data-cy="ipCanDevices-LPSensor-filter-medianOccupiedToFree">
                                            <FormattedMessage id="ipCanDevices.LPSensor.filter.medianOccupiedToFree" />
                                        </FlexboxGrid.Item>
                                        {isInEditMode ? (
                                            <FlexboxGrid.Item componentClass={Col} xs={8}>
                                                <div data-cy="ipCanDevices-lpSensor-filter-medianOccupiedToFree-edit">
                                                    <FormControl
                                                        className="form-margin"
                                                        accepter={SelectPicker}
                                                        cleanable={false}
                                                        searchable={false}
                                                        data={FILTER_MEDIAN_VALUE}
                                                        name="medianOccupied"
                                                        size="sm"
                                                        renderMenuItem={(label, item) => {
                                                            return (
                                                                <div
                                                                    data-cy={`lpSensor-medianOccupied-value-${item.value}`}>
                                                                    {label}
                                                                </div>
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </FlexboxGrid.Item>
                                        ) : (
                                            <Fragment>
                                                {/* READ CONFIG */}
                                                {this.props.compareMode && (
                                                    <FlexboxGrid.Item>
                                                        <FlexboxGrid.Item>
                                                            {
                                                                this.props.comparedData.filterConfig.filtersValue.median
                                                                    .occupiedToFree
                                                            }
                                                        </FlexboxGrid.Item>
                                                    </FlexboxGrid.Item>
                                                )}
                                                <FlexboxGrid.Item data-cy="ipCanDevices-lpSensor-filter-medianOccupiedToFree">
                                                    {this.props.sensor.filterConfiguration.filter.median.occupiedToFree}
                                                </FlexboxGrid.Item>
                                            </Fragment>
                                        )}
                                    </FlexboxGrid>
                                </List.Item>
                            </List>
                            {isInEditMode && this.props.isRealTime && (
                                <FlexboxGrid align="middle" justify="end">
                                    <FlexboxGrid.Item className="margin-right-10">
                                        <ButtonGroup style={{ marginTop: 90, marginBottom: 10 }}>
                                            <Button
                                                data-cy="ipCanDevices-lpSensor-editFilters-valid"
                                                color="green"
                                                onClick={this.validEdition}
                                                disabled={this.state.formHasError}>
                                                <FontAwesomeIcon icon={faCheck} />
                                            </Button>
                                            <Button
                                                data-cy="ipCanDevices-lpSensor-editFilters-cancel"
                                                color="red"
                                                onClick={this.toggleEditMode}>
                                                <FontAwesomeIcon icon={faTimes} />
                                            </Button>
                                        </ButtonGroup>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            )}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Form>

                {isInEditMode && !this.props.isRealTime && (
                    <List>
                        <List.Item className="paenl-list">
                            <FlexboxGrid align="middle" justify="end">
                                <FlexboxGrid.Item className="margin-right-10">
                                    <ButtonGroup>
                                        <Button
                                            data-cy="ipCanDevices-lpSensor-editModeFilters-valid"
                                            color="green"
                                            onClick={this.validEdition}
                                            disabled={this.state.formHasError}>
                                            <FontAwesomeIcon icon={faCheck} />
                                        </Button>
                                        <Button
                                            data-cy="ipCanDevices-lpSensor-editModeFilters-cancel"
                                            color="red"
                                            onClick={this.toggleEditMode}>
                                            <FontAwesomeIcon icon={faTimes} />
                                        </Button>
                                    </ButtonGroup>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>
                    </List>
                )}
            </Panel>
        );
    }
}

export default injectIntl(LPFilter);

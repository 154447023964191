import { faArrowsAlt, faHistory, faHome, faTrash, faVectorSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Button, Col, Drawer, FlexboxGrid } from 'rsuite';


import { ExternalApi } from '../../../handlers/Counter/ExternalApi';
import { DeleteExternalApiModal } from '../Modal/DeleteExternalApiModal';
import { ExternalApiInformationCard } from './ExternalApiInformation';

type Props = {
    isOpen: boolean;
    onHide: () => void;
    reloadCounters: () => void;
    externalApi?: ExternalApi;
};

export const ExternalApiDrawer = (props: Props) => {
    const intl = useIntl();


    if (!props.externalApi) {
        return null;
    }

    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);


    const handleCloseDeleteModal = (deleted: boolean) => {
        if (deleted) {
            props.onHide();
            props.reloadCounters();
        }

        setIsDeleteModalOpen(false);
    };


    return (
        <>
            <DeleteExternalApiModal externalApis={[props.externalApi]} show={isDeleteModalOpen} onHide={handleCloseDeleteModal} />

            <Drawer backdrop show={props.isOpen} onHide={props.onHide}>
                <Drawer.Header>
                    <Drawer.Title>
                        {props.externalApi.label}
                    </Drawer.Title>
                </Drawer.Header>
                <Drawer.Body className="drawer-body-without-avatar">


                    <ExternalApiInformationCard externalApi={props.externalApi} />

                </Drawer.Body>
                <Drawer.Footer>
                    <FlexboxGrid>

                        <FlexboxGrid.Item colspan={8}>
                            <Button color="red" onClick={() => setIsDeleteModalOpen(true)} block>
                                <FontAwesomeIcon icon={faTrash} className="margin-right-5" />{' '}
                                {intl.formatMessage({ id: 'counter.external.delet' })}
                            </Button>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Drawer.Footer>
            </Drawer>
        </>
    );
};

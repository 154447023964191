import L from 'leaflet';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { injectIntl, IntlProvider, WrappedComponentProps } from 'react-intl';
import { Marker } from 'react-leaflet';
import { interpret } from 'xstate';
import { DrawerInformationInterface } from '../..';
import Counter from '../../../../handlers/Counter/Counter';
import { IpCan } from '../../../../handlers/ipcan/IpCan';
import { MapLPMatrixDisplayV2 } from '../../../../handlers/map/MapLPMatrixDisplayV2';
import SecuredFragment from '../../../Auth/SecuredFragment';
import message_ch from './../../../../translations/ch.json';
import message_de from './../../../../translations/de.json';
import message_es from './../../../../translations/es.json';
import message_fr from './../../../../translations/fr.json';
import message_it from './../../../../translations/it.json';
import message_us from './../../../../translations/us.json';
import { LPMatrixDisplayPreviewGenerator } from './LPMatrixDisplayPreviewGenerator';
import LPMatrixDisplayV2IconPopup from './LPMatrixDisplayV2IconPopup';
import { topologyMachine } from './StateMachine/topologyMachine';

type Props = {
    display: MapLPMatrixDisplayV2;
    controllers: IpCan[];
    editMode: boolean;
    auth?: any;
    drawerInformation: DrawerInformationInterface;
    openDrawer: Function;
    closeDrawer: Function;
    service: any;
    counters: Counter[];
    placeTypes: any[];
    currentChange: number;
    reload: number;
} & WrappedComponentProps;

type State = {
    isAdmin: boolean;
    controller?: IpCan;
    clickOpen: boolean;
    current: any;
};

type messageType = {
    [key: string]: Record<string, any>;
};

const messages: messageType = {
    us: message_us,
    fr: message_fr,
    ch: message_ch,
    de: message_de,
    es: message_es,
    it: message_it,
};

class LPMatrixDisplayV2Icon extends React.Component<Props, State> {
    _reloadInterval: any;
    _service: any;

    constructor(props: Props) {
        super(props);

        this.state = {
            isAdmin: false,
            controller: undefined,
            clickOpen: false,
            current: topologyMachine.initialState,
        };

        this._service = interpret(
            topologyMachine.withContext({
                ...topologyMachine.context,
                display: this.props.display.getLpMatrixDisplayV2(),
            })
        ).onTransition(current => {
            this.setState({ current });
        });
    }

    componentDidMount = () => {
        const isAdmin =
            this.props.auth?.user.roles.find(data => {
                return data.name === 'Admin';
            }) || false;

        const controller = this.props.controllers.find(
            controller => controller.id === this.props.display.getLpMatrixDisplayV2().ipCanId
        );

        // if (this.props.display.getLpMatrixDisplayV2().getOnline()) {
        //     this._reloadInterval = setInterval(() => {
        //         this._service.send('TIMER');
        //         this.setState({
        //             count: this.state.count + 1,
        //         });
        //     }, 1000);
        // }

        this._service.start();

        this.setState({
            isAdmin,
            controller,
        });
    };

    componentDidUpdate = (prevProps: Props) => {
        // if (
        //     prevProps.display.getLpMatrixDisplayV2().getOnline() !==
        //     this.props.display.getLpMatrixDisplayV2().getOnline()
        // ) {
        //     if (this.props.display.getLpMatrixDisplayV2().getOnline()) {
        //         this._reloadInterval = setInterval(() => {
        //             this._service.send('TIMER');
        //             this.setState({
        //                 count: this.state.count + 1,
        //             });
        //         }, 1000);
        //     } else {
        //         clearInterval(this._reloadInterval);
        //     }
        // }

        if (this.props.reload !== prevProps.reload) {
            this._service.send('TIMER');
            this._service.send('UPDATE_DISPLAY', { value: this.props.display.getLpMatrixDisplayV2() });
        }
    };

    componentWillUnmount = () => {
        clearInterval(this._reloadInterval);

        this._service.stop();
    };

    onMouseOver = e => {
        if (!this.state.clickOpen) {
            e.target.openPopup();
        }
    };

    onMouseOut = e => {
        if (!this.state.clickOpen) {
            e.target.closePopup();
        }
    };

    openPopup = e => {
        if (!this.state.clickOpen) {
            e.target.openPopup();
        } else {
            e.target.closePopup();
        }

        this.setState({
            clickOpen: !this.state.clickOpen,
        });
    };

    generateMarker = () => {
        const language = navigator.language.split(/[-_]/)[0];
        const msg = messages[language];

        const { current } = this.state;

        let currentTopology = current.context.currentTopology;

        if (
            this.props.display.getLpMatrixDisplayV2().getIsForce() &&
            this.props.display.getLpMatrixDisplayV2().getForceType().getTopology() !== -1
        ) {
            currentTopology = this.props.display.getLpMatrixDisplayV2().getForceType().getTopology();
        }

        return (
            <Marker
                onmouseover={this.onMouseOver}
                onmouseout={this.onMouseOut}
                onclick={this.openPopup}
                position={{
                    lat: this.props.display.geoJSON[0],
                    lng: this.props.display.geoJSON[1],
                }}
                name={`lpDisplay-${this.props.display.id}`}
                icon={L.divIcon({
                    html: ReactDOMServer.renderToStaticMarkup(
                        <IntlProvider defaultLocale="us" locale={language} messages={msg} onError={() => null}>
                            <LPMatrixDisplayPreviewGenerator
                                mapDisplay={this.props.display}
                                currentTopology={currentTopology}
                            />
                        </IntlProvider>
                    ),
                    className: 'map-matrix-display-demo',
                })}>
                <LPMatrixDisplayV2IconPopup
                    controllers={this.props.controllers}
                    display={this.props.display}
                    editMode={this.props.editMode}
                    reloadDisplays={() => null}
                    hover={!this.state.clickOpen}
                    drawerInformation={this.props.drawerInformation}
                    openDrawer={this.props.openDrawer}
                    closeDrawer={this.props.closeDrawer}
                    service={this.props.service}
                    counters={this.props.counters}
                    placeTypes={this.props.placeTypes}
                />
            </Marker>
        );
    };

    render = () => {
        if (this.props.display.getIsShow()) {
            return this.generateMarker();
        } else {
            return <SecuredFragment authorizedRoles={[]}>{this.generateMarker()}</SecuredFragment>;
        }
    };
}

export default injectIntl(LPMatrixDisplayV2Icon);

import moment from 'moment';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { authHeader } from '../../redux/helpers';
import { axiosService, webSocketService } from '../../redux/services';

type Props = {
    counterId: number;
    height: number;
    parking?: Record<string, any>;
    level?: Record<string, any>;
    displayLegend: boolean;
    responsive?: boolean;
} & WrappedComponentProps;

type State = {
    options: Record<string, any>;
    loading: boolean;
    data: {
        labels: Array<Date>;
        datasets: Array<any>;
    };
    redraw: boolean;
    // tooltipMaxValue: Record<string, any> | undefined;
    // tooltipValue: Record<string, any> | undefined;
    // tooltipMax: Record<string, any> | undefined;
};

class OccupationGraph extends React.Component<Props, State> {
    reference: Line | null = null;
    values: [number | null, number | null][];
    dateMaxValue: Date | null;
    constructor(props) {
        super(props);

        this.values = [];
        this.dateMaxValue = null;

        const options = {
            maintainAspectRatio: false,
            responsive: this.props.responsive ? this.props.responsive : false,
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                            max: 100,
                            maxTicksLimit: 10,
                            callback: value => `${value}%`,
                        },
                    },
                ],
                xAxes: [
                    {
                        unit: 'hour',
                        type: 'time',
                        distribution: 'series',
                        // major: {
                        //     enabled: true
                        // },
                        time: {
                            displayFormats: {
                                millisecond: 'HH[h]mm',
                                second: 'HH[h]mm',
                                minute: 'HH[h]mm',
                                hour: 'HH[h]mm',
                                day: 'HH[h]mm',
                                week: 'HH[h]mm',
                                month: 'HH[h]',
                                quarter: 'HH[h]',
                                year: 'HH[h]',
                            },
                        },
                        gridLines: {
                            offset: false,
                            gridLines: {
                                offsetGridLines: false,
                            },
                        },
                    },
                ],
            },
            legend: {
                display: this.props.displayLegend,
            },
            tooltips: {
                callbacks: {
                    title: (tooltipItem, data) => {
                        return moment(data.labels[tooltipItem[0].index]).format('DD/MM/YYYY HH:mm');
                    },
                    label: tooltipItem => {
                        // REMINDER !!
                        // datasetIndex 0 => Occupation values (number)
                        // datasetIndex 1 => MaxValue of the serie (number)
                        // datasetIndex 2 => MinMaxValues of the point ([number, number])

                        if (tooltipItem.datasetIndex === 0) {
                            return `${this.props.intl.formatMessage({
                                id: 'occupationGraph.occupation.maxValue',
                            })} ${tooltipItem.value}% (${moment(this.dateMaxValue).format('DD/MM/YYYY HH:mm')})`;
                        }

                        if (tooltipItem.datasetIndex === 1) {
                            return `${this.props.intl.formatMessage({
                                id: 'occupationGraph.occupation.value',
                            })} ${tooltipItem.value}% (${this.state.data.datasets[1].values[tooltipItem.index]})`;
                        }

                        if (tooltipItem.datasetIndex === 2) {
                            let percentValues = JSON.parse(tooltipItem.value);
                            return `${this.props.intl.formatMessage({
                                id: 'occupationGraph.occupation.min',
                            })} ${percentValues[0]}% (${
                                this.state.data.datasets[2].values[tooltipItem.index][0]
                            }) / ${this.props.intl.formatMessage({ id: 'occupationGraph.occupation.max' })} ${
                                percentValues[1]
                            } (${this.state.data.datasets[2].values[tooltipItem.index][1]})`;
                        }
                    },
                },
                mode: 'index',
            },
        };

        const labels: Array<Date> = [];
        const datasets = [
            {
                label: this.props.intl.formatMessage({
                    id: 'occupationGraph.maxOccupation',
                }),
                type: 'line',
                show: false,
                fill: false,
                borderColor: 'rgba(255, 19, 0, 1)',
                backgroundColor: 'rgba(255, 19, 0, 1)',
                pointBorderColor: 'transparent',
                pointRadius: 0,
                borderWidth: 5,
                data: [],
                values: [],
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'occupationGraph.occupation',
                }),
                show: false,
                fill: true,
                lineTension: 0.1,
                backgroundColor: 'rgba(54, 162, 235, 1)',
                // borderColor: 'rgba(119, 221, 119,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(54, 162, 235, 1)',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(54, 162, 235, 1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: [],
                values: [],
            },
            {
                label: this.props.intl.formatMessage({
                    id: 'occupationGraph.minAndMaxOccupation',
                }),
                type: 'bar',
                show: false,
                backgroundColor: 'rgba(255, 159, 64, 0.7)',
                data: [],
                barThickness: 'flex',
                categoryPercentage: 1,
                barPercentage: 1,
                minBarLength: 20,
                values: [],
            },
        ];

        this.state = {
            options,
            loading: true,
            data: {
                labels,
                datasets,
            },
            redraw: false,
        };
    }

    componentDidUpdate() {
        this.state.redraw &&
            this.setState({
                redraw: false,
            });
    }

    componentDidMount() {
        webSocketService.joinRoom('counter');

        const dateBefore = moment().toISOString();
        const dateAfter = moment().subtract(12, 'hours').toISOString();

        axiosService
            .getAxios()
            .get(`/statistics/counter/${this.props.counterId}/Date/${dateAfter};${dateBefore}`, {
                headers: authHeader(),
            })
            .then(response => {
                let labels: Array<Date> = [];
                let occupationPercent: Array<number> = [];
                let occupationValue: Array<number> = [];
                let maxPercentValue: Array<number | undefined> = [];
                let maxValue: Array<number | undefined> = [];
                let minMaxPercent: [number | null, number | null][] = [];
                let minMaxValues: [number | null, number | null][] = [];

                const stats = response.data;

                // Init the max value of the serie
                let maxSeriePercent = 0;
                let maxSerieValue = 0;
                let maxSerieValueIndex = 0;

                for (let i = 0; i < stats.length; i++) {
                    const stat: StatInterface = stats[i].result.counterValue.all;

                    let counterMaxPercent: number = stats[i].result.counterMax?.maxOccRate
                        ? parseFloat(stats[i].result.counterMax.maxOccRate.toFixed(2))
                        : 0;
                    let counterMinPercent: number = stats[i].result.counterMin?.minOccRate
                        ? parseFloat(stats[i].result.counterMin.minOccRate.toFixed(2))
                        : 0;

                    let counterMaxValue: number = stats[i].result.counterMax?.maxOccRate
                        ? stats[i].result.counterMax.maxOcc
                        : 0;
                    let counterMinValue: number = stats[i].result.counterMin?.minOccRate
                        ? stats[i].result.counterMin.minOcc
                        : 0;

                    //--- check counterMaxMin for update value
                    if(stats[i].result.counterMax?.all?.maxOccRate)
                        counterMaxPercent = parseFloat(Number(stats[i].result.counterMax.all.maxOccRate).toFixed(2));
                    if(stats[i].result.counterMin?.all?.minOccRate)
                        counterMinPercent = parseFloat(Number(stats[i].result.counterMin.all.minOccRate).toFixed(2));
                    if(stats[i].result.counterMax?.all?.maxOcc)
                        counterMaxValue = parseFloat(Number(stats[i].result.counterMax.all.maxOcc).toFixed(2));
                    if(stats[i].result.counterMin?.all?.minOcc)
                        counterMinValue = parseFloat(Number(stats[i].result.counterMin.all.minOcc).toFixed(2));


                    const date = moment(stats[i].createdAt).toDate();

                    // Add date on X axis
                    labels.push(date);

                    // Determine the value of the stat;
                    let statValue = 0;

                    if (stat.total > 0) {
                        statValue = parseFloat(((stat.occupied / stat.total) * 100).toFixed(2));
                    }

                    occupationPercent.push(statValue);
                    occupationValue.push(stat.occupied);

                    // Add min & max value in array
                    // TODO: Change with real values from API
                    minMaxPercent.push([counterMinPercent, counterMaxPercent]);
                    minMaxValues.push([counterMinValue, counterMaxValue]);

                    maxPercentValue.push(0);

                    if (maxSeriePercent < counterMaxPercent) {
                        maxSeriePercent = counterMaxPercent;
                        maxSerieValue = counterMaxValue;
                        maxSerieValueIndex = i;
                        this.dateMaxValue = labels[i];
                    }
                }

                // Fill maxPercentValue array with undefined to not render points
                maxPercentValue.fill(maxSeriePercent, 0, maxPercentValue.length);
                // Replace the maxPercentValue max value index with occupation max value
                // maxPercentValue[maxSerieValueIndex] = maxSeriePercent;

                // Fill maxValue array with undefined to not render points
                maxValue.fill(maxSerieValue, 0, maxPercentValue.length);
                // Replace the maxValue max value index with occupation max value
                // maxValue[maxSerieValueIndex] = maxSerieValue;

                const datasets = [...this.state.data.datasets];
                datasets[0].data = maxPercentValue;
                datasets[0].values = maxValue;
                datasets[1].data = occupationPercent;
                datasets[1].values = occupationValue;
                datasets[2].data = minMaxPercent;
                datasets[2].values = minMaxValues;

                this.setState({
                    data: {
                        ...this.state.data,
                        labels,
                        datasets,
                    },
                });
            })
            .catch(err => console.error(err));

        webSocketService.onEvent('counter:stat', this.statCounterEvent);
    }

    statCounterEvent = event => {
        if (this.props.counterId === event.counterId) {
            let maxPercentValue: Array<number | undefined> = [];
            let maxValue: Array<number | undefined> = [];

            const stat: StatInterface = event.result.counterValue.all;
            let counterMaxPercent: number = event.result.counterMax?.maxOccRate
                ? parseFloat(event.result.counterMax.maxOccRate.toFixed(2))
                : 0;
            let counterMinPercent: number = event.result.counterMin?.minOccRate
                ? parseFloat(event.result.counterMin.minOccRate.toFixed(2))
                : 0;
            let counterMaxValue: number = event.result.counterMax?.maxOccRate
                ? parseFloat(event.result.counterMax.maxOcc.toFixed(2))
                : 0;
            let counterMinValue: number = event.result.counterMin?.minOccRate
                ? parseFloat(event.result.counterMin.minOcc.toFixed(2))
                : 0;
            let date = moment(event.createdAt).toDate();


            //--- check counterMaxMin for update value
            if(event.result.counterMax?.all?.maxOccRate)
                counterMaxPercent = parseFloat(Number(event.result.counterMax.all.maxOccRate).toFixed(2));
            if(event.result.counterMin?.all?.minOccRate)
                counterMinPercent = parseFloat(Number(event.result.counterMin.all.minOccRate).toFixed(2));
            if(event.result.counterMax?.all?.maxOcc)
                counterMaxValue = parseFloat(Number(event.result.counterMax.all.maxOcc).toFixed(2));
            if(event.result.counterMin?.all?.minOcc)
                counterMinValue = parseFloat(Number(event.result.counterMin.all.minOcc).toFixed(2));


            // Recalculation of max oldest value
            const maxOldDate = moment().subtract(12, 'hour').toDate();
            const datasets = [...this.state.data.datasets];
            const labels = [...this.state.data.labels];

            let elementsToDelete: Array<number> = [];

            for (let i = 0; i < this.state.data.labels.length; i++) {
                if (maxOldDate > this.state.data.labels[i]) {
                    elementsToDelete.push(i);
                }
            }

            // Delete elements that are too old
            for (let element in elementsToDelete) {
                labels.splice(elementsToDelete[element], 1);
                datasets[1].data.splice(elementsToDelete[element], 1);
                datasets[2].data.splice(elementsToDelete[element], 1);
            }

            // Add data to label and arrays
            labels.push(date);

            const statValue = parseFloat(((stat.occupied / stat.total) * 100).toFixed(2));

            datasets[1].data.push(statValue);
            datasets[1].values.push(stat.occupied);
            datasets[2].data.push([counterMinPercent, counterMaxPercent]);
            datasets[2].values.push([counterMinValue, counterMaxValue]);

            // Init the max value of the serie
            let maxSeriePercent = 0;
            let maxSerieValue = 0;
            let maxSerieValueIndex = 0;

            for (let i = 0; i < datasets[2].data.length; i++) {
                const currentValue = datasets[2].data[i];
                if (maxSeriePercent < currentValue[1]) {
                    maxSeriePercent = currentValue[1];
                    maxSerieValue = datasets[2].values[i][1];
                    maxSerieValueIndex = i;
                    this.dateMaxValue = labels[i];
                }
                maxPercentValue.push(0);
            }

            // Fill maxPercentValue array with undefined to not render points
            maxPercentValue.fill(maxSeriePercent, 0, maxPercentValue.length);
            // Replace the maxPercentValue max value index with occupation max value
            // maxPercentValue[maxSerieValueIndex] = maxSeriePercent;

            // Fill maxValue array with undefined to not render points
            maxValue.fill(maxSerieValue, 0, maxPercentValue.length);
            // Replace the maxValue max value index with occupation max value
            // maxValue[maxSerieValueIndex] = maxSerieValue;

            datasets[0].data = maxPercentValue;
            datasets[0].values = maxValue;

            this.setState({
                data: {
                    ...this.state.data,
                    labels,
                    datasets,
                },
                redraw: true,
            });
        }
    };

    render() {
        return (
            <Line
                height={this.props.height}
                data={this.state.data}
                options={this.state.options}
                ref={reference => (this.reference = reference)}
                redraw={this.state.redraw}
            />
        );
    }
}

export default injectIntl(OccupationGraph);

export interface StatInterface {
    forced: number;
    free: number;
    occupied: number;
    overstayFree: number;
    overstayOccupied: number;
    total: number;
}

import Counter from '../handlers/Counter/Counter';

interface ITestFunction {}

export function testFunction<T>(finalFunction: string): T | undefined {
    const fun = new Function(finalFunction);

    try {
        return fun();
    } catch (e) {
        console.error(e);
    }

    return;
}

export const tryFunction = async (finalFunction: string) => {
    const fun = new Function(finalFunction);

    try {
        fun();

        return Promise.resolve();
    } catch (e) {
        // console.error(e);

        return Promise.reject(e);
    }
};

export const tryFunctionCpt = async (finalFunction: string, cpt: Counter, data: any) => {
    const fun = new Function('cpt', 'data', finalFunction);

    try {
        fun(cpt, data);

        return Promise.resolve();
    } catch (e) {
        // console.error(e);

        return Promise.reject(e);
    }
};

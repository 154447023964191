import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Alert,
    Button,
    ButtonGroup,
    ControlLabel,
    Form,
    FormControl,
    FormGroup,
    Input,
    Modal,
    SelectPicker,
} from 'rsuite';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';
import { ExternalApi } from '../../../handlers/Counter/ExternalApi';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';

type Props = {
    isOpen: boolean;
    onHide: () => void;
    reloadCounter: () => void;
};

type FormValue = {
    label: string;
    decodeType: object;
};

const initialValues: FormValue = {
    label: '',
    decodeType: {
        type: 'object',
        properties: {
            foo: { type: 'integer' },
            bar: { type: 'string', nullable: true },
        },
        required: ['foo'],
        additionalProperties: false,
    },
};

const Textarea = React.forwardRef((props, ref) => <Input {...props} as="decodeType" ref={ref} />);

export const CreateExternalApiModal = (props: Props) => {
    const intl = useIntl();

    const [isCreating, setIsCreating] = React.useState<boolean>(false);
    const [jsonIsValid, setJsonIsValid] = React.useState<boolean>(true);

    const [formValue, setFormValue] = React.useState<FormValue>(initialValues);
    const [decodeType, setDecodeType] = React.useState<object>(initialValues.decodeType);

    const handleCancel = () => {
        setFormValue(initialValues);
        props.onHide();
    };

    const handleValid = () => {
        setIsCreating(true);

        const { label } = formValue;


        axiosService
            .getAxios()
            .post<ExternalApi>(
                '/counters/external-api',
                {
                    label,
                    decodageType: decodeType,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(() => {
                Alert.success(intl.formatMessage({ id: 'counter.external.create.success' }));

                props.reloadCounter();

                setIsCreating(false);

                handleCancel();
            })
            .catch(err => {
                console.error(err);

                Alert.error(intl.formatMessage({ id: 'camera.create.error' }));

                setIsCreating(false);
            });
    };

    const formIsValid = (): boolean => {
        const { label } = formValue;

        if (label) {
            return true;
        }

        return false;
    };

    return (
        <Modal show={props.isOpen} onHide={handleCancel} backdrop="static" size="sm">
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="externalApi.create.title" />
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form formValue={formValue} onChange={value => setFormValue(value as FormValue)} fluid>
                    {/* LABEL */}
                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="externalApi.create.form.label" />
                        </ControlLabel>
                        <FormControl name="label" />
                    </FormGroup>
                    {/* ExternalApi */}
                    <FormGroup>
                        <ControlLabel>
                            <FormattedMessage id="externalApi.create.form.decodeType" />
                        </ControlLabel>
                        <FormControl name="decodeType" type="hidden" />
                        <JSONInput
                            id="decodeType_json"
                            placeholder={initialValues.decodeType}
                            locale={locale}
                            height="450px"
                            width="100%"
                            onChange={data => {
                                try {
                                    setDecodeType(JSON.parse(data.json));
                                    setJsonIsValid(true);
                                } catch (e) {
                                    console.error(e);
                                    setJsonIsValid(false);
                                }
                            }}
                        />
                    </FormGroup>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <ButtonGroup>
                    <Button
                        onClick={handleCancel}
                        data-cy="counter-event-deleteModal-cancel"
                        color="red"
                        disabled={isCreating}>
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                    <Button
                        onClick={handleValid}
                        data-cy="counter-event-deleteModal-valid"
                        appearance="primary"
                        loading={isCreating}
                        disabled={!formIsValid() || !jsonIsValid}>
                        <FontAwesomeIcon icon={faCheck} />
                    </Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
};

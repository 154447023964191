import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useIntl } from 'react-intl';
import { Button, Panel } from 'rsuite';


import { InformationEditMode } from './InformationEditMode';
import { InformationViewMode } from './InformationViewMode';
import { ExternalApi } from '../../../../handlers/Counter/ExternalApi';
import PanelHeader from '../../../Custom/PanelHeader';

type Props = {
    externalApi: ExternalApi;
};

export const ExternalApiInformationCard = (props: Props) => {
    const [isEditMode, setIsEditMode] = React.useState(false);

    const intl = useIntl();

    return (
        <Panel
            className="panel-small"
            shaded
            bordered
            bodyFill
            header={
                <PanelHeader
                    title={intl.formatMessage({ id: 'externalApi.information' })}
                    buttons={[
                        <Button
                            key="externalApi-edit"
                            color="blue"
                            size="sm"
                            disabled={isEditMode}
                            onClick={() => setIsEditMode(true)}>
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>,
                    ]}
                />
            }>
            {isEditMode ? (
                <InformationEditMode externalApi={props.externalApi} setViewMode={() => setIsEditMode(false)} />
            ) : (
                <InformationViewMode externalApi={props.externalApi} />
            )}
        </Panel>
    );
};

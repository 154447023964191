import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Col, FlexboxGrid, Message, Panel, PanelGroup, Placeholder } from 'rsuite';
import { Parking, ParkingInterface } from '../../../handlers/parking/Parking';
import { parkingHandler } from '../../../handlers/parking/parking.handler';
import { authHeader } from '../../../redux/helpers';
import { axiosService } from '../../../redux/services';
import noImage from '../../../style/assets/eye-slash-solid.svg';
import ProgressComponent from './Progress/ProgressComponent';
import { SensorPlaceTypesProgress } from './Progress/SensorPlaceTypesProgress';

type Props = {
    sensorPlaceTypes: Array<Record<string, any>>;
    parking: Record<string, any>;
    context: Record<string, any>;
    countersNeedReload?: boolean;
    handleCounterDontNeedReload?: Function;
    handleButtonClick: Function;
    counters: Record<string, any>;
    auth: any;
    parkings: Parking[];
    mapZones: any[];
};

type State = {
    placeTypes: Array<Record<string, any>>;
    loading: boolean;
    parkings: ParkingInterface[];
};
class ProgressBars extends React.PureComponent<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            placeTypes: [],
            loading: true,
            parkings: [],
        };
    }

    componentDidMount() {
        axiosService
            .getAxios()
            .get('/place-type', { headers: authHeader() })
            .then(placeTypesResponse => {
                let placeTypes = placeTypesResponse.data;
                let sensorPlacesTypes = this.props.sensorPlaceTypes;

                for (let pt in placeTypes) {
                    for (let spt in sensorPlacesTypes) {
                        if (
                            placeTypes[pt].sensorPlaceTypes.length > 0 &&
                            placeTypes[pt].sensorPlaceTypes[0].id === sensorPlacesTypes[spt].id
                        ) {
                            placeTypes[pt].sensorPlaceTypes[0] = sensorPlacesTypes[spt];
                        }
                    }
                }

                this.setState({
                    placeTypes,
                    loading: false,
                });
            });

        axiosService
            .getAxios()
            .get('/parkings', { headers: authHeader() })
            .then(parkingsResponse => {
                this.setState({
                    parkings: parkingsResponse.data.map(parking => parkingHandler(parking)),
                });
            });
    }

    render() {
        let cardSize = 0;

        if (this.props.context.progressBarsFailure || this.props.context.progressStatusFailure) {
            return (
                <FlexboxGrid>
                    <FlexboxGrid.Item componentClass={Col} xs={21}>
                        <Message type="error" description={<FormattedMessage id="counters.progress.fail" />} />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item componentClass={Col} xs={3}>
                        {this.props.parking.image ? (
                            <img
                                className="text-center"
                                src={`data:image/${this.props.parking.imageType};base64, ${this.props.parking.image}`}
                                style={{
                                    height: 167,
                                    backgroundColor: 'white',
                                    lineHeight: 1,
                                }}
                                alt="logo"
                            />
                        ) : (
                            <img
                                className="text-center"
                                src={noImage}
                                style={{
                                    height: 167,
                                    backgroundColor: 'white',
                                    lineHeight: 1,
                                }}
                                alt="empty"
                            />
                        )}
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            );
        }
        if (this.props.context.progressLoading || this.state.loading) {
            return (
                <FlexboxGrid>
                    <FlexboxGrid.Item componentClass={Col} xs={21}>
                        <Placeholder.Grid rows={5} columns={3} active />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item componentClass={Col} xs={3}>
                        {this.props.parking.image ? (
                            <img
                                className="text-center"
                                src={`data:image/${this.props.parking.imageType};base64, ${this.props.parking.image}`}
                                style={{
                                    height: 167,
                                    backgroundColor: 'white',
                                    lineHeight: 1,
                                }}
                                alt="logo"
                            />
                        ) : (
                            <img
                                className="text-center"
                                src={noImage}
                                style={{
                                    height: 167,
                                    backgroundColor: 'white',
                                    lineHeight: 1,
                                }}
                                alt="empty"
                            />
                        )}
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            );
        } else {
            let nbVisibleCountersInProgress3 = 0;
            //---
            for(let i = 0; i < this.props.context.progress[2].length; i++) {
                if(this.props.context.progress[2][i].isView && this.props.context.progress[2][i].type == 'Zone') {
                    nbVisibleCountersInProgress3++;
                }
            }
            //---
            if (
                this.props.context.progress[0].length > 0 &&
                this.props.context.progress[1].length > 0 &&
                nbVisibleCountersInProgress3 > 0
            ) {
                cardSize = 7;
            } else if (this.props.context.progress[0].length > 0 && this.props.context.progress[1].length > 0) {
                cardSize = 10;
            } else {
                cardSize = 21;
            }

            const countersToDisplay: number[] = [];

            const userParkingList = this.props.auth.user.parkingList;
            const authorizedParkings = this.props.parkings.filter(
                parking => !userParkingList.includes(parking.getId())
            );
            const authorizedLevels = authorizedParkings
                .map(park => park.getLevels())
                .flat()
                .map(level => level.getId());

            if (this.props.parking.id) {
                this.props.counters.forEach(counter => {
                    const currentCounterParkingId = counter.parking?.id;
                    const currentCounterLevelId = counter.level?.id;

                    if (
                        counter.type === 'Park' &&
                        currentCounterParkingId &&
                        !userParkingList.includes(currentCounterParkingId)
                    ) {
                        countersToDisplay.push(counter.id);
                    }

                    if (counter.type === 'Level' && currentCounterLevelId) {
                        for (let p in authorizedParkings) {
                            for (let l in authorizedParkings[p].getLevels()) {
                                if (authorizedParkings[p].getLevels()[l].getId() === currentCounterLevelId) {
                                    countersToDisplay.push(counter.id);
                                }
                            }
                        }
                    }

                    if (counter.type === 'Zone') {
                        this.props.mapZones.forEach(zone => {
                            if (
                                zone.counter?.id &&
                                !countersToDisplay.includes(zone.counter.id) &&
                                authorizedLevels.includes(zone.level.id)
                            ) {
                                countersToDisplay.push(zone.counter?.id);
                            }
                        });
                    }
                });
            }

            return (
                <FlexboxGrid className="mapProgressBar" justify="space-around">
                    {this.props.context.progress[0].length > 0 && (
                        <FlexboxGrid.Item componentClass={Col} className="flexbox-grid-item-counters" xs={cardSize}>
                            <PanelGroup accordion defaultActiveKey={0}>
                                {this.props.context.progress[0].map((counter, index) => {
                                    if (countersToDisplay.includes(counter.id)) {
                                        return (
                                            <Panel
                                                key={`counter-1-${index}`}
                                                className="rs-panel-collapsible-no-carret margin-top-0 panel-small-progress no-radius"
                                                eventKey={index}
                                                header={
                                                    <ProgressComponent
                                                        label={counter.label}
                                                        total={counter.counterValue?.all.total}
                                                        free={counter.counterValue?.all.free}
                                                        occupied={counter.counterValue?.all.occupied}
                                                        size={12}
                                                    />
                                                }>
                                                <SensorPlaceTypesProgress
                                                    key={`counter-progress-1-${index}`}
                                                    sensorPlaceTypes={this.props.sensorPlaceTypes}
                                                    counterValue={counter.counterValue}
                                                />
                                            </Panel>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </PanelGroup>
                        </FlexboxGrid.Item>
                    )}

                    {this.props.context.progress[1].length > 0 && (
                        <FlexboxGrid.Item componentClass={Col} className="flexbox-grid-item-counters" xs={cardSize}>
                            <PanelGroup accordion>
                                {this.props.context.progress[1]
                                    .sort((x, y) => x.parking?.id - y.parking?.id || x.order - y.order)
                                    .map((counter, index) => {
                                        if (countersToDisplay.includes(counter.id)) {
                                            return (
                                                <Panel
                                                    key={`counter-2-${index}`}
                                                    className="rs-panel-collapsible-no-carret margin-top-0 panel-small-progress no-radius"
                                                    header={
                                                        <ProgressComponent
                                                            label={counter.label}
                                                            total={counter.counterValue.all.total}
                                                            free={counter.counterValue.all.free}
                                                            occupied={counter.counterValue.all.occupied}
                                                            size={12}
                                                        />
                                                    }>
                                                    <SensorPlaceTypesProgress
                                                        key={`counter-progress-2-${index}`}
                                                        sensorPlaceTypes={this.props.sensorPlaceTypes}
                                                        counterValue={counter.counterValue}
                                                    />
                                                </Panel>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                            </PanelGroup>
                        </FlexboxGrid.Item>
                    )}

                    {nbVisibleCountersInProgress3 > 0 && (
                        <FlexboxGrid.Item componentClass={Col} className="flexbox-grid-item-counters" xs={cardSize}>
                            <PanelGroup accordion>
                                {this.props.context.progress[2].map((counter, index) => {
                                    if (counter.isView && countersToDisplay.includes(counter.id)) {
                                        return (
                                            <Panel
                                                key={`counter-3-${index}`}
                                                className="rs-panel-collapsible-no-carret margin-top-0 panel-small-progress no-radius"
                                                header={
                                                    <ProgressComponent
                                                        label={counter.label}
                                                        total={counter.counterValue.all.total}
                                                        free={counter.counterValue.all.free}
                                                        occupied={counter.counterValue.all.occupied}
                                                        size={12}
                                                    />
                                                }>
                                                <SensorPlaceTypesProgress
                                                    key={`counter-progress-3-${index}`}
                                                    sensorPlaceTypes={this.props.sensorPlaceTypes}
                                                    counterValue={counter.counterValue}
                                                />
                                            </Panel>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </PanelGroup>
                        </FlexboxGrid.Item>
                    )}

                    <FlexboxGrid.Item componentClass={Col} className="flexbox-grid-item-counters text-center">
                        {this.props.parking.image ? (
                            <img
                                className="text-center"
                                src={`data:image/${this.props.parking.imageType};base64, ${this.props.parking.image}`}
                                style={{
                                    maxHeight: 167,
                                    maxWidth: 200,
                                    // width: '100%',
                                    aspectRatio: '1/1',
                                    backgroundColor: 'white',
                                }}
                                alt={`logo-${this.props.parking.name}`}
                            />
                        ) : (
                            <img
                                className="text-center"
                                src={noImage}
                                style={{
                                    height: 167,
                                    backgroundColor: 'white',
                                    lineHeight: 1,
                                }}
                                alt="empty"
                            />
                        )}
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            );
        }
    }
}

function mapStateToProps(state) {
    const { auth } = state;

    return { auth };
}

export default connect(mapStateToProps)(ProgressBars);

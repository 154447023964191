import { faCheck, faLock, faTimes, faTrash, faWifi } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ConditionalStyles, TableColumn } from 'react-data-table-component';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import Loader from 'react-loader-advanced';
import { Button, ButtonGroup, Icon, IconButton, Panel } from 'rsuite';
import TCMSensor from '../../handlers/ipCanDevices/TCMSensor';
import { rolesConstants } from '../../static/roles';
import SecuredFragment from '../Auth/SecuredFragment';
import PanelHeader from '../Custom/PanelHeader';
import ElementTable from '../ReactDataTableComponent/ElementTable';
import DeleteSensorModal from './Drawer/Sensor/Modal/DeleteSensorModal';
import ForceSensorModal from './Drawer/Sensor/Modal/ForceSensorModal';
import SensorDrawer from './Drawer/Sensor/SensorDrawer';
import calibrationCell from './Table/calibrationCell';
import tcmDetectionStateCell from './Table/tcmDetectionStateCell';
import loginStateCell from './Table/loginStateCell';
import { maintenanceStateInformation } from './Table/maintenanceStateInformation';
import tcmSensorForcedCell from './Table/tcmSensorForcedCell';
import CopyTcmSensorModal from './TCMSensors/CopyTcmSensorModal';

type Props = {
    sensors: Array<TCMSensor>;
    isLoading: boolean;
    intl: Record<string, any>;
    reloadDevices: Function;
    ipCanId: number;
} & WrappedComponentProps;

type State = {
    sensorDrawerOpen: boolean;
    selectedSensor: TCMSensor | undefined;
    sensorsChecked: Array<number>;
    showDeleteModal: boolean;
    showForceModal: boolean;
    toggledClearRows: boolean;
    sensorToCopy: TCMSensor | null;
    sensorsToCopyOn: Array<TCMSensor>;
    showSensorCopyModal: boolean;
};

class SensorsTable extends React.Component<Props, State> {
    columns: TableColumn<TCMSensor>[];
    conditionalRowStyles: ConditionalStyles<Record<string, any>>[];
    constructor(props) {
        super(props);

        this.state = {
            sensorDrawerOpen: false,
            selectedSensor: undefined,
            sensorsChecked: [],
            showDeleteModal: false,
            showForceModal: false,
            toggledClearRows: false,
            sensorToCopy: null,
            sensorsToCopyOn: [],
            showSensorCopyModal: false,
        };

        this.columns = [
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.sensors.online',
                }),
                selector: row => row.online,
                center: true,
                sortable: true,
                grow: 1,
                cell: row => loginStateCell(row.online, row.lastOnlineStateDateFormatted),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.sensors.detectionState',
                }),
                grow: 1,
                center: true,
                cell: row => tcmDetectionStateCell(row.detectionState, row.detectionStateTimeFormatted),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.sensors.maintenanceState',
                }),
                selector: row => row.getMaintenanceState(),
                sortable: true,
                center: true,
                grow: 2,
                cell: row => (
                    <SecuredFragment authorizedRoles={[rolesConstants.TCMSensor.UPDATE_MAINTENANCE_STATE]}>
                        {maintenanceStateInformation(row.getMaintenanceState(), row.getNotepad())}
                    </SecuredFragment>
                ),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.sensors.deviceId',
                }),
                grow: 1,
                selector: row => row.deviceId,
                sortable: true,
                center: true,
                cell: row => <div data-cy="sensor-deviceId">{row.deviceId}</div>,
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.sensors.softwareVersion',
                }),
                grow: 1,
                center: true,
                cell: row => <div data-cy="sensor-softwareVersion">{row.versionSoft}</div>,
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.sensors.isForce',
                }),
                grow: 1,
                center: true,
                cell: row => tcmSensorForcedCell(row.isForce, row.forceType, row.endForceTimeFormatted),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.sensors.calibration',
                }),
                grow: 1,
                center: true,
                cell: row => calibrationCell(row.calibration),
            },
            {
                name: this.props.intl.formatMessage({
                    id: 'ipCanDevices.sensors.copy',
                }),
                center: true,
                grow: 1,
                button: true,
                cell: row => {
                    if (this.state.sensorToCopy?.id === row.id) {
                        return (
                            <ButtonGroup size="sm">
                                <Button color="red" onClick={() => this.unsetCopyMode()}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                                <Button
                                    color="green"
                                    disabled={this.state.sensorsToCopyOn.length === 0}
                                    onClick={() => this.showSensorCopyModal()}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                            </ButtonGroup>
                        );
                    } else if (!this.state.sensorToCopy) {
                        return (
                            <IconButton
                                data-cy="lpSensor-copy"
                                color="blue"
                                icon={<Icon icon="copy" />}
                                size="sm"
                                onClick={() => this.setCopyMode(row)}
                            />
                        );
                    } else {
                        return (
                            <IconButton
                                data-cy="lpSensor-copy"
                                color="blue"
                                icon={<Icon icon="copy" />}
                                size="sm"
                                onClick={() => this.setCopyMode(row)}
                            />
                        );
                    }
                },
            },
        ];

        this.conditionalRowStyles = [
            {
                when: row => row.isForce,
                style: {
                    backgroundColor: 'rgba(230, 81, 0, 0.15)',
                },
            },
            {
                when: row => row.id === this.state.sensorToCopy?.id,
                style: {
                    backgroundColor: 'rgba(0, 200, 0, 0.40)',
                },
            },
            {
                when: row => this.state.sensorToCopy && this.state.sensorsToCopyOn.find(sensor => sensor.id === row.id),
                style: {
                    backgroundColor: 'rgba(128, 0, 128, 0.40)',
                },
            },
        ];

        this.clickRow = this.clickRow.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.showDeleteModal = this.showDeleteModal.bind(this);
        this.hideDeleteModal = this.hideDeleteModal.bind(this);
        this.showForceModal = this.showForceModal.bind(this);
        this.hideForceModal = this.hideForceModal.bind(this);
        this.selectedRowsChange = this.selectedRowsChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.sensors !== prevProps.sensors) {
            const sensorIndex = this.props.sensors.findIndex(sensor => sensor.id === this.state.selectedSensor?.id);

            this.setState({
                selectedSensor: this.props.sensors[sensorIndex],
            });
        }
    }

    clickRow(data) {
        const sensorIndex = this.props.sensors.findIndex(sensor => sensor.id === data.id);

        this.setState({
            selectedSensor: this.props.sensors[sensorIndex],
            sensorDrawerOpen: true,
        });
    }

    closeDrawer(shouldReload: boolean = false) {
        this.setState({
            sensorDrawerOpen: false,
            selectedSensor: undefined,
        });

        if (shouldReload) {
            this.props.reloadDevices();
        }
    }

    showDeleteModal(e) {
        e.stopPropagation();
        this.setState({
            showDeleteModal: true,
        });
    }

    hideDeleteModal(shouldReload: boolean = false) {
        this.setState({
            showDeleteModal: false,
            toggledClearRows: true,
            sensorsChecked: [],
        });

        if (shouldReload) {
            this.props.reloadDevices();
        }
    }

    selectedRowsChange = ({ selectedRows }) => {
        if (this.state.sensorToCopy) {
            this.setState({
                sensorsToCopyOn: selectedRows,
            });
        } else {
            this.setState({
                sensorsChecked: selectedRows.map(row => row.id),
            });
        }
    };

    setCopyMode = id => {
        this.setState({
            sensorToCopy: id,
            toggledClearRows: !this.state.toggledClearRows,
        });
    };

    unsetCopyMode = () => {
        this.setState({
            sensorToCopy: null,
            toggledClearRows: !this.state.toggledClearRows,
            sensorsToCopyOn: [],
        });
    };

    showSensorCopyModal = () => {
        this.setState({
            showSensorCopyModal: true,
        });
    };

    hideSensorCopyModal = (shouldReloadDevices: boolean = false) => {
        this.setState({
            showSensorCopyModal: false,
            sensorToCopy: null,
            toggledClearRows: !this.state.toggledClearRows,
            sensorsToCopyOn: [],
        });

        if (shouldReloadDevices) {
            this.props.reloadDevices();
        }
    };

    showForceModal(e) {
        e.stopPropagation();
        this.setState({
            showForceModal: true,
        });
    }

    hideForceModal() {
        this.setState({
            showForceModal: false,
            toggledClearRows: true,
        });
    }

    shouldDisableDeleteButton = (): boolean => {
        if (this.state.sensorsChecked.length === 0) return true;

        const selectedSensors = this.props.sensors.filter(sensor => this.state.sensorsChecked.includes(sensor.id));

        return selectedSensors.some(sensor => sensor.online);
    };

    render() {

        return (
            <Loader show={this.props.isLoading ? true : false}>
                {/* SENSOR COPY MODAL */}
                <SecuredFragment authorizedRoles={[rolesConstants.TCMSensor.COPY_CONFIG]}>
                    <CopyTcmSensorModal
                        sensorToCopy={this.state.sensorToCopy}
                        sensorsToCopyOn={this.state.sensorsToCopyOn}
                        show={this.state.showSensorCopyModal}
                        onHide={this.hideSensorCopyModal}
                    />
                </SecuredFragment>

                <SecuredFragment authorizedRoles={[rolesConstants.TCMSensor.DELETE]}>
                    <DeleteSensorModal
                        show={this.state.showDeleteModal}
                        onHide={this.hideDeleteModal}
                        id={this.state.sensorsChecked}
                    />
                </SecuredFragment>

                <SecuredFragment authorizedRoles={[rolesConstants.TCMSensor.FORCE_SENSOR]}>
                    <ForceSensorModal
                        show={this.state.showForceModal}
                        onHide={this.hideForceModal}
                        sensorsId={this.state.sensorsChecked}
                        sensors={this.props.sensors}
                    />
                </SecuredFragment>

                {this.state.selectedSensor && (
                    <SecuredFragment authorizedRoles={[rolesConstants.TCMSensor.VIEW]}>
                        <SensorDrawer
                            show={this.state.sensorDrawerOpen}
                            sensor={this.state.selectedSensor}
                            close={this.closeDrawer}
                            ipCanId={this.props.ipCanId}
                            sensorsList={this.props.sensors}
                        />
                    </SecuredFragment>
                )}

                <SecuredFragment authorizedRoles={[rolesConstants.TCMSensor.VIEW_LIST]}>
                    <Panel
                        className={'panel-big element-table dashboard-card-header rs-panel-collapsible-no-carret'}
                        shaded
                        bordered
                        header={
                            <PanelHeader
                                title={this.props.intl.formatMessage({ id: 'ipCanDevices.sensors' })}
                                tagValue={this.props.sensors.length}
                                icon={faWifi}
                                iconRotation={180}
                                buttons={[
                                    <Button
                                        key="moduleIPCan-sensors-table-multiForce"
                                        color="orange"
                                        data-cy="moduleIPCan-sensors-table-multiForce"
                                        placement="left"
                                        disabled={this.state.sensorsChecked.length === 0}
                                        onClick={this.showForceModal}>
                                        <FontAwesomeIcon icon={faLock} className="margin-right-10" />
                                        <FormattedMessage id="ipCanDevices.sensors.forceMode" />
                                    </Button>,
                                    <Button
                                        key="moduleIPCan-sensors-table-multiDelete"
                                        color="red"
                                        data-cy="moduleIPCan-sensors-table-multiDelete"
                                        disabled={this.shouldDisableDeleteButton()}
                                        onClick={this.showDeleteModal}>
                                        <FontAwesomeIcon icon={faTrash} className="margin-right-10" />
                                        <FormattedMessage id="ipCanDevices.sensors.deleteMode" />
                                    </Button>,
                                ]}
                            />
                        }
                        collapsible
                        defaultExpanded={this.props.sensors.length > 0}
                        bodyFill
                        data-cy="moduleIPCan-sensors-table">
                        <ElementTable
                            columns={this.columns}
                            data={this.props.sensors}
                            progressPending={this.props.isLoading}
                            selectableRows
                            onSelectedRowsChange={this.selectedRowsChange}
                            clearSelectedRows={this.state.toggledClearRows}
                            onRowClicked={row => this.clickRow(row)}
                            conditionalRowStyles={this.conditionalRowStyles}
                            selectableRowDisabled={row => row.id === this.state.sensorToCopy?.id}
                        />
                    </Panel>
                </SecuredFragment>
            </Loader>
        );
    }
}

export default injectIntl(SensorsTable);

import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Button, ButtonGroup, FlexboxGrid, Form, FormControl, InputNumber, List, SelectPicker, Toggle } from 'rsuite';

import { authHeader } from '../../../../redux/helpers';
import { axiosService } from '../../../../redux/services';
import { ExternalApi } from '../../../../handlers/Counter/ExternalApi';
import Counter from '../../../../handlers/Counter/Counter';
import { JSCodeEditorForm } from '../../../Form/CodeEditor/JSCodeEditorForm';

type Props = {
    counter: Counter;
    externalApis: ExternalApi[];
    setViewMode: () => void;
};

type FormValue = {
    label: string;
    externalId: string;
    enabled: boolean;
    externalApiId: number | null;
    decodeFunction: string;
    timeTimeout: number;
};

export const InformationEditMode = (props: Props) => {
    const intl = useIntl();
    const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
    // const [decodageType, setDecodageType] = React.useState<object>(props.counter.decodageType);
    const [jsonIsValid, setJsonIsValid] = React.useState<boolean>(true);
    const [decodeFunctionValue, setDecodeFunctionValue] = React.useState<string>(
        props.counter.externalCounter.decodeFunction
    );

   

    const [formValue, setFormValue] = React.useState<FormValue>({
        label: props.counter.label,
        externalId: props.counter.externalCounter.externalId,
        enabled: props.counter.externalCounter.enabled,
        decodeFunction: props.counter.externalCounter.decodeFunction,
        timeTimeout: props.counter.externalCounter.timeTimeout,
        externalApiId: props.counter.externalApi !== null ? props.counter.externalApi.id : null,
    });

    // Assuming props.counter.externalApi?.decodageType is an object
    const decodageType = props.counter.externalApi?.decodageType;
    let decodageTypeString = '';

    if (decodageType) {
        // Get the properties of the object
        const properties = (decodageType as any).properties;

        // Create the declaration string
        const declaration = `{ ${Object.keys(properties)
            .map(key => {
                if (properties[key].type === 'object') {
                    const subProperties = properties[key].properties;
                    return `${key}: { ${Object.keys(subProperties)
                        .map(subKey => `${subKey}: ${subProperties[subKey].type === 'integer' ? 'number' : subProperties[subKey].type};`)
                        .join(' ')} }`;
                } else {
                    return `${key}: ${properties[key].type === 'integer' ? 'number' : properties[key].type};`;
                }
            })
            .join(', ')} }`;

        // Now declaration contains the desired result
        decodageTypeString = declaration;

    }


    const handleCancel = () => {
        setFormValue({
            label: props.counter.label,
            externalId: props.counter.externalCounter.externalId,
            enabled: props.counter.externalCounter.enabled,
            decodeFunction: props.counter.externalCounter.decodeFunction,
            timeTimeout: props.counter.externalCounter.timeTimeout,
            externalApiId: props.counter.externalApi !== null ? props.counter.externalApi.id : null,
        });

        props.setViewMode();
    };

    const externalApiSelector = props.externalApis.map(externalApi => {
        return {
            label: `${externalApi.label}`,
            value: externalApi.id,
        };
    });

    const handleValid = () => {
        setIsUpdating(true);

        //--- Update Name of counter
        axiosService
            .getAxios()
            .put<Counter>(
                '/counters/',
                {
                    id: props.counter.id,
                    label: formValue.label,
                    isView: false,
                },
                {
                    headers: authHeader(),
                }
            )
            .then(externalApiResponse => {
                props.counter.label = externalApiResponse.data.label;
                // props.counter. = externalApiResponse.data.decodageType;
                props.counter.updatedBy = externalApiResponse.data.updatedBy;
                props.counter.updatedAt = externalApiResponse.data.updatedAt;
                //--- Update externalCounter configuration
                axiosService
                    .getAxios()
                    .put<Counter>(
                        '/counters/externalConfig',
                        {
                            id: props.counter.id,
                            externalApiId: formValue.externalApiId,
                            externalId: formValue.externalId,
                            enabled: formValue.enabled,
                            timeTimeout: parseInt(formValue.timeTimeout.toString()),
                            decodeFunction: decodeFunctionValue,
                        },
                        {
                            headers: authHeader(),
                        }
                    )
                    .then(externalConfigResponse => {
                        //--- Update counter
                        props.counter.externalCounter.externalId =
                            externalConfigResponse.data.externalCounter.externalId;
                        props.counter.externalCounter.enabled = externalConfigResponse.data.externalCounter.enabled;
                        props.counter.externalCounter.decodeFunction =
                            externalConfigResponse.data.externalCounter.decodeFunction;
                        props.counter.externalCounter.timeTimeout = externalConfigResponse.data.externalCounter.timeTimeout;
                        props.counter.externalApi = externalConfigResponse.data.externalApi;
                        props.counter.updatedBy = externalConfigResponse.data.updatedBy;
                        props.counter.updatedAt = externalConfigResponse.data.updatedAt;
                        //---
                        Alert.success(intl.formatMessage({ id: 'externalCounter.update.success' }));
                        props.setViewMode();
                    })
                    .catch(err => {
                        console.error(err);
                        Alert.error(intl.formatMessage({ id: 'externalCounter.update.error' }));
                    })
                    .finally(() => {
                        setIsUpdating(false);
                    });
            })
            .catch(err => {
                console.error(err);

                Alert.error(intl.formatMessage({ id: 'externalCounter.update.error' }));

                setIsUpdating(false);
            });
    };

    return (
        <Form formValue={formValue} fluid onChange={formValue => setFormValue(formValue as FormValue)}>
            <List>
                {/* LABEL */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item className="bold" colspan={12}>
                            <FormattedMessage id="counter.information.name" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={12}>
                            <FormControl name="label" />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* EXTERNALID */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item className="bold" colspan={12}>
                            <FormattedMessage id="counter.externalConfig.externalId" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={12}>
                            <FormControl name="externalId" />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* ENABLE */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item className="bold" colspan={12}>
                            <FormattedMessage id="counter.externalConfig.enable" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={12}>
                            <FormControl
                                accepter={Toggle}
                                data-cy="edit-enabled"
                                defaultChecked={props.counter.externalCounter.enabled}
                                name="enabled"
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* TIME TIMEOUT */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item className="bold" colspan={12}>
                            <FormattedMessage id="counter.externalConfig.timeTimeout" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={12}>
                            {/* Create form for get timeTimeout in number */}
                            <FormControl name="timeTimeout" accepter={InputNumber} />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* EXTERNAL API */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item className="bold" colspan={12}>
                            <FormattedMessage id="counter.externalApi.name" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={12}>
                            <FormControl
                                accepter={SelectPicker}
                                cleanable={false}
                                data={externalApiSelector}
                                name="externalApiId"
                                key={props.counter.externalApi?.id}
                                renderMenuItem={(label, item) => {
                                    return <div data-cy={`editExternalCounter-${item.value}`}>{label}</div>;
                                }}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>

                {/* DECODE FUNCTION */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item className="bold" colspan={24}>
                            <FormattedMessage id="counter.externalConfig.decodeFunction" />
                            <br />
                            <JSCodeEditorForm
                                name="decodeFunction"
                                height={'45vh'}
                                defaultValue={props.counter.externalCounter.decodeFunction}
                                readOnly={false}
                                onChange={value => {
                                    setDecodeFunctionValue(value);
                                }}
                                inputForFunction={{
                                    cpt: props.counter,
                                    data: props.counter.externalApi?.decodageType,
                                }}
                                extraLib={`
                                declare var cpt: {
                                    id: number;
                                    label: string;
                                    externalCounter: {
                                        externalId: string;
                                        decodeFunction: string;
                                        lastReceiveData: JSON;
                                        lastTimeReceiveData: Date;
                                        externalConnectionStatus: boolean;
                                        enabled: boolean;
                                    };
                                    counterValue: {
                                        all: {
                                          free: number;
                                          occupied: number;
                                          overstayOccupied: number;
                                          overstayFree: number;
                                          forced: number;
                                          total: number;
                                        };
                                    };
                                }
                                declare var data: ${decodageTypeString};                                                                            
                            `}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* BUTTONS */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="end">
                        <FlexboxGrid.Item>
                            <ButtonGroup>
                                <Button color="red" onClick={handleCancel} disabled={isUpdating}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                                <Button
                                    color="green"
                                    onClick={handleValid}
                                    loading={isUpdating}
                                    disabled={!jsonIsValid}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                            </ButtonGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
            </List>
        </Form>
    );
};

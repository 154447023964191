import { faPlay, faStop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Col, FlexboxGrid, Input, List, Message, Modal, SelectPicker, Tag } from 'rsuite';
import { authHeader } from '../../../redux/helpers';
import { axiosService, webSocketService } from '../../../redux/services';

type TestBusModalProps = {
    ipCan: Record<string, any>;
    show: boolean;
    onHide: Function;
    bus?: string;
};
export default class TestBusModal extends React.Component<TestBusModalProps> {
    render() {
        if (this.props.ipCan) {
            return (
                <Modal backdrop="static" show={this.props.show} onHide={() => this.props.onHide()} size={'lg'}>
                    <Modal.Header>
                        <Modal.Title>
                            <FormattedMessage id="ipCan.testBus.title" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <TestBus ipCan={this.props.ipCan} />
                    </Modal.Body>
                </Modal>
            );
        }
        return null;
    }
}

type TestsBus = {
    ipCan: Record<string, any>;
};

type State = {
    data: Record<string, any>;
    running: boolean;
    loading: boolean;
    timing: string;
    errorArray: Array<Record<string, any>>;
    bus: string;
    busList: Array<Record<string, any>>;
};
class TestBus extends React.Component<TestsBus, State> {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            running: false,
            loading: false,
            timing: '100',
            errorArray: [],
            bus: '1',
            busList: [
                {
                    label: 'BUS 1',
                    value: 0,
                },
                {
                    label: 'BUS 2',
                    value: 1,
                },
            ],
        };

        this.launchTestBus = this.launchTestBus.bind(this);
        this.addTestBus = this.addTestBus.bind(this);
    }

    launchTestBus() {
        if (!this.state.running) {
            webSocketService.onEvent(`ipcanmodule:testBus/${this.state.bus}`, this.addTestBus);

            this.setState({
                data: {},
                errorArray: [],
            });
        } else {
            webSocketService.offEvent(`ipcanmodule:testBus/${this.state.bus}`, this.addTestBus);
        }

        axiosService
            .getAxios()
            .put(
                '/ipcanmodules/cmdTestBus',
                {
                    id: this.props.ipCan.id,
                    bus: parseInt(this.state.bus),
                    startStop: !this.state.running,
                    timing: parseInt(this.state.timing),
                },
                { headers: authHeader() }
            )
            .then(() => {
                this.setState({
                    running: !this.state.running,
                });
            });
    }

    componentWillUnmount() {
        if(this.state.running)
            this.launchTestBus();
        //---
        // webSocketService.offEvent(`ipcanmodule:testBus/${this.state.bus}`, this.addTestBus);
    }

    addTestBus(data) {
        let errorArray = this.state.errorArray;
        if (data.errorTimeOut) {
            errorArray.unshift({ ...data, date: moment().format('DD/MM/YYYY HH:mm:ss:SSSS') });
        }

        this.setState({
            data,
            errorArray,
        });
    }

    render() {
        return (
            <Fragment>
                {this.state.errorArray.length > 0 && (
                    <Message type="error" description="Une erreur est survenue, veuillez réessayer" />
                )}
                <FlexboxGrid justify="space-between" className="margin-top-15 margin-bottom-15">
                    <FlexboxGrid.Item>
                        <SelectPicker
                            data-cy="ipCan-testBus-selectBus"
                            searchable={false}
                            cleanable={false}
                            disabled={this.state.running}
                            style={{ width: 200 }}
                            data={this.state.busList}
                            onChange={value => this.setState({ bus: value })}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        <Input
                            data-cy="ipCan-testBus-timing"
                            stye={{ width: 200 }}
                            disabled={this.state.running}
                            value={this.state.timing}
                            onChange={value => this.setState({ timing: value })}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        {this.state.running ? (
                            <Button
                                data-cy="ipCan-testBus-stop"
                                onClick={this.launchTestBus}
                                color="red"
                                loading={this.state.loading}>
                                <FontAwesomeIcon icon={faStop} className="margin-right-10" />
                                <FormattedMessage id="ipCan.testBus.stop" />
                            </Button>
                        ) : (
                            <Button
                                data-cy="ipCan-testBus-start"
                                onClick={this.launchTestBus}
                                color="green"
                                loading={this.state.loading}>
                                <FontAwesomeIcon icon={faPlay} className="margin-right-10" />

                                <FormattedMessage id="ipCan.testBus.start" />
                            </Button>
                        )}
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <List>
                    <List.Item>
                        <FlexboxGrid align="middle" justify="space-between">
                            <FlexboxGrid.Item componentClass={Col} xs={8} className="text-center">
                                <FormattedMessage id="ipCan.testBus.id" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={8} className="text-center">
                                <FormattedMessage id="ipCan.testBus.nbFrames" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item componentClass={Col} xs={8} className="text-center">
                                <FormattedMessage id="ipCan.testBus.nbErrors" />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>
                    {this.state.data.deviceId && (
                        <List.Item>
                            <FlexboxGrid align="middle" justify="space-between">
                                <FlexboxGrid.Item componentClass={Col} xs={8} className="text-center">
                                    <Tag color="blue">{this.state.data.deviceId}</Tag>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item componentClass={Col} xs={8} className="text-center">
                                    <Tag color="cyan">{this.state.data.nbFrame}</Tag>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item componentClass={Col} xs={8} className="text-center">
                                    <Tag color="red">{this.state.data.nbError}</Tag>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>
                    )}
                </List>
                {this.state.errorArray.length > 0 && (
                    <Fragment>
                        <List className="margin-top-20">
                            <List.Item className="list-red">
                                <FlexboxGrid align="middle" justify="start">
                                    <FlexboxGrid.Item componentClass={Col} xs={12} className="text-center">
                                        <FormattedMessage id="ipCan.testBus.error.id" />
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item componentClass={Col} xs={12} className="text-center">
                                        <FormattedMessage id="ipCan.testBus.error.date" />
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </List.Item>
                            {this.state.errorArray.map((error, index) => {
                                return (
                                    <List.Item key={error.deviceId} className={index % 2 === 0 ? 'striped' : ''}>
                                        <FlexboxGrid align="middle" justify="start">
                                            <FlexboxGrid.Item componentClass={Col} xs={12} className="text-center">
                                                {error.deviceId}
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item componentClass={Col} xs={12} className="text-center">
                                                {error.date}
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                );
                            })}
                        </List>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

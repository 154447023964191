import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useIntl } from 'react-intl';
import { Button, Panel } from 'rsuite';


import { InformationEditMode } from './InformationEditMode';
import { InformationViewMode } from './InformationViewMode';
import PanelHeader from '../../../Custom/PanelHeader';
import Counter from '../../../../handlers/Counter/Counter';
import { ExternalApi } from '../../../../handlers/Counter/ExternalApi';


type Props = {
    counter: Counter;
    externalApis: ExternalApi[];
};

export const ExternalCounterInformationCard = (props: Props) => {
    const [isEditMode, setIsEditMode] = React.useState(false);

    const intl = useIntl();

    return (
        <Panel
            className="panel-small"
            shaded
            bordered
            bodyFill
            header={
                <PanelHeader
                    title={intl.formatMessage({ id: 'externalCounter.information' })}
                    buttons={[
                        <Button
                            key="externalCounter-edit"
                            color="blue"
                            size="sm"
                            disabled={isEditMode}
                            onClick={() => setIsEditMode(true)}>
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>,
                    ]}
                />
            }>
            {isEditMode ? (
                <InformationEditMode counter={props.counter} externalApis={props.externalApis} setViewMode={() => setIsEditMode(false)} />
            ) : (
                <InformationViewMode counter={props.counter} />
            )}
        </Panel>
    );
};

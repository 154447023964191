import { ExternalApi } from './ExternalApi';

export enum counterTypeEnum {
    PARKING = 'Park',
    LEVEL = 'Level',
    ZONE = 'Zone',
    CPT_VEHICLE = 'cpt vehicle',
    EXTERNAL = 'external',
}

export default class Counter {
    id: number;
    label: string;
    type: counterTypeEnum;
    nbSensors: Record<string, any>;
    nbDisplays: Record<string, any>;
    isView: boolean;
    forceValue: number;
    isShowOnDashboard: boolean;
    isShowOnProgressZone1: boolean;
    isShowOnProgressZone2: boolean;
    isShowOnProgressZone3: boolean;
    createdAt: string;
    updatedAt: string;
    createdBy: string;
    updatedBy: string;
    parking?: { id: number };
    level?: { id: number };
    externalCounter: ExternalCounter;
    externalApi: ExternalApi | null;

    counterValue: any;

    constructor(data) {
        this.id = data.id;
        this.label = data.label;
        this.type = data.type;
        this.nbSensors = data.nbSensors;
        this.nbDisplays = data.nbDisplays;
        this.isView = data.isView;
        this.forceValue = data.forceValue;
        this.isShowOnDashboard = data.isShowOnDashboard;
        this.isShowOnProgressZone1 = data.isShowOnProgressZone1;
        this.isShowOnProgressZone2 = data.isShowOnProgressZone2;
        this.isShowOnProgressZone3 = data.isShowOnProgressZone3;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
        this.createdBy = data.createdBy;
        this.updatedBy = data.updatedBy;
        this.parking = data.parking;
        this.level = data.level;
        this.externalCounter = data.externalCounter;
        this.counterValue = null;
        this.externalApi = data.externalApi ? data.externalApi : null;
    }
}

export class ExternalCounter {
    externalId: string;
    decodeFunction: string;
    lastReceiveData: JSON;
    lastTimeReceiveData: Date;
    externalConnectionStatus: boolean;
    enabled: boolean;
    timeTimeout: number;

    constructor() {
        this.externalId = '';
        this.decodeFunction = '';
        this.lastReceiveData = JSON.parse('{}');
        this.lastTimeReceiveData = new Date();
        this.externalConnectionStatus = false;
        this.enabled = false;
        this.timeTimeout = 2;
    }
}

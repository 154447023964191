import { faPlug, faTimes, faWifi, faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { Popup } from 'react-leaflet';
import { Button, FlexboxGrid, List } from 'rsuite';
import { LPSensor } from '../../../../handlers/ipCanDevices/LPSensor';
import TCMSensor from '../../../../handlers/ipCanDevices/TCMSensor';
import { IpCan } from '../../../../handlers/ipcan/IpCan';
import { MapVehicleCounter } from '../../../../handlers/map/MapVehicleCounter';
import { VehicleCounterExtension } from '../../../../handlers/vehicleCounter/VehicleCounterExtension';
import { authHeader } from '../../../../redux/helpers';
import { axiosService, webSocketService } from '../../../../redux/services';
import { rolesConstants } from '../../../../static/roles';
import SecuredFragment from '../../../Auth/SecuredFragment';
import EditPassCountModal from '../../Modal/PassCount/EditPassCountModal';
import ForcePassCountModal from '../../Modal/PassCount/ForcePassCountModal';
import PassCountExtensionsModal from '../../Modal/PassCount/PassCountExtensionsModal';
import { PassCountStatsModal } from '../../Modal/PassCount/PassCountStatsModal';
import PassCountEditFilterModal from './PassCountEditFilterModal';

type Props = {
    passCount: MapVehicleCounter;
    hover: boolean;
    editMode: boolean;
    editMap: boolean;
    reloadPassCount: Function;
} & WrappedComponentProps;

type State = {
    editModalOpen: boolean;
    extensionModalOpen: boolean;
    forceModalOpen: boolean;
    ipcans: IpCan[];
    isLoading: boolean;
    passCountExtensionsIN: VehicleCounterExtension[];
    passCountExtensionsOUT: VehicleCounterExtension[];
    lpSensors: LPSensor[];
    tcmSensors: TCMSensor[];
    hasError: boolean;
    error?: any;
    isAutoCalibrationModalOpen: boolean;
    lpSensorAutoCalibrationId?: number;
    statsModalOpen: boolean;
};

class PassCountPopup extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            editModalOpen: false,
            extensionModalOpen: false,
            forceModalOpen: false,
            ipcans: [],
            isLoading: true,
            passCountExtensionsIN: [],
            passCountExtensionsOUT: [],
            lpSensors: [],
            tcmSensors: [],
            hasError: false,
            error: undefined,
            isAutoCalibrationModalOpen: false,
            lpSensorAutoCalibrationId: undefined,
            statsModalOpen: false,
        };
    }

    componentDidMount = () => {
        axiosService
            .getAxios()
            .get('/ipcanmodules', { headers: authHeader() })
            .then(ipcanResponse => {
                const ipcans = ipcanResponse.data.map(ipcan => new IpCan(ipcan));

                this.setState({
                    ipcans,
                });

                this.fetchExtensions();
            });
        webSocketService.onEvent('lp-sensor:updateStatus', this.updateLPSensor);
        webSocketService.onEvent('tcm-sensor:updateStatus', this.updateTCMSensor);
    };

    componentWillUnmount = () => {
        webSocketService.offEvent('lp-sensor:updateStatus', this.updateLPSensor);
        webSocketService.offEvent('tcm-sensor:updateStatus', this.updateTCMSensor);
    };

    updateTCMSensor = data => {
        const tcmSensorToUpdate = this.state.tcmSensors.find(tcmSensor => tcmSensor.getId() === data.id);

        if (tcmSensorToUpdate) {
            tcmSensorToUpdate.webSocketUpdate(data);
        }
    };

    updateLPSensor = data => {
        const lpDisplayToUpdate = this.state.lpSensors.find(lpSensor => lpSensor.getId() === data.id);

        if (lpDisplayToUpdate) {
            lpDisplayToUpdate.setWebsocketInformation(data);
        }
    };

    fetchExtensions = () => {
        axiosService
            .getAxios()
            .get(`/devices/vehicle-counters/${this.props.passCount.getVehicleCounter().getId()}`, {
                headers: authHeader(),
            })
            .then(passCountResponse => {
                const passCountExtensions: VehicleCounterExtension[] = passCountResponse.data.extensions.map(
                    extension => new VehicleCounterExtension(extension)
                );

                const lpSensors: LPSensor[] = [];
                const tcmSensors: TCMSensor[] = [];

                passCountExtensions.forEach(passCountExtension => {
                    if (
                        passCountExtension.getType() === 'LP_SENSOR_1' ||
                        passCountExtension.getType() === 'LP_SENSOR_2'
                    ) {
                        let lpSensor1: LPSensor | null = passCountExtension.getLPSensor1();
                        lpSensor1 && this.addSensorToArray(lpSensor1);
                        let lpSensor2: LPSensor | null = passCountExtension.getLPSensor2();
                        lpSensor2 && this.addSensorToArray(lpSensor2);
                    }

                    if (
                        passCountExtension.getType() === 'TCM_SENSOR_1' ||
                        passCountExtension.getType() === 'TCM_SENSOR_2'
                    ) {
                        let tcmSensor1: TCMSensor | null = passCountExtension.getTCMSensor1();
                        tcmSensor1 && this.addTCMSensorToArray(tcmSensor1);
                        let tcmSensor2: TCMSensor | null = passCountExtension.getTCMSensor2();
                        tcmSensor2 && this.addTCMSensorToArray(tcmSensor2);
                    }
                });

                this.setState({
                    passCountExtensionsIN: passCountExtensions.filter(passCount => passCount.getSensType() === 'IN'),
                    passCountExtensionsOUT: passCountExtensions.filter(passCount => passCount.getSensType() === 'OUT'),
                    lpSensors,
                    tcmSensors,
                });
            })
            .catch(err => {
                this.setState({
                    hasError: true,
                    error: err,
                });
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    addSensorToArray = (lpSensor: LPSensor) => {
        axiosService
            .getAxios()
            .get(`/devices/lp-sensor/${lpSensor.getId()}`, { headers: authHeader() })
            .then(lpSensorResponse => {
                const lpSensor = new LPSensor(lpSensorResponse.data, lpSensorResponse.data.ipcanmodule.id, null, null);
                let lpSensorsArray: LPSensor[] = [...this.state.lpSensors];

                lpSensorsArray.push(lpSensor);

                this.setState({
                    lpSensors: lpSensorsArray,
                });
            });
    };

    addTCMSensorToArray = (sensor: TCMSensor) => {
        axiosService
            .getAxios()
            .get(`/devices/tcm-sensor/${sensor.getId()}`, { headers: authHeader() })
            .then(tcmSensorResponse => {
                const tcmSensor = new TCMSensor(
                    tcmSensorResponse.data,
                    tcmSensorResponse.data.ipcanmodule.id,
                    null,
                    null
                );
                let tcmSensorsArray: TCMSensor[] = [...this.state.tcmSensors];

                tcmSensorsArray.push(tcmSensor);

                this.setState({
                    tcmSensors: tcmSensorsArray,
                });
            });
    };

    toggleEditModal = () => {
        this.state.editModalOpen && this.props.reloadPassCount();

        this.setState({
            editModalOpen: !this.state.editModalOpen,
        });
    };

    toggleExtensionModal = () => {
        this.setState({
            extensionModalOpen: !this.state.extensionModalOpen,
        });
    };

    toggleForceModal = () => {
        this.setState({
            forceModalOpen: !this.state.forceModalOpen,
        });
    };

    toggleStatsModal = () => {
        this.setState({
            statsModalOpen: !this.state.statsModalOpen,
        });
    };

    openAutoCalibrationModal = (lpSensorId: number) => {
        this.setState({
            isAutoCalibrationModalOpen: true,
            lpSensorAutoCalibrationId: lpSensorId,
        });
    };

    closeAutoCalibrationModal = () => {
        this.setState({
            isAutoCalibrationModalOpen: false,
            lpSensorAutoCalibrationId: undefined,
        });
    };

    render() {
        const lpSensorAutoCalibration = this.state.lpSensors.find(
            lpSensor => lpSensor.getId() === this.state.lpSensorAutoCalibrationId
        );

        return (
            <Popup autopan autoClose={false}>
                <h5>{this.props.passCount.getLabel()}</h5>

                <SecuredFragment authorizedRoles={[]}>
                    <p>
                        <FormattedMessage id="map.passCount.creation" /> : {this.props.passCount.getCreatedAt()} -{' '}
                        {this.props.passCount.getCreatedBy()}
                    </p>
                </SecuredFragment>

                <List bordered size="sm" hover>
                    {/* IN */}
                    <List.Item>
                        <FlexboxGrid align="middle" justify="space-between">
                            <FlexboxGrid.Item>
                                <FormattedMessage id="map.passCount.valueIn" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="bold">
                                {this.props.editMap ? (
                                    <FontAwesomeIcon icon={faTimes} />
                                ) : (
                                    this.props.passCount.getVehicleCounter().getValueIn().getValue()
                                )}
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>
                    {/* OUT */}
                    <List.Item>
                        <FlexboxGrid align="middle" justify="space-between">
                            <FlexboxGrid.Item>
                                <FormattedMessage id="map.passCount.valueOut" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="bold">
                                {this.props.editMap ? (
                                    <FontAwesomeIcon icon={faTimes} />
                                ) : (
                                    this.props.passCount.getVehicleCounter().getValueOut().getValue()
                                )}
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>
                    {/* FREE */}
                    <List.Item>
                        <FlexboxGrid align="middle" justify="space-between">
                            <FlexboxGrid.Item>
                                <FormattedMessage id="map.passCount.freeValue" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="bold">
                                {this.props.editMap ? (
                                    <FontAwesomeIcon icon={faTimes} />
                                ) : (
                                    this.props.passCount.getVehicleCounter().getValue().getValue()
                                )}
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>
                    {/* OUT */}
                    <List.Item>
                        <FlexboxGrid align="middle" justify="space-between">
                            <FlexboxGrid.Item>
                                <FormattedMessage id="map.passCount.occupiedValue" />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="bold">
                                {this.props.editMap ? (
                                    <FontAwesomeIcon icon={faTimes} />
                                ) : (
                                    this.props.passCount.getVehicleCounter().getNbTotPlace() -
                                    this.props.passCount.getVehicleCounter().getValue().getValue()
                                )}
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </List.Item>
                </List>

                {this.state.lpSensors.length > 0 && (
                    <Fragment>
                        <hr />
                        <h6 className="margin-top-5 margin-bottom-10">
                            <FormattedMessage id="map.passCount.lpSensorConfiguration" />
                        </h6>

                        <List bordered hover size="sm">
                            {this.state.lpSensors.map(lpSensor => {
                                const ipCan = this.state.ipcans.find(ipcan => ipcan.getId() === lpSensor.ipCanId);

                                return (
                                    <List.Item key={`passCounter-lpSensor-${lpSensor.getId()}`}>
                                        <FlexboxGrid justify="space-between" align="middle">
                                            <FlexboxGrid.Item>
                                                {ipCan?.getLabel()} - {lpSensor.bus + 1} - {lpSensor.deviceId}
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item>
                                                <FontAwesomeIcon
                                                    icon={faWifi}
                                                    rotation={180}
                                                    color={lpSensor.detectionState === 1 ? 'green' : 'red'}
                                                />
                                                <FontAwesomeIcon
                                                    className="margin-left-5"
                                                    icon={faPlug}
                                                    color={lpSensor.getOnline() ? 'green' : 'red'}
                                                />
                                            </FlexboxGrid.Item>
                                            {!this.props.hover && (
                                                <FlexboxGrid.Item>
                                                    <Button
                                                        size="sm"
                                                        color="orange"
                                                        onClick={() => this.openAutoCalibrationModal(lpSensor.getId())}>
                                                        <FontAwesomeIcon icon={faWrench} />
                                                    </Button>
                                                </FlexboxGrid.Item>
                                            )}
                                        </FlexboxGrid>
                                    </List.Item>
                                );
                            })}
                        </List>

                        {lpSensorAutoCalibration && (
                            <PassCountEditFilterModal
                                show={this.state.isAutoCalibrationModalOpen}
                                onHide={this.closeAutoCalibrationModal}
                                lpSensor={lpSensorAutoCalibration}
                            />
                        )}
                    </Fragment>
                )}

                {this.state.tcmSensors.length > 0 && (
                    <Fragment>
                        <hr />
                        <h6 className="margin-top-5 margin-bottom-10">
                            <FormattedMessage id="map.passCount.tcmSensorConfiguration" />
                        </h6>

                        <List bordered hover size="sm">
                            {this.state.tcmSensors.map(tcmSensor => {
                                const ipCan = this.state.ipcans.find(ipcan => ipcan.getId() === tcmSensor.ipCanId);

                                return (
                                    <List.Item key={`passCounter-lpSensor-${tcmSensor.getId()}`}>
                                        <FlexboxGrid justify="space-between" align="middle">
                                            <FlexboxGrid.Item>
                                                {ipCan?.getLabel()} - {tcmSensor.bus + 1} - {tcmSensor.deviceId}
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item>
                                                <FontAwesomeIcon
                                                    icon={faWifi}
                                                    rotation={180}
                                                    color={tcmSensor.detectionState === 1 ? 'green' : 'red'}
                                                />
                                                <FontAwesomeIcon
                                                    className="margin-left-5"
                                                    icon={faPlug}
                                                    color={tcmSensor.getOnline() ? 'green' : 'red'}
                                                />
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                );
                            })}
                        </List>
                        {/* 
                        {lpSensorAutoCalibration && (
                            <PassCountEditFilterModal
                                show={this.state.isAutoCalibrationModalOpen}
                                onHide={this.closeAutoCalibrationModal}
                                lpSensor={lpSensorAutoCalibration}
                            />
                        )} */}
                    </Fragment>
                )}

                {!this.props.hover && (
                    <Fragment>
                        <hr />
                        <SecuredFragment authorizedRoles={[rolesConstants.mapVehicleCounters.VIEW]}>
                            <Button color="blue" data-cy="map-passCount-force" block onClick={this.toggleForceModal}>
                                <FormattedMessage id="map.passCount.forcePassCount" />
                            </Button>

                            <ForcePassCountModal
                                show={this.state.forceModalOpen}
                                passCount={this.props.passCount}
                                onHide={this.toggleForceModal}
                            />
                        </SecuredFragment>

                        {/* VEHICLE COUNTER STATS */}
                        <SecuredFragment authorizedRoles={[]}>
                            <Button color="blue" data-cy="map-passCount-stats" block onClick={this.toggleStatsModal}>
                                <FormattedMessage id="map.passCount.passCountStats" />
                            </Button>

                            <PassCountStatsModal
                                show={this.state.statsModalOpen}
                                passCount={this.props.passCount}
                                onHide={this.toggleStatsModal}
                            />
                        </SecuredFragment>
                        {this.props.editMode && (
                            <Fragment>
                                <Fragment>
                                    <Button
                                        color="orange"
                                        data-cy="map-passCount-updatePassCount"
                                        block
                                        onClick={this.toggleEditModal}>
                                        <FormattedMessage id="map.passCount.updatePassCount" />
                                    </Button>

                                    <EditPassCountModal
                                        show={this.state.editModalOpen}
                                        passCount={this.props.passCount}
                                        onHide={this.toggleEditModal}
                                    />
                                </Fragment>

                                <Fragment>
                                    <Button
                                        color="orange"
                                        data-cy="map-passCount-editExtensions"
                                        block
                                        onClick={this.toggleExtensionModal}>
                                        <FormattedMessage id="map.passCount.editExtensions" />
                                    </Button>

                                    <PassCountExtensionsModal
                                        show={this.state.extensionModalOpen}
                                        passCount={this.props.passCount}
                                        onHide={this.toggleExtensionModal}
                                        ipcans={this.state.ipcans}
                                        passCountExtensionsIN={this.state.passCountExtensionsIN}
                                        passCountExtensionsOUT={this.state.passCountExtensionsOUT}
                                        fetchExtensions={this.fetchExtensions}
                                        isLoading={this.state.isLoading}
                                    />
                                </Fragment>
                            </Fragment>
                        )}
                    </Fragment>
                )}
            </Popup>
        );
    }
}

export default injectIntl(PassCountPopup);

import { Switch, useLocation } from 'react-router-dom';
import { Content } from 'rsuite';
import { CameraPlacesPage } from '../CameraPlaces';
import { CamerasPage } from '../Cameras';
import Configuration from '../Configuration/Configuration';
import CountersPage from '../CountersPage/CountersPage';
import { ProtectedRoute } from '../Custom/ProtectedRoute';
import Event from '../Event';
import History from '../History';
import homepage from '../Homepage/homepage';
import IpCanElementsPage from '../IpCanElementsPage';
import IpCanPage from '../IpCanPage';
import { ListCounters } from '../ListCounters';
import ParkingMap from '../Map';
import NoMatch from '../NoMatch';
import Parkings from '../Parkings';
import { PixelArtFontEditor } from '../PixelArtFontEditor';
import { PixelArtPictoEditor } from '../PixelArtPictoEditor';
import PlaceType from '../PlaceType';
import UsersPage from '../UsersPage';

interface Props {
    expanded: boolean;
    auth: any;
}

export const AppRouter = (props: Props) => {
    const router = useLocation();

    const isMapPage = router.pathname.includes('map');

    const shouldDisplayCountersPart = props.auth?.user.roles.find(data => {
        return (data.name === 'ViewDashboard' && data.section === 'Counters') || data.name === 'Admin';
    });

    return <Content
        style={{
            marginLeft: props.expanded ? isMapPage ? 260 : 270 : isMapPage ? 56 : 66,
            marginTop: isMapPage ? 0 : 4,
            marginRight: isMapPage ? 0 : 10,
            marginBottom: isMapPage ? 0 : 10,
        }}>
        <Switch>
            <ProtectedRoute
                isAuthenticated={props.auth.loggedIn}
                path="/configuration"
                component={Configuration}
            />
            <ProtectedRoute
                isAuthenticated={props.auth.loggedIn}
                path="/parkings"
                component={Parkings}
            />
            <ProtectedRoute
                isAuthenticated={props.auth.loggedIn}
                path="/users"
                component={UsersPage}
            />
            <ProtectedRoute
                isAuthenticated={props.auth.loggedIn}
                exact={true}
                path="/ipCan/:ipCanId/bus/:busNumber"
                component={IpCanElementsPage}
            />
            <ProtectedRoute
                isAuthenticated={props.auth.loggedIn}
                exact={true}
                path="/ipCan/:ipCanId/bus/:busNumber/matrix-display/:displayId/font/:size"
                component={PixelArtFontEditor}
            />
            <ProtectedRoute
                isAuthenticated={props.auth.loggedIn}
                exact={true}
                path="/ipCan/:ipCanId/bus/:busNumber/matrix-display/:displayId/picto/:type"
                component={PixelArtPictoEditor}
            />
            <ProtectedRoute
                isAuthenticated={props.auth.loggedIn}
                path="/ipCan"
                component={IpCanPage}
            />
            <ProtectedRoute
                isAuthenticated={props.auth.loggedIn}
                path="/events"
                component={Event}
            />
            <ProtectedRoute
                isAuthenticated={props.auth.loggedIn}
                path="/places"
                component={PlaceType}
            />
            {/* {shouldDisplayCountersPart && ( */}
            <ProtectedRoute
                isAuthenticated={props.auth.loggedIn}
                path="/counters"
                component={CountersPage}
            />
            {/* )} */}
            <ProtectedRoute
                isAuthenticated={props.auth.loggedIn}
                path="/listcounters"
                component={ListCounters}
            />
            <ProtectedRoute
                isAuthenticated={props.auth.loggedIn}
                path="/history"
                component={History}
            />
            <ProtectedRoute
                isAuthenticated={props.auth.loggedIn}
                path="/cameras/:cameraId"
                component={CameraPlacesPage}
            />
            <ProtectedRoute
                isAuthenticated={props.auth.loggedIn}
                path="/cameras"
                component={CamerasPage}
            />
            <ProtectedRoute
                isAuthenticated={props.auth.loggedIn}
                path="/"
                exact={true}
                component={shouldDisplayCountersPart ? homepage : CountersPage}
            />
            <ProtectedRoute
                isAuthenticated={props.auth.loggedIn}
                path="/map/:levelId"
                component={ParkingMap}
                exact
            />

            <ProtectedRoute
                isAuthenticated={props.auth.loggedIn}
                component={NoMatch}
            />
        </Switch>
    </Content>
}
import Editor, { loader, useMonaco } from '@monaco-editor/react';

import { tryFunction, tryFunctionCpt } from '../../../static/helper';
import { FlexboxGrid, List, Tag } from 'rsuite';
import { useEffect, useState } from 'react';
import { monacoBlackboard } from '../../../static/monaco-blackboard';
import { clone } from '../../../utils/clone';
import Counter from '../../../handlers/Counter/Counter';

type Props = {
    name: string | (string | number)[];
    onChange?: (value: string) => void;
    defaultValue?: string;
    label?: string;
    height?: string | number;
    readOnly?: boolean;
    helper?: string;
    extraLib?: string;
    inputForFunction?: any;
    value?: string;
};

export const JSCodeEditorForm = (props: Props) => {
    const [isValidFunction, setIsValidFunction] = useState(true);
    const [textError, setTextError] = useState('');

    let defaultValue = '{}';
    try {
        if (props.defaultValue) defaultValue = props.defaultValue;
    } catch (e) {
        console.log(e);
    }

    const checkFunction = (value: string) => {
        if(props.readOnly)
            return;
        //---
        if (props.inputForFunction) {
            //---
            tryFunctionCpt(value, props.inputForFunction['cpt'], props.inputForFunction['data'])
                .then(res => {
                    setIsValidFunction(true);
                })
                .catch(err => {
                    setTextError(err.message);
                    setIsValidFunction(false);
                });
        } else {
            tryFunction(value)
                .then(res => {
                    setIsValidFunction(true);
                })
                .catch(err => {
                    setTextError(err.message);
                    setIsValidFunction(false);
                });
        }
    };

    const handleEditorChange = async (value, event) => {
        const valueToSave = value;
        //--- Send the value to the parent component
        if (props.onChange) props.onChange(valueToSave);
        //---
        checkFunction(valueToSave);
    };

    useEffect(() => {
        loader.init().then(monaco => {
            monaco.editor.defineTheme('Blackboard', monacoBlackboard);
            monaco.editor.setTheme('Blackboard');
            //---


            // Enable TypeScript validation
            monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
                noSemanticValidation: false,
                noSyntaxValidation: false,
            });

            if (props.extraLib) {
                // Define the cpt object
                monaco.languages.typescript.javascriptDefaults.addExtraLib(props.extraLib, 'filename/filename.d.ts');
            }

            //---
            checkFunction(defaultValue);
        });
    }, []);

    return (
        <>
            {!isValidFunction && (
                <>
                    <Tag color="red">Invalid function</Tag>
                    <Tag color="orange">{textError}</Tag>
                </>
            )}
            <Editor
                defaultLanguage="javascript"
                defaultValue={defaultValue}
                value={props.value}
                height={props.height || '30vh'}
                options={{
                    readOnly: props.readOnly,
                    minimap: { enabled: false },
                    cursorStyle: 'line',
                    formatOnPaste: true,
                    formatOnType: true,
                    wordWrap: 'on',
                    autoIndent: 'full',
                }}
                onChange={handleEditorChange}
                theme="Blackboard"
                wrapperProps={{
                    'data-testid': 'monaco-editor-wrapper',
                    'aria-readonly': props.readOnly || false,
                }}
            />
        </>
    );
};

import { faDesktop, faServer, faWifi } from '@fortawesome/free-solid-svg-icons';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Col, Container, FlexboxGrid } from 'rsuite';
import { Parking } from '../../handlers/parking/Parking';
import { authHeader } from '../../redux/helpers';
import { axiosService, webSocketService } from '../../redux/services';
import { rolesConstants } from '../../static/roles';
import SecuredFragment from '../Auth/SecuredFragment';
import { CounterStatus } from '../CountersPage/ParkingPanel';
import CounterCard from './CounterCard';
import ElementCard from './ElementCard';
import EngineCard from './EngineCard';
import { ParkingCard } from './ParkingCard/ParkingCard';
import ParkingStats from './ParkingStats';
import PlaceTypeCard from './PlaceTypeCard';
import UsersCard from './UsersCards';

type Props = {
    auth: Record<string, any>;
};

type State = {
    parkings: Parking[];
    loading: boolean;
    counterStatus: CounterStatus[];
};

class Homepage extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            parkings: [],
            loading: true,
            counterStatus: [],
        };
    }

    componentDidMount() {
        webSocketService.joinRoom('counter');

        axiosService
            .getAxios()
            .get('/parkings', { headers: authHeader() })
            .then(response => {
                const currentParkings: Parking[] = [];
                const parkingsResponse = response.data;

                for (let p in parkingsResponse) {
                    if (
                        this.props.auth.user.roles.some(role => role.id === 1) ||
                        !this.props.auth.user.parkingList.includes(parkingsResponse[p].id)
                    ) {
                        currentParkings.push(new Parking(parkingsResponse[p]));
                    }
                }

                this.setState({
                    loading: false,
                    parkings: currentParkings,
                });
            })
            .catch(err => {
                console.error(err);
            });

        axiosService
            .getAxios()
            .get('/counters/status', { headers: authHeader() })
            .then(response => {
                this.setState({
                    counterStatus: response.data,
                });
            });

        webSocketService.onEvent('counter:update', this.updateCountersValue);
    }

    componentWillUnmount = () => {
        webSocketService.offEvent('counter:update', this.updateCountersValue);
    };

    updateCountersValue = (data: CounterStatus) => {
        const counterStatus = this.state.counterStatus.map(counter => {
            if (counter.id === data.id) {
                return { ...counter, counterValue: data.counterValue };
            }
            return counter;
        });

        this.setState({ counterStatus });
    };

    render() {
        return (
            <Container>
                <FlexboxGrid justify="start">
                    {/* IPCAN Controller */}
                    <SecuredFragment authorizedRoles={[rolesConstants.controller.STATES]}>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            colspan={24}
                            xl={window.devicePixelRatio <= 1.2 ? 6 : 12}
                            lg={window.devicePixelRatio <= 1.2 ? 6 : 12}
                            sm={12}
                            xs={24}>
                            <ElementCard element="ipcan" icon={faServer} />
                        </FlexboxGrid.Item>
                    </SecuredFragment>
                    {/* Sensors */}
                    <SecuredFragment authorizedRoles={[rolesConstants.devices.SENSORS_STATE]}>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            colspan={24}
                            xl={window.devicePixelRatio <= 1.2 ? 6 : 12}
                            lg={window.devicePixelRatio <= 1.2 ? 6 : 12}
                            sm={12}
                            xs={24}>
                            <ElementCard element="sensors" icon={faWifi} iconRotation={180} />
                        </FlexboxGrid.Item>
                    </SecuredFragment>
                    {/* DISPLAYS */}
                    <SecuredFragment authorizedRoles={[rolesConstants.devices.DISPLAYS_STATE]}>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            colspan={24}
                            xl={window.devicePixelRatio <= 1.2 ? 6 : 12}
                            lg={window.devicePixelRatio <= 1.2 ? 6 : 12}
                            sm={12}
                            xs={24}>
                            <ElementCard element="displays" icon={faDesktop} />
                        </FlexboxGrid.Item>
                    </SecuredFragment>

                    {/* ENGINE */}
                    <SecuredFragment authorizedRoles={[rolesConstants.configuration.GET_ENGINE]}>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            colspan={24}
                            xl={window.devicePixelRatio <= 1.2 ? 6 : 12}
                            lg={window.devicePixelRatio <= 1.2 ? 6 : 12}
                            sm={12}
                            xs={24}>
                            <EngineCard />
                        </FlexboxGrid.Item>
                    </SecuredFragment>
                </FlexboxGrid>
                <SecuredFragment authorizedRoles={[rolesConstants.parking.VIEW_LIST]}>
                    <FlexboxGrid justify="start">
                        {this.state.parkings.map(parking => {
                            return (
                                <Fragment key={`parking-${parking.getId()}`}>
                                    <FlexboxGrid.Item componentClass={Col} colspan={24} lg={12} md={12} sm={24} xs={24}>
                                        <ParkingStats parking={parking} loading={this.state.loading} />
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item componentClass={Col} colspan={24} lg={12} md={12} sm={24} xs={24}>
                                        <ParkingCard
                                            parking={parking}
                                            loading={this.state.loading}
                                            counterStatus={this.state.counterStatus}
                                        />
                                    </FlexboxGrid.Item>
                                </Fragment>
                            );
                        })}
                    </FlexboxGrid>
                </SecuredFragment>
                <FlexboxGrid justify="start">
                    <SecuredFragment authorizedRoles={[rolesConstants.placeType.VIEW_LIST]}>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            colspan={24}
                            xl={window.devicePixelRatio <= 1.2 ? 8 : 12}
                            lg={window.devicePixelRatio <= 1.2 ? 8 : 12}
                            sm={12}
                            xs={24}>
                            <PlaceTypeCard />
                        </FlexboxGrid.Item>
                    </SecuredFragment>
                    <SecuredFragment authorizedRoles={[rolesConstants.counters.STATES]}>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            colspan={24}
                            xl={window.devicePixelRatio <= 1.2 ? 8 : 12}
                            lg={window.devicePixelRatio <= 1.2 ? 8 : 12}
                            sm={12}
                            xs={24}>
                            <CounterCard />
                        </FlexboxGrid.Item>
                    </SecuredFragment>
                    <SecuredFragment authorizedRoles={[rolesConstants.user.GET_STATE]}>
                        <FlexboxGrid.Item
                            componentClass={Col}
                            colspan={24}
                            xl={window.devicePixelRatio <= 1.2 ? 8 : 12}
                            lg={window.devicePixelRatio <= 1.2 ? 8 : 12}
                            sm={12}
                            xs={24}>
                            <UsersCard />
                        </FlexboxGrid.Item>
                    </SecuredFragment>
                </FlexboxGrid>
            </Container>
        );
    }
}

function mapStateToProps(state) {
    const { auth } = state;
    return { auth };
}

export default connect(mapStateToProps)(Homepage);

import { faPlug } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FlexboxGrid, List } from 'rsuite';
import { ExternalApi } from '../../../../handlers/Counter/ExternalApi';
import JSONInput from 'react-json-editor-ajrm';

type Props = {
    externalApi: ExternalApi;
};

export const InformationViewMode = (props: Props) => {
    return (
        <List>
            {/* LABEL */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="externalApi.information.name" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>{props.externalApi.label}</FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
            {/* DECODAGE TYPE */}
            <List.Item className="panel-list">
                <FlexboxGrid align="middle" justify="space-between">
                    <FlexboxGrid.Item className="bold">
                        <FormattedMessage id="externalApi.information.decodageType" />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                        {/* ExternalApi */}
                        <JSONInput
                            id="decodeType_json"
                            placeholder={props.externalApi.decodageType}
                            // height="450px"
                            // width="100%"
                        />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
        </List>
    );
};

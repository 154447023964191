import axios from 'axios';
let url = '';
// let isUserAuthenticated = false;
/**
 * @TODO: Change URL for each process environment
 */
if (process.env.REACT_APP_CI === 'true') {
    url = `http://${process.env.REACT_APP_API_SERVICE}:3000`;
} else if (process.env.REACT_APP_EMBEDDED === 'true') {
    url = `http://${window.location.hostname}`;
} else if (process.env.REACT_APP_ENV === 'production') {
    url = 'https://mle-supervision-api-v3.ie-concept.com';
} else if (process.env.REACT_APP_ENV === 'develop') {
    url = 'https://beta.mle-supervision-v3-api.ie-concept.com';
} else if (process.env.NODE_ENV === 'production') {
    url = `http://${window.location.hostname}:3000`;
} else if (process.env.REACT_APP_DEBUG === 'true') {
    url = 'http://172.16.9.10:3000';
} else if (process.env.REACT_APP_DEVELOP === 'true') {
    url = 'http://localhost:3000';
} else {
    url = `http://${window.location.hostname}`;
    // url = 'http://172.16.9.10:3000';
}

// url = 'http://localhost:3000';

// INIT AXIOS
let axiosInstance = axios.create({
    baseURL: url,
    responseType: 'json',
    headers: {
        Authorization: '',
    },
});

export const axiosService = {
    getAxios,
    // setBearer,
    // resetBearer,
    // getIsUserAuthenticated,
};

function getAxios() {
    return axiosInstance;
}

// function setBearer(token, user, isUserRemembered) {
//     isUserAuthenticated = true;
//     axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
//     axiosInstance.defaults.headers['Authorization'] = 'Bearer ' + token;

//     if (isUserRemembered) {
//         localStorage.setItem('auth_key', token);
//         localStorage.setItem('user', JSON.stringify(user));
//     }

//     sessionStorage.setItem('user', JSON.stringify(user));
//     sessionStorage.setItem('auth_key', token);

//     return Promise.resolve();
// }

// function resetBearer() {
//     isUserAuthenticated = false;
//     localStorage.removeItem('auth_key');
//     localStorage.removeItem('user');
//     sessionStorage.removeItem('user');
//     sessionStorage.removeItem('auth_key');
//     axiosInstance.defaults.headers.common['Authorization'] = '';
//     axiosInstance.defaults.headers['Authorization'] = '';
// }

// function getIsUserAuthenticated() {
//     return sessionStorage.getItem('user') !== null;
// }

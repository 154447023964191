import { FormattedMessage } from 'react-intl';
import { FlexboxGrid, List, Tag } from 'rsuite';
import JSONInput from 'react-json-editor-ajrm';
import Counter from '../../../../handlers/Counter/Counter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faPlug } from '@fortawesome/free-solid-svg-icons';
import Editor, { useMonaco } from '@monaco-editor/react';
import { useEffect } from 'react';
import { JSCodeEditorForm } from '../../../Form/CodeEditor/JSCodeEditorForm';

type Props = {
    counter: Counter;
};

export const InformationViewMode = (props: Props) => {
    // Assuming props.counter.externalApi?.decodageType is an object
    const decodageType = props.counter.externalApi?.decodageType;
    let decodageTypeString = '';

    if (decodageType) {
        // Get the properties of the object
        const properties = (decodageType as any).properties;

        // Create the declaration string
        const declaration = `{ ${Object.keys(properties)
            .map(key => `${key}: ${properties[key].type === 'integer' ? 'number' : properties[key].type};`)
            .join(' ')} }`;

        // Now declaration contains the desired result
        decodageTypeString = declaration;
    }

    return (
        <>
            <List>
                {/* LABEL */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="counter.information.name" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>{props.counter.label}</FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* ONLINE */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="counter.information.online" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>
                            <FontAwesomeIcon
                                icon={faPlug}
                                color={props.counter.externalCounter.externalConnectionStatus ? 'green' : 'red'}
                                size="lg"
                                className="margin-right-5"
                            />
                            ({props.counter.externalCounter.lastTimeReceiveData})
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* ENABLE */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="counter.information.enable" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>
                            <FontAwesomeIcon
                                icon={faCheckCircle}
                                color={props.counter.externalCounter.enabled ? 'green' : 'red'}
                                size="lg"
                                className="margin-right-5"
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* EXTERNALID */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="counter.information.externalId" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>{props.counter.externalCounter.externalId}</FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* EXTERNAL API */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="counter.information.externalApi" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>
                            <Tag color="blue">{props.counter.externalApi ? props.counter.externalApi.label : ''}</Tag>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* TIME TIMEOUT */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item className="bold">
                            <FormattedMessage id="counter.information.timeTimeout" />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>
                            <Tag color="orange">{props.counter.externalCounter.timeTimeout}</Tag>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* DECODE FUNCTION */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item className="bold" colspan={24}>
                            <FormattedMessage id="counter.information.decodeFunction" />
                            <br />
                            <JSCodeEditorForm
                                name="decodeFunction"
                                height={'45vh'}
                                defaultValue={props.counter.externalCounter.decodeFunction}
                                readOnly={true}
                                inputForFunction={{
                                    cpt: props.counter,
                                    data: props.counter.externalApi?.decodageType,
                                }}
                                extraLib={`
                                declare var cpt: {
                                    id: number;
                                    label: string;
                                    externalCounter: {
                                        externalId: string;
                                        decodeFunction: string;
                                        lastReceiveData: JSON;
                                        lastTimeReceiveData: Date;
                                        externalConnectionStatus: boolean;
                                        enabled: boolean;
                                    };
                                    counterValue: {
                                        all: {
                                          free: number;
                                          occupied: number;
                                          overstayOccupied: number;
                                          overstayFree: number;
                                          forced: number;
                                          total: number;
                                        };
                                    };
                                }
                                declare var data: ${decodageTypeString};                                                                            
                            `}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
                {/* DECODE FUNCTION */}
                <List.Item className="panel-list">
                    <FlexboxGrid align="middle" justify="space-between">
                        <FlexboxGrid.Item className="bold" colspan={24}>
                            <FormattedMessage id="counter.information.lastReceive" />
                            <br />
                            <JSCodeEditorForm
                                name="lastReceiveData"
                                height={'20vh'}
                                value={JSON.stringify(props.counter.externalCounter.lastReceiveData, null, 2)}
                                readOnly={true}
                            />
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
            </List>
        </>
    );
};

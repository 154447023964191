import { faChartBar, faCheck, faEdit, faExclamationCircle, faEyeSlash, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Button, ButtonGroup, Col, DatePicker, FlexboxGrid, Form, FormControl, InputNumber, List, Panel, SelectPicker, Toggle, Tooltip, Whisper } from 'rsuite';
import { secondsToHours } from '../../../../handlers/helper';
import { LPSensor } from '../../../../handlers/ipCanDevices/LPSensor';
import { LPSensorUsConfig, LPSensorUsHeightValues, LPSensorUsSensValues } from '../../../../handlers/ipCanDevices/Sensor/LPSensorUsConfig';
import PanelHeader from '../../../Custom/PanelHeader';
import { FILTER_US_HEIGHT_VALUE, FILTER_US_SENS_VALUE } from '../../LPSensors/constants/filter';

interface Props {
    sensor: LPSensor;
    realTimeChange: (realTime: boolean) => void;
    compareMode: boolean;
    isRealTime: boolean;
    comparedData: any;
}

export const CalibrationCard = (props: Props) => {
    const intl = useIntl();

    const [isEditMode, setIsEditMode] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [formHasError, setFormHasError] = useState(false);
    const [formValue, setFormValue] = useState(() => {
        const hours = secondsToHours(props.sensor.overstay);

        return {
            c1: props.sensor.us1Config.height,
            c2: props.sensor.us2Config.height,
            sDetection: props.sensor.us1Config.sens,
            sRelease: props.sensor.us2Config.sens,
            overstayDurationDays: hours.days,
            overstayDurationHours: new Date(new Date().setHours(hours.hours, hours.minutes, hours.seconds, 0)),
        };
    });

    // create a unique string with usConfig and overtay for comparison
    const uniqueString = `${props.sensor.us1Config.height}${props.sensor.us2Config.height}${props.sensor.us1Config.sens}${props.sensor.us2Config.sens}${props.sensor.overstay}`;


    useEffect(() => {
        const hours = secondsToHours(props.sensor.overstay);

        setFormValue({
            c1: props.sensor.us1Config.height,
            c2: props.sensor.us2Config.height,
            sDetection: props.sensor.us1Config.sens,
            sRelease: props.sensor.us2Config.sens,
            overstayDurationDays: hours.days,
            overstayDurationHours: new Date(new Date().setHours(hours.hours, hours.minutes, hours.seconds, 0)),
        });
    }, [uniqueString]);

    const handleToggleChange = (checked: boolean) => {
        props.realTimeChange(checked);

        setIsEditMode(checked);
    };

    const toggleEditMode = () => {
        setIsEditMode(!isEditMode);
    }

    const hours = secondsToHours(props.sensor.overstay);

    const us1ToCompare = new LPSensorUsConfig(props.comparedData?.us1 ?? 0);
    const us2ToCompare = new LPSensorUsConfig(props.comparedData?.us2 ?? 0);

    const c1_SDetectionClass = props.sensor.us1Config.compare(us1ToCompare) ? 'list-green' : 'list-red';
    const c2_SReleaseClass = props.sensor.us2Config.compare(us2ToCompare) ? 'list-green' : 'list-red';
    const overstayClass = props.sensor.overstay === props.comparedData?.overstay ? 'list-green' : 'list-red';

    const handleFormChange = (formValue: any) => {
        let sDetection: number = 128;
        let sRelease: number = 89;

        switch (parseInt(formValue.sDetection)) {
            case 255:
                sDetection = 512;
                break;
            case 240:
                sDetection = 384;
                break;
            case 150:
                sDetection = 256;
                break;
            case 70:
                sDetection = 192;
                break;
            default:
                sDetection = 128;
                break;
        }

        switch (parseInt(formValue.sRelease)) {
            case 255:
                sRelease = 358;
                break;
            case 240:
                sRelease = 269;
                break;
            case 150:
                sRelease = 179;
                break;
            case 70:
                sRelease = 134;
                break;
            default:
                sRelease = 89;
                break;
        }

        if (sDetection < sRelease) {
            setFormHasError(true);
        } else {
            setFormHasError(false);
        }

        setFormValue(formValue);
    }

    const validEdition = () => {
        setIsUpdating(true);

        let totalTime = formValue.overstayDurationDays * 24 * 60 * 60; // INIT WITH DAYS AS SECONDS
        totalTime +=
            formValue.overstayDurationHours.getHours() * 60 * 60 +
            formValue.overstayDurationHours.getMinutes() * 60 +
            formValue.overstayDurationHours.getSeconds();

        props.sensor.updateCalibration(formValue.c1, formValue.c2, formValue.sDetection, formValue.sRelease, totalTime).then(() => {
            if (!props.isRealTime) {
                setIsEditMode(false);
            }

            Alert.success(
                intl.formatMessage({
                    id: 'ipCanDevices.LPSensor.updateFitler.success',
                })
            );
        })
            .catch(err => {
                Alert.error(
                    intl.formatMessage(
                        {
                            id: 'ipCanDevices.LPSensor.updateFitler.error',
                        },
                        {
                            error: err,
                        }
                    )
                );
            })
            .finally(() => {
                setIsUpdating(false);
            });
    }

    const isInEditMode = isEditMode || props.isRealTime;

    return <Panel
        className="panel-small"
        shaded
        bordered
        bodyFill
        header={
            <PanelHeader
                title={intl.formatMessage({
                    id: 'ipCanDevices.LPSensor.calibration',
                })}
                buttons={[
                    <Whisper
                        placement="top"
                        trigger="hover"
                        speaker={
                            <Tooltip>
                                <FormattedMessage id="device.testMode" />
                            </Tooltip>
                        }>
                        <Toggle
                            data-cy="ipCanDevices-lpSensor-changeMode"
                            unCheckedChildren={<FontAwesomeIcon icon={faEyeSlash} />}
                            checkedChildren={<FontAwesomeIcon icon={faChartBar} />}
                            className="margin-right-10"
                            checked={props.isRealTime}
                            onChange={handleToggleChange}
                        /></Whisper>,
                    <Button
                        data-cy="ipCanDevices-lpSensor-editMode"
                        appearance="primary"
                        onClick={toggleEditMode}
                        disabled={isInEditMode}
                        size="sm">
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>,
                ]}
            />
        }>
        <Form formValue={formValue} onChange={handleFormChange} fluid>
            <FlexboxGrid>
                <FlexboxGrid.Item
                    componentClass={Col}
                    xs={24}
                    style={{ padding: 0 }}>
                    <List
                        hover
                        size="sm"
                        style={{ borderRight: '1px solid black' }}
                        className="list-text-smaller">
                        <List.Item
                            className={`panel-list ${props.compareMode ? c1_SDetectionClass : 'list-colored-violet'}`}>
                            <FlexboxGrid justify="space-between" align="middle">
                                <FlexboxGrid.Item
                                    style={{ fontWeight: 'bold' }}
                                    componentClass={Col}
                                    xs={14}
                                    data-cy="ipCanDevices-LPSensor-calibration-c1">
                                    <FormattedMessage id="ipCanDevices.LPSensor.calibration.c1" />
                                </FlexboxGrid.Item>
                                {isInEditMode ? (
                                    <FlexboxGrid.Item componentClass={Col} xs={8}>
                                        <div data-cy="ipCanDevices-lpSensor-calibration-c1-edit">
                                            <FormControl
                                                className="form-margin"
                                                accepter={SelectPicker}
                                                data={FILTER_US_HEIGHT_VALUE}
                                                placement="auto"
                                                searchable={false}
                                                cleanable={false}
                                                name="c1"
                                                size="sm"
                                                postfix="cm"
                                                renderMenuItem={(label, item) => {
                                                    return (
                                                        <div
                                                            data-cy={`lpSensor-calibration-c1-value-${item.value}`}>
                                                            {label}
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </div>
                                    </FlexboxGrid.Item>
                                ) : (
                                    <Fragment>
                                        {/* READ CONFIG */}
                                        {props.compareMode && (
                                            <FlexboxGrid.Item>
                                                {LPSensorUsHeightValues(props.comparedData?.us1.height)}
                                            </FlexboxGrid.Item>
                                        )}
                                        <FlexboxGrid.Item data-cy="ipCanDevices-lpSensor-calibration-c1">
                                            {LPSensorUsHeightValues(props.sensor.us1Config.height)}
                                        </FlexboxGrid.Item>
                                    </Fragment>
                                )}
                            </FlexboxGrid>
                        </List.Item>
                        {/* --- C2 */}
                        <List.Item
                            className={`panel-list ${props.compareMode ? c2_SReleaseClass : 'list-colored-violet'}`}>
                            <FlexboxGrid justify="space-between" align="middle">
                                <FlexboxGrid.Item
                                    style={{ fontWeight: 'bold' }}
                                    componentClass={Col}
                                    xs={14}
                                    data-cy="ipCanDevices-LPSensor-calibration-c2">
                                    <FormattedMessage id="ipCanDevices.LPSensor.calibration.c2" />
                                </FlexboxGrid.Item>
                                {isInEditMode ? (
                                    <FlexboxGrid.Item componentClass={Col} xs={8}>
                                        <div data-cy="ipCanDevices-lpSensor-calibration-c2-edit">
                                            <FormControl
                                                className="form-margin"
                                                accepter={SelectPicker}
                                                data={FILTER_US_HEIGHT_VALUE}
                                                placement="auto"
                                                searchable={false}
                                                cleanable={false}
                                                name="c2"
                                                size="sm"
                                                postfix="cm"
                                                renderMenuItem={(label, item) => {
                                                    return (
                                                        <div
                                                            data-cy={`lpSensor-calibration-c2-value-${item.value}`}>
                                                            {label}
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </div>
                                    </FlexboxGrid.Item>
                                ) : (
                                    <Fragment>
                                        {/* READ CONFIG */}
                                        {props.compareMode && (
                                            <FlexboxGrid.Item>
                                                {LPSensorUsHeightValues(props.comparedData?.us2.height)}
                                            </FlexboxGrid.Item>
                                        )}
                                        <FlexboxGrid.Item data-cy="ipCanDevices-lpSensor-calibration-c2">
                                            {LPSensorUsHeightValues(props.sensor.us2Config.height)}
                                        </FlexboxGrid.Item>
                                    </Fragment>
                                )}
                            </FlexboxGrid>
                        </List.Item>
                        {/* --- sDetection */}
                        <List.Item
                            className={`panel-list ${props.compareMode ? c1_SDetectionClass : 'list-colored-violet'}`}>
                            <FlexboxGrid justify="space-between">
                                <FlexboxGrid.Item
                                    style={{ fontWeight: 'bold' }}
                                    componentClass={Col}
                                    xs={12}
                                    data-cy="ipCanDevices-LPSensor-calibration-sDetection">
                                    <FormattedMessage id="ipCanDevices.LPSensor.calibration.sDetection" />
                                </FlexboxGrid.Item>
                                {isInEditMode ? (
                                    <Fragment>
                                        {formHasError && (
                                            <FlexboxGrid.Item componentClass={Col} xs={4}>
                                                <FontAwesomeIcon
                                                    data-cy="ipCanDevices-lpSensor-error-sDetection"
                                                    icon={faExclamationCircle}
                                                    color="red"
                                                    size="2x"
                                                />
                                            </FlexboxGrid.Item>
                                        )}

                                        <FlexboxGrid.Item componentClass={Col} xs={8}>
                                            <div data-cy="ipCanDevices-lpSensor-calibration-sDetection-edit">
                                                <FormControl
                                                    className="form-margin"
                                                    accepter={SelectPicker}
                                                    data={FILTER_US_SENS_VALUE}
                                                    placement="auto"
                                                    searchable={false}
                                                    cleanable={false}
                                                    name="sDetection"
                                                    size="sm"
                                                    renderMenuItem={(label, item) => {
                                                        return (
                                                            <div
                                                                data-cy={`lpSensor-sDetection-value-${item.value}`}>
                                                                {label}
                                                            </div>
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </FlexboxGrid.Item>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        {/* READ CONFIG */}
                                        {props.compareMode && (
                                            <FlexboxGrid.Item>
                                                {LPSensorUsSensValues(props.comparedData?.us1.sens)}
                                            </FlexboxGrid.Item>
                                        )}
                                        <FlexboxGrid.Item data-cy="ipCanDevices-lpSensor-calibration-sDectection">
                                            {LPSensorUsSensValues(props.sensor.us1Config.sens)}
                                        </FlexboxGrid.Item>
                                    </Fragment>
                                )}
                            </FlexboxGrid>
                        </List.Item>
                        {/* --- */}
                        <List.Item
                            className={`panel-list ${props.compareMode ? c2_SReleaseClass : 'list-colored-violet'}`}>
                            <FlexboxGrid justify="space-between">
                                <FlexboxGrid.Item
                                    style={{ fontWeight: 'bold' }}
                                    componentClass={Col}
                                    xs={12}
                                    data-cy="ipCanDevices-LPSensor-calibration-sRelease">
                                    <FormattedMessage id="ipCanDevices.LPSensor.calibration.sRelease" />
                                </FlexboxGrid.Item>
                                {isInEditMode ? (
                                    <Fragment>
                                        {formHasError && (
                                            <FlexboxGrid.Item componentClass={Col} xs={4}>
                                                <FontAwesomeIcon
                                                    data-cy="ipCanDevices-lpSensor-error-sRelease"
                                                    icon={faExclamationCircle}
                                                    color="red"
                                                    size="2x"
                                                />
                                            </FlexboxGrid.Item>
                                        )}
                                        <FlexboxGrid.Item componentClass={Col} xs={8}>
                                            <div data-cy="ipCanDevices-lpSensor-calibration-sRelease-edit">
                                                <FormControl
                                                    className="form-margin"
                                                    accepter={SelectPicker}
                                                    data={FILTER_US_SENS_VALUE}
                                                    placement="auto"
                                                    searchable={false}
                                                    cleanable={false}
                                                    name="sRelease"
                                                    size="sm"
                                                    renderMenuItem={(label, item) => {
                                                        return (
                                                            <div
                                                                data-cy={`lpSensor-sRelease-value-${item.value}`}>
                                                                {label}
                                                            </div>
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </FlexboxGrid.Item>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        {/* READ CONFIG */}
                                        {props.compareMode && (
                                            <FlexboxGrid.Item>
                                                {LPSensorUsSensValues(props.comparedData?.us2.sens)}
                                            </FlexboxGrid.Item>
                                        )}
                                        <FlexboxGrid.Item data-cy="ipCanDevices-lpSensor-calibration-sRelease">
                                            {LPSensorUsSensValues(props.sensor.us2Config.sens)}
                                        </FlexboxGrid.Item>
                                    </Fragment>
                                )}
                            </FlexboxGrid>
                        </List.Item>
                        {/* --- */}
                        <List.Item
                            className={`panel-list ${props.compareMode ? overstayClass : 'list-colored-violet'}`}>
                            <FlexboxGrid justify="space-between">
                                <FlexboxGrid.Item
                                    style={{ fontWeight: 'bold' }}
                                    componentClass={Col}
                                    xs={14}
                                    data-cy="ipCanDevices-LPSensor-calibration-overstayDuration">
                                    <FormattedMessage id="ipCanDevices.LPSensor.calibration.overstayDuration" />
                                </FlexboxGrid.Item>
                                {isInEditMode ? (
                                    <FlexboxGrid.Item componentClass={Col} xs={24}>
                                        <FlexboxGrid>
                                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                                <FormControl
                                                    className="form-margin"
                                                    accepter={InputNumber}
                                                    postfix={intl.formatMessage({
                                                        id: 'ipCanDevices.LPSensor.calibration.days',
                                                    })}
                                                    name="overstayDurationDays"
                                                    size="sm"
                                                />
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item componentClass={Col} xs={12}>
                                                <div data-cy="ipCanDevices-lpSensor-calibration-realHours-edit">
                                                    <FormControl
                                                        className="form-margin"
                                                        accepter={DatePicker}
                                                        format="HH:mm:ss"
                                                        cleanable={false}
                                                        placement={'auto'}
                                                        ranges={[]}
                                                        name="overstayDurationHours"
                                                        size="sm"
                                                    />
                                                </div>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </FlexboxGrid.Item>
                                ) : (
                                    <Fragment>
                                        {/* READ CONFIG */}
                                        {props.compareMode && (
                                            <FlexboxGrid.Item>
                                                <FlexboxGrid.Item>{secondsToHours(props.comparedData?.overstay).stringified}</FlexboxGrid.Item>
                                            </FlexboxGrid.Item>
                                        )}
                                        <FlexboxGrid.Item data-cy="ipCanDevices-lpSensor-calibration-realHours">
                                            <FlexboxGrid.Item>{hours.stringified}</FlexboxGrid.Item>
                                        </FlexboxGrid.Item>
                                    </Fragment>
                                )}
                            </FlexboxGrid>
                        </List.Item>
                    </List>
                    {isInEditMode && props.isRealTime && (
                        <FlexboxGrid align="middle" justify="end">
                            <FlexboxGrid.Item className="margin-right-10">
                                <ButtonGroup style={{ marginBottom: 10, marginTop: 10 }}>
                                    <Button
                                        data-cy="ipCanDevices-lpSensor-edit-valid"
                                        color="green"
                                        loading={isUpdating} docker
                                        onClick={validEdition}
                                        disabled={formHasError}>
                                        <FontAwesomeIcon icon={faCheck} />
                                    </Button>
                                    <Button
                                        data-cy="ipCanDevices-lpSensor-edit-cancel"
                                        color="red"
                                        onClick={toggleEditMode}>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </Button>
                                </ButtonGroup>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    )}
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Form>
        {isInEditMode && !props.isRealTime && (
            <List>
                <List.Item className="paenl-list">
                    <FlexboxGrid align="middle" justify="end">
                        <FlexboxGrid.Item className="margin-right-10">
                            <ButtonGroup>
                                <Button
                                    data-cy="ipCanDevices-lpSensor-editMode-valid"
                                    color="green"
                                    onClick={validEdition}
                                    loading={isUpdating}
                                    disabled={formHasError}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                                <Button
                                    data-cy="ipCanDevices-lpSensor-editMode-cancel"
                                    color="red"
                                    onClick={toggleEditMode}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                            </ButtonGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </List.Item>
            </List>
        )}
    </Panel>
}